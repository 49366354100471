export const DATADOG_RUM_APPLICATION_ID =
  '70585d24-006d-424f-a3c5-fbe0403f9ebf';
export const DATADOG_CLIENT_TOKEN = 'pub44b08232e19f1a1651a49e62607b9fcd';
export const DATADOG_SITE = 'us5.datadoghq.com';
export const DATADOG_UI_SERVICE = 'wandb-web-ui';

export const ALLOWED_TRACING_URLS = [
  // local dev
  'http://api.wandb.test',
  'https://api.wandb.test',
  'https://weave.wandb.test',
  'https://trace.wandb.test',

  // qa
  'https://api.qa.wandb.ai',
  'https://weave.qa.wandb.ai',
  'https://trace.qa.wandb.ai',

  // prod
  'https://api.wandb.ai',
  'https://weave.wandb.ai',
  'https://trace.wandb.ai',
].map(url => ({match: url, propagatorTypes: ['b3multi']}));
