import React, {FC, useEffect} from 'react';

import {useCacheIfEnabled} from './util/cache';
import {disableCacheLink, enableCacheLink} from './util/cache/util';

export const LongTermCacheExperimentContextProvider: FC = ({children}) => {
  const cache = useCacheIfEnabled();

  useEffect(() => {
    if (cache) {
      enableCacheLink();
    } else {
      disableCacheLink();
    }
  }, [cache]);

  return <>{children}</>;
};
