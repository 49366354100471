import _ from 'lodash';

import {DEFAULT_LINE_PLOT_MAX_GROUP_RUNS} from '../../components/WorkspaceDrawer/Settings/runLinePlots/maxRunDefaults';
import {
  QueryToRunsDataQueryParams,
  RunsDataQuery,
  toRunsDataQuery,
} from '../../containers/RunsDataLoader';
import {BucketedHistorySpec, HistorySpec} from '../../state/runs/types';
import {CHART_SAMPLES} from '../../util/constants';
import {Expression, metricsInExpression} from '../../util/expr';
import * as Filters from '../../util/filters';
import {Filter} from '../../util/filterTypes';
import * as PanelHelpers from '../../util/panelHelpers';
import {Query} from '../../util/queryTypes';
import {Key} from '../../util/runTypes';
import {DEFAULT_X_AXIS_SETTINGS} from '../WorkspaceDrawer/Settings/runLinePlots/linePlotDefaults';
import {getExtraFields} from './queryConstructor/getSectionFields';
import {RunsLinePlotConfig} from './types';

export interface Range {
  min: number | null;
  max: number | null;
}

export function runsLinePlotTransformQuery(options: {
  query: Query;
  runsLinePlotConfig: RunsLinePlotConfig;
  xStepRange: Range | null;
  parsedExpressions: {
    expressions: Expression[] | undefined;
    xExpression: Expression | undefined;
  };
  defaultMaxRuns: number;
  isFullFidelity?: boolean;
}): RunsDataQuery {
  const {
    query,
    runsLinePlotConfig,
    xStepRange,
    parsedExpressions,
    defaultMaxRuns,
    isFullFidelity = false,
  } = options;
  const singleRun = Boolean(query.runName);
  const isGrouped = PanelHelpers.isGrouped(query, runsLinePlotConfig);

  let result;
  if (singleRun) {
    result = toRunsDataQuery(query);
  } else {
    const queryToDataQueryParams: QueryToRunsDataQueryParams = {
      selectionsAsFilters: true,
    };

    if (
      runsLinePlotConfig.metrics != null &&
      runsLinePlotConfig.metrics.length > 0
    ) {
      const filters: Array<Filter<Key>> = runsLinePlotConfig.metrics
        .slice(0, 50)
        .map(metric => ({
          key: {section: 'keys_info', name: metric},
          op: '!=',
          value: null,
        }));
      queryToDataQueryParams.mergeFilters = Filters.Or(filters);
    }

    result = toRunsDataQuery(query, queryToDataQueryParams);
  }

  if (runsLinePlotConfig.useRunsTableGroupingInPanels === false) {
    result.queries = result.queries.map(q => ({...q, grouping: []}));
  }

  result.disabled = true;

  const extraFields = getExtraFields(
    runsLinePlotConfig,
    parsedExpressions,
    query
  );

  result.configKeys = extraFields
    .filter(key => key.section === 'config')
    .map(key => key.name);
  result.summaryKeys = extraFields
    .filter(key => key.section === 'summary')
    .map(key => key.name);

  if (runsLinePlotConfig.metrics != null) {
    // We load the history data for the graphs
    // We sample values where an individual metric and the xAxis has values
    // Some users might prefer to sample history rows where all of the metrics
    // and the xAxis has a value, but this will cause some graphs to not display
    // if for example a user logs test data in one step and training data in another
    // and then wants to plot the two metrics in a single graph.
    result.disabled = false;
    result.history = true;

    const xAxis = runsLinePlotConfig.xAxis ?? DEFAULT_X_AXIS_SETTINGS.xAxis;

    const yAxisExprKeys: string[] = [];
    for (const expr of parsedExpressions.expressions ?? []) {
      yAxisExprKeys.push(...metricsInExpression(expr));
    }
    const xAxisExprKeys: string[] =
      parsedExpressions.xExpression != null
        ? metricsInExpression(parsedExpressions.xExpression)
        : [];

    const xAxisHistoryKeys: string[] = []; // history keys we need besides the metric
    if (!getHasSystemMetrics(runsLinePlotConfig)) {
      xAxisHistoryKeys.push('_step');
    }
    if (xAxis === '_absolute_runtime') {
      // we calculate absolute wall time from the start of the run
      // we need both timestamp and runtime to do this
      xAxisHistoryKeys.push('_timestamp');
      xAxisHistoryKeys.push('_runtime');
    } else {
      // this is the normal case
      xAxisHistoryKeys.push(xAxis);
    }
    xAxisHistoryKeys.push(...xAxisExprKeys);

    let numberOfPoints = CHART_SAMPLES;
    if (isFullFidelity) {
      // This will be set later by useBucketedData
      numberOfPoints = 1000;
    }
    if (xAxis === '_step') {
      runsLinePlotConfig.xAxis = xAxis;
    }

    const specs = _.uniq([
      ...runsLinePlotConfig.metrics,
      ...yAxisExprKeys,
      ...xAxisExprKeys,
    ]).map(metricKey =>
      createGorillaHistorySpec(
        xStepRange,
        numberOfPoints,
        metricKey,
        xAxis,
        xAxisHistoryKeys,
        isFullFidelity
      )
    );

    if (isFullFidelity) {
      result.bucketedHistorySpecs = specs as BucketedHistorySpec[];
    } else {
      result.historySpecs = specs as HistorySpec[];
    }

    // order of specificity:
    // if an explicit limit is on the config (such as if a user has set the max runs value in the UI), use that
    // otherwise default to the defaultMaxRuns value which can vary by custom default
    result.page = {
      size: singleRun ? 1 : runsLinePlotConfig.limit ?? defaultMaxRuns,
    };

    if (isGrouped) {
      // We need the metadata for grouping because we do it locally
      // TODO: move grouping to server
      // result.disableMeta = false;

      // optionally compute group statistics over all runs instead of sub-sampling
      result.page.size =
        runsLinePlotConfig.groupRunsLimit ?? DEFAULT_LINE_PLOT_MAX_GROUP_RUNS;
      // Disable grouping for this query, we'll do it ourselves.
      result.queries = result.queries.map(q => ({...q, grouping: []}));
    }
  }

  return result;
}

function createGorillaHistorySpec(
  xStepRange: Range | null,
  numberOfPoints: number,
  metricKey: string,
  xAxis: string,
  xAxisHistoryKeys: string[],
  useGorillaOutliers: boolean
) {
  const spec = {
    keys: _.uniq([...xAxisHistoryKeys, metricKey]),
    samples: numberOfPoints,
    // The API won't accept zoom ranges in decimal form - they must be integers, so make sure to round
    ...(xStepRange != null
      ? {
          minStep: Number.isFinite(xStepRange.min)
            ? // @ts-expect-error TS doesn't handle this check correctly
              Math.floor(xStepRange.min)
            : null,
          maxStep: Number.isFinite(xStepRange.max)
            ? // @ts-expect-error TS doesn't handle this check correctly
              Math.ceil(xStepRange.max)
            : null,
        }
      : {}),
  };
  if (useGorillaOutliers) {
    return {
      ...spec,
      bins: numberOfPoints,
      xAxis: xAxis,
    };
  }
  return spec;
}
export function defaultTitle(
  runsLinePlotConfig: Pick<RunsLinePlotConfig, 'metrics' | 'expressions'>
): string {
  // eslint-disable-next-line no-extra-boolean-cast
  const metricsInUse = Boolean(runsLinePlotConfig.expressions?.[0])
    ? runsLinePlotConfig.expressions
    : runsLinePlotConfig.metrics;

  return metricsInUse?.join(', ') ?? '';
}

export function getHasSystemMetrics(
  runsLinePlotConfig: Pick<RunsLinePlotConfig, 'metrics'>
): boolean {
  if (runsLinePlotConfig.metrics == null) {
    return false;
  }
  return runsLinePlotConfig.metrics.some(metric =>
    metric.startsWith('system/')
  );
}

export function getTitleFromConfig(
  runsLinePlotConfig: RunsLinePlotConfig
): string {
  return runsLinePlotConfig.chartTitle || defaultTitle(runsLinePlotConfig);
}
