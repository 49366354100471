import {Dialog as MUIDialog} from '@material-ui/core';
import React, {FC} from 'react';

import {useColorMode} from '../../util/useColorMode';

type SearchModalProps = {
  onClose: () => void;
};

export const SearchModal: FC<SearchModalProps> = ({onClose, children}) => {
  const {isDarkMode} = useColorMode();
  return (
    <MUIDialog
      open
      onClose={onClose}
      className={`tw-style search-modal ${
        isDarkMode ? `search-modal-night-mode` : ``
      }`}
      style={{
        zIndex: 2147483605, // Stolen from semantic-ui-react's dimmer
      }}
      classes={{paper: `m-0 max-w-none max-h-none rounded-lg`}}>
      {children}
    </MUIDialog>
  );
};
