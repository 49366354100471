import {useEffect} from 'react';

import {useDatadogLogs} from '../../datadog';

type Predicate = (value: number) => boolean;

/**
 * This collects information and sends it to Datadog Logs and RUM. When sending
 * to RUM, it logs it as a custom action, for lack of a better option.
 *
 * @param name
 * @param value
 * @param predicate
 * @param context
 */
export function usePerformanceStatLogger(
  name: string,
  value: number,
  predicate: Predicate,
  context: Record<string, any> = {}
) {
  const datadogLogs = useDatadogLogs();
  const shouldLog = predicate(value);

  useEffect(() => {
    if (!shouldLog) {
      return;
    }

    const logLine = `PerfStat ${name}:${value}`;
    const ctx = {
      ...context,
      [name]: value,
    };
    datadogLogs.logger.info(logLine, ctx);
  }, [shouldLog, name, value, context, datadogLogs.logger]);
}
