import {useMemo} from 'react';

import {usePart} from '../../../../state/views/hooks';
import * as PanelTypes from '../../../../state/views/panel/types';
import {useDeepEqualValue} from '../../../../util/hooks';
import {OrganizedSettings} from '../../../PanelBank/types';
import {RunsLinePlotConfig} from '../../../PanelRunsLinePlot/types';
import {getCascadingSetting} from '../getCascadingSetting';
import {useCascadingLinePlotSettings} from '../runLinePlots/useCascadingLinePlotSettings';
import {getCascadingExcludeOutliers} from '../runLinePlots/utils/getCascadingExcludeOutliers';
import {useCascadingPointAggregationMethod} from '../runLinePlots/utils/getCascadingPointAggregation';
import {getCascadingSmoothingSettings} from '../runLinePlots/utils/getCascadingSmoothingSettings';
import {getCascadingXAxisSettings} from '../runLinePlots/utils/getCascadingXAxisSettings';
import {getCascadingYAxisSettings} from '../runLinePlots/utils/getCascadingYAxisSettings';
import {isFullFidelityMode, LinePlotSettings} from '../types';
import {getWorkspaceLayoutSettings} from '../workspaceLayout/getWorkspaceLayoutSettings';
import {WorkspaceSettingsContextType} from '../WorkspaceSettingsContextProvider';

/**
 * This hook determines which setting should be used in the panel.
 * This should only be used on a workspace page. Reports has its
 * own cascading logic because we don't have access to the context
 * providers and it's still on the old settings structure.
 *
 * Hierarchy: sectionSettings >> workspaceSettings
 *
 * This hook returns either the section or workspace cascading setting.
 */
export const useWorkspaceInheritedSettings = (
  workspaceSettingsContext: WorkspaceSettingsContextType,
  sectionLinePlotSettings?: LinePlotSettings
): OrganizedSettings => {
  const cascadingLinePlotSettings = useCascadingLinePlotSettings(
    workspaceSettingsContext.linePlot,
    sectionLinePlotSettings
  );

  return useMemo(
    () => ({
      ...getWorkspaceLayoutSettings(workspaceSettingsContext.workspaceLayout),
      linePlot: cascadingLinePlotSettings,
    }),
    [cascadingLinePlotSettings, workspaceSettingsContext.workspaceLayout]
  );
};

/**
 * This hook determine if the parent cascading settings or panel setting
 * should be used. It only gets used in PanelCompRedux.
 *
 * Hierarchy: panelConfig >> (sectionSettings >> workspaceSettings)
 *
 * @param inheritedSettings either the workspace or section setting
 */
export const usePanelWithInheritedSettings = (
  panelRef: PanelTypes.Ref,
  inheritedSettings: OrganizedSettings | undefined
) => {
  const panel = usePart(panelRef);
  const isRunsLinePlot = panel.viewType === 'Run History Line Plot';

  const cascadingPointAggregationSettings = useCascadingPointAggregationMethod(
    inheritedSettings?.linePlot,
    isRunsLinePlot ? panel.config : undefined
  );

  const modifiedPanel = useMemo(() => {
    if (inheritedSettings == null) {
      return panel;
    }

    // We need to pipe through useRunsTableGroupingInPanels so we can show
    // individual runs in the run comparer
    if (panel.viewType === 'Run Comparer') {
      return {
        ...panel,
        config: {
          ...panel.config,
          // section setting doesn't exist, so inherit workspace value
          useRunsTableGroupingInPanels:
            inheritedSettings.linePlot?.useRunsTableGroupingInPanels,
        },
      };
    }

    if (isRunsLinePlot) {
      return {
        ...panel,
        config: getLinePlotWithInheritedSettings(
          cascadingPointAggregationSettings,
          panel.config,
          inheritedSettings.linePlot
        ),
      };
    }

    return panel;
  }, [
    inheritedSettings,
    panel,
    isRunsLinePlot,
    cascadingPointAggregationSettings,
  ]);

  const deepEqualPanel = useDeepEqualValue(modifiedPanel);
  return deepEqualPanel;
};

export const getLinePlotWithInheritedSettings = (
  cascadingPointAggregationSettings: ReturnType<
    typeof useCascadingPointAggregationMethod
  >,
  panelConfig: RunsLinePlotConfig,
  inheritedSettings: LinePlotSettings | undefined
) => {
  const output = {
    // Order matters here
    ...inheritedSettings, // workspace or section settings
    ...panelConfig, // settings that are not migrated yet - goal is to move them into cascading function
    // migrated settings
    ...getCascadingPanelSettings(
      cascadingPointAggregationSettings,
      inheritedSettings,
      undefined,
      panelConfig
    ),
  };

  // @ts-expect-error - ignoreOutliers is deprecated, make sure it doesn't show up downstream
  delete output.ignoreOutliers;

  return output;
};

/**
 * We do further transformations on the x-axis depending on the selected x-axis
 * value and if panel is a system metric.
 */
export const getCascadingPanelSettings = (
  cascadingPointAggregationSettings: ReturnType<
    typeof useCascadingPointAggregationMethod
  >,
  parentSettings: LinePlotSettings | undefined,
  childSettings?: LinePlotSettings,
  panelSettings?: RunsLinePlotConfig
) => {
  const {pointVisualizationMethod} = cascadingPointAggregationSettings;

  return {
    ...getCascadingXAxisSettings(parentSettings, childSettings, panelSettings),
    ...getCascadingSmoothingSettings(
      parentSettings,
      childSettings,
      panelSettings
    ),
    ...getCascadingYAxisSettings(parentSettings, childSettings, panelSettings),

    colorRunNames: parentSettings?.colorRunNames,
    displayFullRunName: parentSettings?.displayFullRunName,

    excludeOutliers: getCascadingExcludeOutliers(
      isFullFidelityMode(pointVisualizationMethod),
      {
        excludeOutliers:
          childSettings?.excludeOutliers ?? parentSettings?.excludeOutliers,
        ignoreOutliers:
          // @ts-expect-error - ignoreOutliers is deprecated
          childSettings?.ignoreOutliers ?? parentSettings?.ignoreOutliers,

        showMinMaxOnHover:
          // @ts-expect-error - showMinMaxOnHover is deprecated
          childSettings?.showMinMaxOnHover ?? parentSettings?.showMinMaxOnHover,
      },
      {
        excludeOutliers: panelSettings?.excludeOutliers,
        // @ts-expect-error - ignoreOutliers is deprecated
        ignoreOutliers: panelSettings?.ignoreOutliers,
        // @ts-expect-error - showMinMaxOnHover is deprecated
        showMinMaxOnHover: panelSettings?.showMinMaxOnHover,
      }
    ),
    highlightedCompanionRunOnly: parentSettings?.highlightedCompanionRunOnly,

    // These settings are under development and cascading logic is determined at a higher level for now, so just stick these values in
    pointVisualizationMethod,
    tooltipNumberOfRuns: parentSettings?.tooltipNumberOfRuns,

    // These settings are not named the same at workspace and panel level, so map to the field used at panel level for consistency
    showLegend: getCascadingSetting([
      parentSettings?.showLegend,
      panelSettings?.showLegend,
    ]),
    limit: getCascadingSetting([parentSettings?.limit, panelSettings?.limit]),
    useRunsTableGroupingInPanels: getCascadingSetting([
      parentSettings?.useRunsTableGroupingInPanels,
      panelSettings?.useRunsTableGroupingInPanels,
    ]),
  };
};
