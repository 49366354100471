import classNames from 'classnames';
import React from 'react';

import {UsageType} from '../../generated/graphql';
import {ExportAsCSV} from './ExportAsCSV';
import {HistoricUsageChart} from './HistoricUsageChart';
import {
  TimeRange,
  useHistoricUsageChartContext,
} from './HistoricUsageChartContextProvider';

function getChartTitle(usageType: UsageType) {
  switch (usageType) {
    case UsageType.Storage:
      return 'Average monthly storage';
    default:
      return 'Usage over time';
  }
}

export const HistoricUsageTabContent = () => {
  const {setSelectedTimeRange, selectedTimeRange, usageType, processedData} =
    useHistoricUsageChartContext();

  // If any of the bar values are hidden, that means there isn't data going back far enough
  // so disable the 24 month toggle.
  const hasLessThan12MonthsData = processedData?.some(
    ({hideValue}) => hideValue
  );
  return (
    <div className="flex flex-col rounded-lg border border-moon-250 px-16 pb-16 pt-24">
      <div className="flex justify-between">
        <div className="flex">
          <div className="font-semibold text-moon-750">
            {getChartTitle(usageType)}
          </div>
          <div className="ml-12 flex">
            {Object.values(TimeRange).map(timeRange => (
              <div
                key={timeRange}
                onClick={() =>
                  (hasLessThan12MonthsData !== true ||
                    timeRange === TimeRange.LAST_12_MONTHS) &&
                  setSelectedTimeRange(timeRange)
                }
                className={classNames(
                  'mr-1 px-[9px] py-3 font-semibold first:rounded-l last:mr-0 last:rounded-r',
                  timeRange === selectedTimeRange
                    ? 'bg-teal-300/[.48] text-teal-600'
                    : hasLessThan12MonthsData === true &&
                      timeRange === TimeRange.LAST_24_MONTHS
                    ? 'cursor-auto opacity-[.3]'
                    : 'cursor-pointer bg-oblivion/5 text-moon-500'
                )}>
                {timeRange}
              </div>
            ))}
          </div>
        </div>
        <ExportAsCSV />
      </div>
      <HistoricUsageChart />
    </div>
  );
};
