import * as _ from 'lodash';

import * as Run from '../../util/runs';
import * as RunTypes from '../runTypes';
import {bucketLines, BucketSpec} from './bucket';
import {arrMax, arrMin, avg, median, stddev, stderr} from './math';
import {AggregateCalculation, Line, Point} from './types';

/**
 * Takes in a bunch of lines and returns a line with
 * the name Mean + name that plots the average of all the lines passed in
 * as well as a line with a y0 and a y coordinate for react vis
 * representing the min and the max.
 *
 * Fun fact! If you specify useMedian = true, this code doesn't actually return a line with aggType: "median",
 * rather, the line emitted says "mean" despite it being median.
 */
export function aggregateLines({
  lines,
  bucketSpec = null,
  name = '', // for the legend
  aggregateCalculations = {
    minmax: false,
    stderr: false,
    stddev: false,
    samples: false,
  },
  useMedian = false, // if true calculates median instead of mean
  extraVars = [],
}: {
  lines: Line[];
  bucketSpec: BucketSpec | null;
  name: string; // for the legend
  aggregateCalculations: Record<
    Exclude<AggregateCalculation, 'none' | 'mean'>,
    boolean | undefined
  >;
  useMedian: boolean; // if true calculates median instead of mean
  extraVars: RunTypes.Key[];
}) {
  if (lines.length === 0) {
    throw new Error('Programming error: empty lines');
  }

  let bucketXValues: number[] = [];
  let mergedBuckets: number[][] = [];

  if (bucketSpec && bucketSpec.bucketCount > 0) {
    const ret = bucketLines(lines, bucketSpec);
    bucketXValues = ret.bucketXValues;
    mergedBuckets = ret.mergedBuckets;
  } else {
    const xVals = _.flatten(
      lines.map((line, j) => (line.data as Point[]).map(point => point.x))
    );
    // This should already be sorted
    // TODO: Remove
    bucketXValues = _.uniq(xVals).sort((a, b) => a - b);
    const xValToBucketIndex: {[key: number]: number} = {};
    bucketXValues.map((val, i) => (xValToBucketIndex[val] = i));

    // get all the data points in buckets
    lines.map((line, j) =>
      (line.data as Point[]).forEach(point => {
        const bucketIdx = xValToBucketIndex[point.x];
        mergedBuckets[bucketIdx]
          ? mergedBuckets[bucketIdx].push(point.y)
          : (mergedBuckets[bucketIdx] = [point.y]);
      })
    );
  }

  const lineData: Array<{x: number; y: number}> = [];
  const stddevData: Array<{x: number; y: number; y0: number}> = [];
  const stderrData: Array<{x: number; y: number; y0: number}> = [];
  const minmaxData: Array<{x: number; y: number; y0: number}> = [];
  let calculatedStddev: number | undefined;

  for (let i = 0; i < mergedBuckets.length; i++) {
    const bucket = mergedBuckets[i];
    if (bucket.length === 0) {
      continue;
    }
    let avgVal: number;
    if (useMedian) {
      avgVal = median(bucket);
    } else {
      avgVal = avg(bucket);
    }
    lineData.push({x: bucketXValues[i], y: avgVal});

    if (aggregateCalculations.minmax) {
      minmaxData.push({
        x: bucketXValues[i],
        y0: arrMin(bucket),
        y: arrMax(bucket),
      });
    }

    if (aggregateCalculations.stddev) {
      const stddevVal = stddev(bucket);
      const stddevValOrZero = isNaN(stddevVal) ? 0 : stddevVal;
      calculatedStddev = stddevValOrZero;
      stddevData.push({
        x: bucketXValues[i],
        y0: avgVal - stddevValOrZero,
        y: avgVal + stddevValOrZero,
      });
    }

    if (aggregateCalculations.stderr) {
      const stderrVal = stderr(bucket);
      const stderrValOrZero = isNaN(stderrVal) ? 0 : stderrVal;

      stderrData.push({
        x: bucketXValues[i],
        y0: avgVal - stderrValOrZero,
        y: avgVal + stderrValOrZero,
      });
    }
  }

  let minmaxLine: Line | undefined;
  let stddevLine: Line | undefined;
  let stderrLine: Line | undefined;
  let sampleLines: Line[] | undefined;

  const aggregateExtraVars: {[key: string]: number} = {};
  extraVars.forEach(varKey => {
    const vals = lines
      .map(line =>
        line.vars != null ? line.vars[Run.keyToString(varKey)] : null
      )
      .filter(y => y != null && isFinite(y)) as number[];
    aggregateExtraVars[Run.keyToString(varKey)] = avg(vals);
  });

  if (aggregateCalculations.minmax) {
    minmaxLine = {
      aggType: 'minmax',
      aux: true,
      data: minmaxData,
      displayName: name,
      meta: {
        aggregation: 'minmax',
        category: 'grouped',
        mode: 'sampled',
        type: 'area',
      },
      name,
      run: lines[0].run,
      title: '',
      type: 'area',
    };
  }
  if (aggregateCalculations.stddev) {
    stddevLine = {
      aggType: 'stddev',
      aux: true,
      data: stddevData,
      displayName: name,
      name,
      run: lines[0].run,
      stddev: calculatedStddev,
      title: '',
      type: 'area',
      meta: {
        aggregation: 'stddev',
        category: 'grouped',
        mode: 'sampled',
        type: 'area',
      },
    };
  }

  if (aggregateCalculations.stderr) {
    stderrLine = {
      aggType: 'stderr',
      aux: true,
      data: stderrData,
      displayName: name,
      name,
      run: lines[0].run,
      title: '',
      type: 'area',
      meta: {
        aggregation: 'stderr',
        category: 'grouped',
        mode: 'sampled',
        type: 'area',
      },
    };
  }

  if (aggregateCalculations.samples) {
    sampleLines = lines.map(l => {
      return {
        ...l,
        aux: true,
        mark: 'dotted',
        meta: {
          aggregation: 'samples',
          category: 'grouped',
          mode: 'sampled',
          type: 'line',
        },
      };
    });
  }

  const meanLine: Line = {
    title: '',
    run: lines[0].run,
    data: lineData,
    aggType: 'mean',
    name,
    meta: {
      aggregation: 'avg',
      category: 'grouped',
      mode: 'sampled',
      type: 'line',
    },
    vars: aggregateExtraVars,
  };
  return {meanLine, minmaxLine, stddevLine, stderrLine, sampleLines};
}
