import {isMultiValue} from '../../util/filters';
import {IndividualFilter} from '../../util/filterTypes';

/**
 * In order to support multi-value filters, we need to be able to extract the filter values for Individual and MultiValue filters. Multivalue filters (such as IN | NIN) put a "*" in the name value and feed in the selections as an array of strings in the value field. Individual filters (such as = | !=) put the selection in the name field and a boolean in the value field.
 */
export function getCurrentSelections<T extends IndividualFilter>(
  filter: T,
  ignoreValues: string[] = ['-', '*', '', 'null']
): string[] {
  const names = getNames(filter);

  // make everything into an array
  const namesAsArray = Array.isArray(names) ? names : [names];

  // dump empty values "-", "*", ""
  return namesAsArray.filter(name => !ignoreValues.includes(name));
}

function getNames(filter: IndividualFilter): string | string[] {
  if (['tags'].includes(filter.key.section)) {
    return isMultiValue(filter)
      ? (filter.value as string[])
      : filter.key?.name ?? '';
  }

  if (Array.isArray(filter.value)) {
    return filter.value.map(stringify);
  }

  return stringify(filter.value);
}

function stringify(v: any): string {
  if (typeof v === `string`) {
    return v;
  }
  return JSON.stringify(v);
}
