import React from 'react';
import {AbstractSeries, ScaleUtils} from 'react-vis';

import {getMousePosition} from '../../util/mouse';
import {isMobile} from '../../util/panelbankFlow';

export type DomainArea = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  classes: string;
};

export type BrushEndHandler = (
  area: DomainArea | null,
  newZoomedYAxis: boolean
) => void;

export default class Highlight extends AbstractSeries<any> {
  static displayName = 'HighlightOverlay';
  static defaultProps = {
    allow: 'x',
    color: 'black',
    opacity: 0.1,
  };

  MIN_DRAG_DISTANCE = 5;
  MIN_Y_DRAG_DISTANCE = 25;

  state = {
    drawing: false,
    drawArea: {top: 0, right: 0, bottom: 0, left: 0},
    startLocX: 0,
    startLocY: 0,
    initialXDomain: null,
    disableZoom: isMobile(),
  };

  updateDrawing = (drawing: boolean) => {
    this.props.setIsDrawing(drawing);
    this.setState({drawing});
  };

  wrapperRef = React.createRef();

  _getDrawArea(locX: number, locY: number) {
    const {innerWidth, innerHeight} = this.props;
    const {drawArea, startLocX, startLocY} = this.state;

    let left;
    let right;
    let top;
    let bottom;
    if (locX < startLocX) {
      left = Math.max(locX, 0);
      right = startLocX;
    } else {
      left = startLocX;
      right = Math.min(locX, innerWidth);
    }

    if (Math.abs(locY - startLocY) > this.MIN_Y_DRAG_DISTANCE) {
      if (locY < startLocY) {
        top = Math.max(locY, 0);
        bottom = startLocY;
      } else {
        top = startLocY;
        bottom = Math.min(locY, innerHeight);
      }
      return {
        ...drawArea,
        top,
        bottom,
        left,
        right,
      };
    } else {
      return {
        ...drawArea,
        left,
        right,
        top: 0,
        bottom: this.props.innerHeight,
      };
    }
  }

  getMouseXInContainer(event: React.MouseEvent) {
    return getMousePosition(event).offset.x - this.props.marginLeft;
  }

  getMouseYInContainer(event: React.MouseEvent) {
    return getMousePosition(event).offset.y - this.props.marginTop;
  }

  onParentMouseDown = (e: React.MouseEvent) => {
    const {innerHeight, onBrushStart, setDrawing} = this.props;

    const offsetX = this.getMouseXInContainer(e);
    const offsetY = this.getMouseYInContainer(e);
    this.updateDrawing(true);
    this.setState({
      drawArea: {
        top: 0,
        right: offsetX,
        bottom: innerHeight,
        left: offsetX,
      },
      startLocX: offsetX,
      startLocY: offsetY,
    });

    if (onBrushStart) {
      onBrushStart(e);
    }
  };

  stopDrawing = () => {
    // Quickly short-circuit if the user isn't drawing in our component
    if (!this.state.drawing) {
      return;
    }

    const {onBrushEnd, onMouseUp} = this.props;
    const {drawArea, disableZoom} = this.state as any;
    const xScale = ScaleUtils.getAttributeScale(this.props as any, 'x');
    const yScale = ScaleUtils.getAttributeScale(this.props as any, 'y');
    const classes = ' zoomed-in';

    this.updateDrawing(false);
    // Clear the draw area
    this.setState({
      drawArea: {top: 0, right: 0, bottom: 0, left: 0},
      startLocX: 0,
      startLocY: 0,
    });

    // Invoke the callback with null if the selected area was < 5px
    if (Math.abs(drawArea.right - drawArea.left) < this.MIN_DRAG_DISTANCE) {
      this.setState({disableZoom: isMobile()});
      onBrushEnd(null, false);
      onMouseUp();
      return;
    }

    // Compute the corresponding domain drawn
    const domainArea: DomainArea = {
      top: (yScale as any).invert(drawArea.top),
      right: (xScale as any).invert(drawArea.right),
      bottom: (yScale as any).invert(drawArea.bottom),
      left: (xScale as any).invert(drawArea.left),
      classes,
    };

    const zoomedXAxis = true;
    const zoomedYAxis =
      drawArea.top !== 0 || drawArea.bottom !== this.props.innerHeight;

    if (onBrushEnd && !disableZoom) {
      onBrushEnd(domainArea, zoomedYAxis);
    }
    onMouseUp();
  };

  onParentMouseMove = (e: React.MouseEvent) => {
    const {onBrush} = this.props;
    const {drawing} = this.state;

    e.preventDefault();

    // this is true if you're hovering over a datapoint, which screws up the offset calculation.
    // ignoring these mouseovers fixes this bug: https://github.com/wandb/core/issues/2429
    if ((e.nativeEvent.target as any).nodeName === 'circle') {
      return;
    }

    if (!this.wrapperRef.current) {
      return;
    }
    const offsetX = this.getMouseXInContainer(e);
    const offsetY = this.getMouseYInContainer(e);

    if (drawing) {
      const newDrawArea = this._getDrawArea(offsetX, offsetY);
      this.setState({drawArea: newDrawArea});
      if (onBrush) {
        onBrush(e);
      }
    } else {
      const xScale = ScaleUtils.getAttributeScale(this.props as any, 'x');
      const mouseX = (xScale as any).invert(offsetX);
      const yScale = ScaleUtils.getAttributeScale(this.props as any, 'y');
      const mouseY = (yScale as any).invert(offsetY);
      this.props.onMouseMoveWithXY(mouseX, mouseY);
    }
  };

  handleMouseUp = () => {
    if (this.state.drawing) {
      this.stopDrawing();
    }
  };

  componentDidMount() {
    window.addEventListener('mouseup', this.handleMouseUp, false);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp, false);
  }

  onParentMouseUp = () => {
    this.stopDrawing();
  };

  onTouchCancel = () => {
    this.stopDrawing();
  };

  render() {
    const {marginLeft, marginTop, innerWidth, innerHeight, color, opacity} =
      this.props;
    const {
      drawArea: {left, right, top, bottom},
      disableZoom,
    } = this.state as any;

    return (
      <g
        ref={this.wrapperRef as any}
        transform={`translate(${marginLeft}, ${marginTop})`}
        className="highlight-container"
        onTouchEnd={this.onParentMouseUp}
        onTouchCancel={this.onTouchCancel}>
        <rect
          className="mouse-target"
          fill="black"
          opacity="0"
          x={0}
          y={0}
          // prevent console error for negative value
          width={Math.max(innerWidth, 0)}
          height={Math.max(innerHeight, 0)}
        />
        {Math.abs(this.state.drawArea.right - this.state.drawArea.left) >=
          this.MIN_DRAG_DISTANCE && (
          <>
            <rect
              className="highlight"
              pointerEvents="none"
              opacity={!disableZoom ? opacity : 0}
              fill={color}
              x={left}
              y={top}
              width={right - left}
              height={bottom - top}
            />
          </>
        )}
      </g>
    );
  }
}
