import {OrganizedSettings} from '../../../components/PanelBank/types';
import {
  LinePlotSettings,
  OrganizationPrefix,
  WorkspaceLayoutSettings,
} from '../../../components/WorkspaceDrawer/Settings/types';
import {EMPTY_PANEL_BANK_CONFIG} from '../../../util/panelbankConfigs';
import {setInShallowClone} from '../../../util/utility';
import {addObjsImmutable} from '../normalize';
import {clearAllPanels} from '../panelBankConfig/clearPanelsUtils';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import {isReportView} from '../util';
import * as Actions from './actions';
import {Ref as WorkspaceSettingsRef} from './types';

export const updateLinePlotWorkspaceSettings = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  settings: Partial<LinePlotSettings>
): [ViewReducerState, ActionType] => {
  const prevWorkspaceSettings = state.parts[ref.type][ref.id];
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
        [ref.id]: {
          ...state.parts[ref.type][ref.id],
          linePlot: {
            ...prevWorkspaceSettings.linePlot,
            ...settings,
          },
        },
      },
    },
  };

  const inverseAction = Actions.setWorkspaceSettings(
    ref,
    prevWorkspaceSettings
  );
  return [newState, inverseAction];
};

export const updateAutoOrganizePrefix = (
  state: ViewReducerState,
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  autoOrganizePrefix: OrganizationPrefix | undefined
): [ViewReducerState, ActionType] => {
  const prevSectionRefs =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;
  const prevAutoOrganizePrefix =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      ?.autoOrganizePrefix;

  const {parts: newParts, refs: newSectionRefs} = addObjsImmutable(
    state.parts,
    'panel-bank-section-config',
    panelBankConfigRef.viewID,
    [...EMPTY_PANEL_BANK_CONFIG.sections]
  );

  const newState = {
    ...state,
    parts: {
      ...newParts,
      [panelBankConfigRef.type]: {
        ...newParts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...newParts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs: newSectionRefs, // update sections with new refs
        },
      },
      [workspaceSettingsRef.type]: {
        ...newParts[workspaceSettingsRef.type],
        [workspaceSettingsRef.id]: {
          ...newParts[workspaceSettingsRef.type][workspaceSettingsRef.id],
          autoOrganizePrefix, // update the workspace autoOrganizePrefix setting
        },
      },
    },
  };

  const inverseAction = Actions.updateAutoOrganizePrefixUndo(
    workspaceSettingsRef,
    panelBankConfigRef,
    prevSectionRefs,
    prevAutoOrganizePrefix
  );

  return [newState, inverseAction];
};

export const updateAutoOrganizePrefixUndo = (
  state: ViewReducerState,
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  sectionRefs: PanelBankSectionConfigTypes.Ref[],
  autoOrganizePrefix: OrganizationPrefix | undefined
): [ViewReducerState, ActionType] => {
  const prevSectionRefs =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;
  const prevAutoOrganizePrefix =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      ?.autoOrganizePrefix;

  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [panelBankConfigRef.type]: {
        ...state.parts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...state.parts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs, // reverts
        },
      },
      [workspaceSettingsRef.type]: {
        ...state.parts[workspaceSettingsRef.type],
        [workspaceSettingsRef.id]: {
          ...state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id],
          autoOrganizePrefix, // reverts
        },
      },
    },
  };

  const inverseAction = Actions.updateAutoOrganizePrefixUndo(
    workspaceSettingsRef,
    panelBankConfigRef,
    prevSectionRefs,
    prevAutoOrganizePrefix
  );

  return [newState, inverseAction];
};

export const updateShouldAutoGeneratePanels = (
  prevState: ViewReducerState,
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  newValue: WorkspaceLayoutSettings['shouldAutoGeneratePanels']
): [ViewReducerState, ActionType] => {
  // update setting
  let newState = setInShallowClone(
    prevState,
    [
      'parts',
      workspaceSettingsRef.type,
      workspaceSettingsRef.id,
      'shouldAutoGeneratePanels',
    ],
    newValue
  );

  // clear panels if transitioning between modes
  if (newValue === 'pending') {
    newState = clearAllPanels(
      newState,
      panelBankConfigRef,
      workspaceSettingsRef,
      false
    )[0];
  }

  // hide panel gen info banner (no need for additional panel gen
  // education if user has explicitly selected a workspace mode)
  const viewRef = newState.views[workspaceSettingsRef.viewID].partRef;
  if (viewRef && !isReportView(viewRef)) {
    const {panelAutoGenInfoHiddenAt} = newState.parts[viewRef.type][viewRef.id];
    if (panelAutoGenInfoHiddenAt == null) {
      newState = setInShallowClone(
        newState,
        ['parts', viewRef.type, viewRef.id, 'panelAutoGenInfoHiddenAt'],
        new Date().toISOString()
      );
    }
  }

  // set up undo action
  const prevValue =
    prevState.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      .shouldAutoGeneratePanels;
  const inverseAction = Actions.updateShouldAutoGeneratePanels(
    workspaceSettingsRef,
    panelBankConfigRef,
    prevValue
  );

  return [newState, inverseAction];
};

export const updateWorkspaceLayoutSettings = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  settings: Partial<WorkspaceLayoutSettings>
): [ViewReducerState, ActionType] => {
  const prevWorkspaceSettings = state.parts[ref.type][ref.id];
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
        [ref.id]: {
          ...prevWorkspaceSettings,
          ...settings,
        },
      },
    },
  };

  const inverseAction = Actions.setWorkspaceSettings(
    ref,
    prevWorkspaceSettings
  );
  return [newState, inverseAction];
};

export const setWorkspaceSettings = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  settings: OrganizedSettings
): [ViewReducerState, ActionType] => {
  const prevWorkspaceSettings = state.parts[ref.type][ref.id];
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
        [ref.id]: Object.assign({}, settings),
      },
    },
  };

  const inverseAction = Actions.setWorkspaceSettings(
    ref,
    prevWorkspaceSettings
  );
  return [newState, inverseAction];
};
