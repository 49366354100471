import * as d3 from 'd3';
import _ from 'lodash';
import {useMemo} from 'react';

import {Line, Point} from '../../util/plotHelpers/types';
import {ExcludeOutliersValues} from '../WorkspaceDrawer/Settings/types';
import {areOutliersExcluded} from '../WorkspaceDrawer/Settings/utils';
import {DomainMaybe} from './types';

export function getBoundaryValues(
  points: Point[],
  config: {ignoreInfinity?: boolean}
) {
  if (points.length === 0) {
    return {
      min: undefined,
      max: undefined,
    };
  }
  return points.reduce(
    (acc, point) => {
      [point.y, point.y0]
        .filter(val => {
          if (val === undefined) {
            return false;
          }
          if (config.ignoreInfinity) {
            return _.isFinite(val);
          }
          return !isNaN(val);
        })
        .forEach(val => {
          // undefineds are filtered out above
          acc.min = Math.min(acc.min, val!);
          acc.max = Math.max(acc.max, val!);
        });
      return acc;
    },
    {min: Infinity, max: -Infinity}
  );
}

export function calculateDomain({
  filteredLines,
  excludeOutliers,
  isFullFidelityMode,
  isHeatmap,
  userXDomain,
  userYDomain,
}: {
  filteredLines: Line[];
  excludeOutliers?: ExcludeOutliersValues;
  isFullFidelityMode: boolean;
  isHeatmap: boolean;
  userXDomain: DomainMaybe | undefined;
  userYDomain: DomainMaybe | undefined;
}) {
  const retXDomain: DomainMaybe =
    userXDomain != null ? [...userXDomain] : [null, null];
  const retYDomain: DomainMaybe =
    userYDomain != null ? [...userYDomain] : [null, null];

  if (
    retXDomain[0] != null &&
    retXDomain[1] != null &&
    retYDomain[0] != null &&
    retYDomain[1] != null
  ) {
    return [retXDomain, retYDomain] as const;
  }

  if (filteredLines.length === 0) {
    return [retXDomain, retYDomain] as const;
  }

  const pointFilter = ({x}: Point): boolean => {
    if (userXDomain != null) {
      return (
        (userXDomain[0] == null || x >= userXDomain[0]) &&
        (userXDomain[1] == null || x <= userXDomain[1])
      );
    }
    return true;
  };

  const filteredPoints = filteredLines
    .flatMap(({data}) => data)
    .filter(pointFilter);

  const filteredNaNPoints = filteredLines
    .flatMap(({nanPoints}) => nanPoints ?? [])
    .filter(pointFilter);

  const [xMinRaw, xMaxRaw] = d3.extent(
    [...filteredPoints, ...filteredNaNPoints].map(point => point.x)
  );
  let xMin = xMinRaw ?? 0;
  let xMax = xMaxRaw ?? 0;

  // TODO(aswanberg): Consider adding padding like tensorboard does.
  let minYPoints: number[] = [];
  let maxYPoints: number[] = [];
  if (areOutliersExcluded(excludeOutliers) && !isFullFidelityMode) {
    minYPoints = filteredPoints.map(point =>
      point.y0 != null ? Math.min(point.y0, point.y) : point.y
    );
    maxYPoints = filteredPoints.map(point =>
      point.y0 != null ? Math.max(point.y0, point.y) : point.y
    );
    minYPoints = _.sortBy(minYPoints);
    maxYPoints = _.sortBy(maxYPoints);

    const lowerBound = d3.quantile(minYPoints, 0.05) || -Infinity;
    const upperBound = d3.quantile(maxYPoints, 0.95) || Infinity;

    minYPoints = minYPoints.filter(yVal => yVal >= lowerBound);
    maxYPoints = maxYPoints.filter(yVal => yVal <= upperBound);
  } else {
    const {min, max} = getBoundaryValues(filteredPoints, {
      ignoreInfinity: true,
    });
    if (min !== undefined) {
      minYPoints.push(min);
    }
    if (max !== undefined) {
      maxYPoints.push(max);
    }
  }

  let yMin = _.min(minYPoints) ?? 0;
  let yMax = _.max(maxYPoints) ?? 0;

  const xRange = xMax - xMin;
  const yRange = yMax - yMin;

  if (xRange === 0) {
    if (isHeatmap) {
      // ridiculous hacky fix to single step heatmap
      xMin = -0.49998;
      xMax = 0.49999;
    } else {
      xMin -= Math.abs(xMin);
      xMax += xMax === 0 ? 1 : Math.abs(xMax);
    }
  }

  // arbitrary epsilon to account for centering constant lines
  // javascript min value is 2^-1074 so this should be safe
  if (yRange < 1e-20) {
    yMin -= yMax === 0 ? 2 : Math.abs(yMin);
    yMax += yMax === 0 ? 2 : Math.abs(yMax);
  }

  retXDomain[0] = retXDomain[0] ?? xMin;
  retXDomain[1] = retXDomain[1] ?? xMax;
  retYDomain[0] = retYDomain[0] ?? yMin;
  retYDomain[1] = retYDomain[1] ?? yMax;

  return [retXDomain, retYDomain] as const;
}

export function useCalculatedDomain({
  excludeOutliers,
  filteredLines,
  isFullFidelityMode,
  isHeatmap,
  userXDomain,
  userYDomain,
}: {
  filteredLines: Line[];
  excludeOutliers?: ExcludeOutliersValues;
  isFullFidelityMode: boolean;
  isHeatmap: boolean;
  userXDomain: DomainMaybe | undefined;
  userYDomain: DomainMaybe | undefined;
}) {
  return useMemo(() => {
    return calculateDomain({
      excludeOutliers,
      filteredLines,
      isFullFidelityMode,
      isHeatmap,
      userXDomain,
      userYDomain,
    });
  }, [
    excludeOutliers,
    filteredLines,
    isFullFidelityMode,
    isHeatmap,
    userXDomain,
    userYDomain,
  ]);
}
