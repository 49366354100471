import {useEffect, useMemo} from 'react';

import {
  OrganizationSubscriptionStatus,
  UserOrganizationsQuery,
  UserOrganizationsQueryResult,
} from '../generated/graphql';

export type MatchingOrganizations = NonNullable<
  UserOrganizationsQuery['viewer']
>['openMatchingOrganizations'];

export function useMatchingOrganizations(
  onNoMatches?: () => void,
  userOrganizationsQueryResult?: UserOrganizationsQueryResult
): MatchingOrganizations {
  // Filter out organizations that don't have an active subscription - users shouldn't be prompted to join them.
  const organizations = useMemo(
    () =>
      userOrganizationsQueryResult?.data?.viewer?.openMatchingOrganizations?.filter(
        organization =>
          organization?.subscriptions?.some(
            subscription =>
              subscription?.status === OrganizationSubscriptionStatus.Enabled
          )
      ) ?? [],
    [userOrganizationsQueryResult]
  );
  useEffect(() => {
    if (
      userOrganizationsQueryResult?.data != null &&
      userOrganizationsQueryResult.loading === false
    ) {
      const hasMatchingTeams = organizations?.some(({teams}) => teams?.length);
      if (!hasMatchingTeams) {
        onNoMatches?.();
      }
    }
  }, [organizations, userOrganizationsQueryResult, onNoMatches]);
  return organizations;
}
