import {Button, Radio} from '@wandb/weave/components';
import * as React from 'react';
import {twMerge} from 'tailwind-merge';

import {ClearSettingsRow} from '../components/ClearSettingsRow';
import {SettingLabel} from '../components/SettingLabel';
import {ClearWorkspaceSettingFnType} from '../hooks/useSettingOverrides';
import {
  DerivedPointVisualizationOption,
  POINT_VISUALIZATION_OPTIONS,
} from '../types';

/**
 * Types of point visualization:
 * BucketingGorilla: this is new functionality using gorilla to bucket points without sampling. We ejected from Weave to do this for time, but long-term hope to move this to weave.

 * Sampling: this is the legacy functionality of doing sampling/bucketing clientside
 * SamplingByDefault: we need a way to differentiate downstream if a workspace has sampling
 * saved as a value or if we're just opting into it by default. If it's by default, we can 
 * selectively override it to enroll organizations or users into bucketing to drive enrollment
 */

type LinePlotPointAggregationProps = {
  className?: string;
  pointVisualizationMethod: DerivedPointVisualizationOption;
  numOverrides?: {
    sections?: number;
    panels: number;
  };
  onPointVisualizationMethodChange: (
    value: DerivedPointVisualizationOption
  ) => void;
  clearSetting?: ClearWorkspaceSettingFnType;
};

export const LinePlotPointAggregation = ({
  className,
  pointVisualizationMethod,
  numOverrides,
  onPointVisualizationMethodChange,
  clearSetting,
}: LinePlotPointAggregationProps) => {
  return (
    <fieldset className={twMerge('text-moon-800', className)}>
      <div className="center mb-4 flex flex-col">
        <div className="flex items-center gap-4">
          <SettingLabel className="w-fit" text="Point aggregation method" />
          <Button
            size="small"
            variant="quiet"
            icon="info"
            tooltipProps={{className: 'w-[20rem]'}}
            tooltip={
              <>
                <div className="mb-12">
                  <strong>Random sampling</strong> displays a subset of points
                  to improve performance with large datasets.
                </div>
                <div>
                  <strong>Full fidelity</strong> shows all data points with
                  point aggregation across x-axis buckets. You can choose to
                  display min/max values of each bucket as a shaded area.
                </div>
              </>
            }
          />
        </div>
        <p className="text-sm text-moon-500">
          Only applies to lines with more than 1,000 data points.
        </p>
      </div>
      <Radio.Root
        aria-label="Point aggregation method"
        name="point-aggregation-method"
        onValueChange={onPointVisualizationMethodChange}
        value={pointVisualizationMethod}>
        <div className="flex items-center justify-start">
          <Radio.Item
            id="point-visualization-sampling"
            value={POINT_VISUALIZATION_OPTIONS.Sampling}>
            <Radio.Indicator />
          </Radio.Item>
          <label className="" htmlFor="point-visualization-sampling">
            <span className="mx-8">Random sampling</span>
          </label>
        </div>
        <div className="flex items-center justify-start">
          <Radio.Item
            id="point-visualization-bucketing-gorilla"
            value={POINT_VISUALIZATION_OPTIONS.BucketingGorilla}>
            <Radio.Indicator />
          </Radio.Item>
          <label htmlFor="point-visualization-bucketing-gorilla">
            <span className="mx-8">Full fidelity</span>
          </label>
        </div>
      </Radio.Root>

      {numOverrides?.panels != null && numOverrides?.panels > 0 && (
        <div className="flex-end">
          <ClearSettingsRow
            setting="pointVisualizationMethod"
            count={numOverrides?.panels}
            type="panel"
            clearSetting={() => {
              clearSetting?.('panel', 'pointAggregationMethod', {
                pointVisualizationMethod: undefined,
              });
            }}
          />
        </div>
      )}
    </fieldset>
  );
};
