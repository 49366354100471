import {Icon} from '@wandb/weave/components/Icon';
import React from 'react';

export const LOADING_ERROR =
  'Oops! We hit a snag loading your data. Please try again in a moment. If the issue persists, reach out to support@wandb.com for help. Thank you for your patience!';

export const StorageTableLoadingFailure = () => {
  return (
    <div className="flex w-full items-center justify-center px-32 py-24 text-red-600">
      <div className="mr-8">
        <Icon name="warning" width={18} height={18} />
      </div>
      {LOADING_ERROR}
    </div>
  );
};
