import {notEmpty} from '@wandb/weave/common/util/obj';
import update, {Query} from 'immutability-helper';
import {isArray, isEqual, uniqBy} from 'lodash';

import * as Artifact from './artifacts';
import {
  DateOperators,
  DefaultOperators,
  FunctionalOperators,
  multiValueOps,
  ops,
  WITHINSECONDS_OP_NAME,
} from './filtersDefaults';
import {
  AndFilter,
  Filter,
  FilterKey,
  GroupFilter,
  IndividualFilter,
  IndividualOp,
  MultiValueFilter,
  MultiValueOp,
  RootFilter,
  TimeOption,
  TimeOptionData,
  ValueFilter,
  ValueOp,
} from './filterTypes';
// eslint-disable-next-line import/no-cycle
import * as Run from './runs';
import * as RunTypes from './runTypes';
import {Key, RunKeySection, runKeySections} from './runTypes';

export function isRunKey(k: FilterKey): k is Key {
  return runKeySections.includes(k.section);
}

export function displayKey(k: FilterKey) {
  if (isRunKey(k)) {
    return Run.displayKey(k);
  }

  return Artifact.displayKey(k);
}

export function keyToServerPath(k: FilterKey) {
  if (isRunKey(k)) {
    return Run.keyToServerPath(k);
  }

  return Artifact.keyToServerPath(k);
}

export function keyToString(k: FilterKey) {
  if (isRunKey(k)) {
    return Run.keyToString(k);
  }

  return Artifact.keyToString(k);
}

export function isIndividualFilter<K extends FilterKey>(
  f: Filter<K>
): f is IndividualFilter<K> {
  return `key` in f;
}
export function isRunFilter(f: Filter<FilterKey>): f is Filter<RunTypes.Key> {
  if (isIndividualFilter(f)) {
    return RunTypes.runKeySections.includes(f.key.section);
  }

  return f.filters.length === 0 || f.filters.every(filt => isRunFilter(filt));
}

export const getKeyAllowedOps = (key: FilterKey): IndividualOp[] => {
  if (key.name === 'createdAt') {
    return DateOperators;
  }
  if (key.name === 'state') {
    return FunctionalOperators;
  }

  return DefaultOperators;
};

export const getDefaultAbsoluteDateValue = (now?: Date) => {
  now = now || new Date();
  return now.toISOString();
};

// this is a string since the values returned by convert are also strings
export const getDefaultRelativeDateValue = () => '0';

export const unitsSelectOptions = new Map<TimeOption, TimeOptionData>([
  ['seconds', {displayText: 'Seconds', numSeconds: 1}],
  ['minutes', {displayText: 'Minutes', numSeconds: 60}],
  ['hours', {displayText: 'Hours', numSeconds: 60 * 60}],
  ['days', {displayText: 'Days', numSeconds: 24 * 60 * 60}],
]);

export const checkedGetTimeOptionData = (unit: TimeOption): TimeOptionData => {
  const res = unitsSelectOptions.get(unit);
  if (!res) {
    throw new Error('improper unit for checkedGetTimeOptionData:' + unit);
  }
  return res;
};

export const getSortedTimeOptions = (
  minimumUnit: TimeOption | null
): Array<[TimeOption, TimeOptionData]> => {
  const minimumScale = minimumUnit
    ? checkedGetTimeOptionData(minimumUnit).numSeconds
    : 0;
  const options = Array.from(unitsSelectOptions.entries()).filter(
    ([unit, data]) => data.numSeconds >= minimumScale
  );

  return options.sort(([unit, data]) => data.numSeconds);
};

// Run name is always not equal to null. selectionmanager uses this to get
// a true value
export const TRUE_FILTER: IndividualFilter<RunTypes.Key> = {
  key: {
    section: 'run',
    name: 'name',
  },
  op: '!=',
  value: null,
};

// Run name never equals null. selectionmanager uses this to get a
// false value
export const FALSE_FILTER: IndividualFilter<RunTypes.Key> = {
  key: {
    section: 'run',
    name: 'name',
  },
  op: '=',
  value: null,
};

export const CREATED_AT_KEY = {section: 'run', name: 'createdAt'} as const;

export function isGroup<K extends FilterKey>(
  filter: Filter<K>
): filter is GroupFilter<K> {
  return (filter as GroupFilter<K>).filters !== undefined;
}

export function isIndividual<K extends FilterKey>(
  filter: Filter<K>
): filter is IndividualFilter<K> {
  return (filter as IndividualFilter<K>).key !== undefined;
}

export function isRootFilter<K extends FilterKey>(
  filter: Filter<K>
): filter is RootFilter<K> {
  if (!isGroup(filter)) {
    return false;
  }
  if (filter.op !== 'OR') {
    return false;
  }
  if (filter.filters.length === 0) {
    return false;
  }
  const firstOrChild = filter.filters[0];
  if (firstOrChild.op !== 'AND') {
    return false;
  }
  return true;
}

export const assertIsValueFilter = <K extends FilterKey>(
  filter: IndividualFilter<K>
): ValueFilter<K> => {
  if (isMultiOp(filter.op)) {
    throw new Error('assertIsValueFilter bad op type: ' + filter.op);
  }
  return filter as ValueFilter<K>;
};

export const assertIsDateOp = (op: IndividualOp): ValueOp => {
  if (['>=', '<=', WITHINSECONDS_OP_NAME].indexOf(op) === -1) {
    throw new Error('assertIsRelativeDateOp bad op type: ' + op);
  }
  return op as ValueOp;
};

export function isMultiValue<K extends FilterKey>(
  filter: IndividualFilter<K>
): filter is MultiValueFilter<K> {
  return isMultiOp(filter.op);
}

export function isMultiOp(op: IndividualOp): op is MultiValueOp {
  return multiValueOps.indexOf(op) !== -1;
}

export enum ValueType {
  Default = 0,
  Date = 1,
  Duration = 2,
  Tags = 3,
}

export const getFilterValueType = (filter: IndividualFilter) => {
  if (
    (filter.key.section === 'run' && filter.key.name === 'createdAt') ||
    (filter.key.section === 'artifact' && filter.key.name === 'createdAt')
  ) {
    if (filter.op === WITHINSECONDS_OP_NAME) {
      return ValueType.Duration;
    }
    return ValueType.Date;
  }

  if (filter.key.section === 'run' && filter.key.name === 'duration') {
    return ValueType.Duration;
  }

  if (filter.key.section === 'tags') {
    return ValueType.Tags;
  }

  return ValueType.Default;
};

export function isEmpty<K extends FilterKey>(filter: Filter<K>): boolean {
  return isIndividual(filter) && filter.key.name === '';
}

// We don't just use _.isEqual, because we inject view object refs everywhere
// but not always :(
export function filtersAreEqual(
  left: IndividualFilter,
  right: IndividualFilter
) {
  return (
    left.key.section === right.key.section &&
    left.key.name === right.key.name &&
    left.op === right.op &&
    left.value === right.value
  );
}

type QueryPathItem = number | string;

// legacy
export const EMPTY_FILTERS: RootFilter<RunTypes.Key> = {
  op: 'OR',
  filters: [
    {
      op: 'AND',
      filters: [],
    },
  ],
};

export const DEFAULT_FILTERS: Filter<FilterKey> = {
  op: 'OR',
  filters: [],
};

export const MATCH_NONE_FILTER: IndividualFilter<RunTypes.Key> = {
  key: {section: 'run', name: 'name'},
  op: '=',
  value: '<NONE>',
};

// A "freeze" filter (for freezing runsets) is just "createdAt <="
export function isFreezeFilter(filter: IndividualFilter): boolean {
  return isEqual(filter.op, '<=') && isEqual(filter.key, CREATED_AT_KEY);
}

export function findFreezeFilterIndex<K extends FilterKey>(
  rootFilter: RootFilter<K>
): number {
  return rootFilter.filters[0].filters.findIndex(f =>
    isFreezeFilter(f as IndividualFilter)
  );
}

function notNull<TValue>(value: TValue | null): value is TValue {
  return value !== null;
}

export function match(
  filter: Filter<RunTypes.Key>,
  run: RunTypes.Run
): boolean {
  return matchWithDisabled(filter, run) ?? true;
}

export function matchWithDisabled(
  filter: Filter<RunTypes.Key>,
  run: RunTypes.Run
): boolean | null {
  /**
   * (OR, [true, disabled, false]) => true
   * (AND, [true, disabled, false]) => false
   * (OR, [disabled]) => true
   * (AND, [disabled]) => true
   * (OR, []) => true
   * (AND, []) => true
   * (OR, [disabled, disabled]) => true
   * (AND, [disabled, disabled]) => true
   *
   * With Individual filters, it returns bool | null
   * disabled => null
   *
   */
  if (isGroup(filter)) {
    const result = filter.filters.map(f => matchWithDisabled(f, run));
    const booleanResult = result.filter(notNull);
    if (booleanResult.length === 0) {
      // if length of the filters is zero, maybe becaues all the filters
      // are disabled or no filters, we return true
      return true;
    }
    if (filter.op !== 'AND') {
      return booleanResult.some(o => o);
    }

    return booleanResult.every(o => o);
  }

  if (isIndividual(filter)) {
    if (filter.key.name === '') {
      return null;
    }
    // This is bad: we use keys_info to ask the server for runs that
    // contain specific history keys. But we use this client-side match
    // function when we do client-side grouping (run set grouping). We
    // don't have a run's history available here, so I'm just returning
    // true for any keys_info filter. We know this is ok in the couple
    // of cases that Filter.match is actually used. But we should fix
    // this eventually by either only ever filtering on the server, or
    // making sure run has the history data available here and check it.
    // TODO: fix.
    // console.log(filter);
    if (filter.disabled != null && filter.disabled) {
      return null;
    }
    if (filter.key.section === 'keys_info') {
      return true;
    }
    const value = Run.getValue(run, filter.key);
    if (filter.op === '=') {
      if (filter.value === '*') {
        return value != null;
      }
      return filter.value === value;
    }

    if (filter.op === '!=') {
      if (filter.value === '*') {
        return value === null;
      }
      return filter.value !== value;
    }
    if (filter.value != null) {
      if (isMultiValue(filter) && filter.value.length > 0) {
        if (filter.op === 'IN') {
          return filter.value.includes(value);
        }
        if (filter.op === 'NIN') {
          return !filter.value.includes(value);
        }
      }

      if (value != null) {
        if (filter.op === '<') {
          return value < filter.value!;
        } else if (filter.op === '>') {
          return value > filter.value!;
        } else if (filter.op === '<=') {
          return value <= filter.value!;
        } else if (filter.op === '>=') {
          return value >= filter.value!;
        }
      }
    }
  }

  return false;
}

export function filterRuns(filter: Filter<RunTypes.Key>, runs: RunTypes.Run[]) {
  return runs.filter(run => match(filter, run));
}

export function And<K extends FilterKey>(filters: Array<Filter<K>>): Filter<K> {
  // We can merge if we have all individual and AND filters
  // It would be better to implement this by making a good simplify() function and then
  // calling it on {op: 'AND', filters}
  const andFilts = filters.filter(
    f => isIndividual(f) || (isGroup(f) && f.op === 'AND')
  );
  if (andFilts.length === filters.length) {
    return {
      op: 'AND',
      filters: andFilts.flatMap(f => (isIndividual(f) ? [f] : f.filters)),
    };
  }
  return {op: 'AND', filters};
}

export function Or<K extends FilterKey>(filters: Array<Filter<K>>): Filter<K> {
  // We can merge if we have all individual and OR filters
  const orFilts = filters.filter(
    f => isIndividual(f) || (isGroup(f) && f.op === 'OR')
  );
  if (orFilts.length === filters.length) {
    return {
      op: 'OR',
      filters: orFilts.flatMap(f => (isIndividual(f) ? [f] : f.filters)),
    };
  }
  return {op: 'OR', filters};
}

export class Update {
  static groupPush<T>(
    owner: T,
    path: QueryPathItem[],
    filter: Filter<FilterKey> | Array<Filter<FilterKey>>
  ): T {
    return update(
      owner,
      genUpdate(path, {
        filters: {$push: Array.isArray(filter) ? filter : [filter]},
      })
    );
  }

  static groupRemove<T>(owner: T, path: QueryPathItem[], index: number): T {
    return update(owner, genUpdate(path, {filters: {$splice: [[index, 1]]}}));
  }

  static setFilter<T>(
    owner: T,
    path: QueryPathItem[],
    filter: Filter<FilterKey>
  ): T {
    return update(owner, genUpdate(path, {$set: filter}));
  }
}

// Can't build this up as query directly, so we take the Tree type from
// immutability-helper and use it.
type Tree<T> = {[K in keyof T]?: Query<T[K]>};
function genUpdate(path: QueryPathItem[], updateQuery: Query<any>): Query<any> {
  const result: Tree<any> = {};
  let node = result;
  path.forEach(pathItem => {
    node.filters = {[pathItem]: {}};
    node = node.filters[pathItem] as Tree<any>;
  });
  Object.assign(node, updateQuery);
  return result;
}

// This is a centralized place to merge mergefilters with the filters.
// This will be nested a ton, so likely won't be renderable. Should only be used
// as an argument to a graphql query.
export function mergedFilters<K extends FilterKey>(
  filters: Filter<K>,
  mergeFilters?: Filter<K>
): Filter<K> {
  const resultFilters = [filters];
  if (mergeFilters != null) {
    resultFilters.push(mergeFilters);
  }
  return And(resultFilters);
}

///// check* functions and fromJson are used for making sure server data is
// in the format we expect. We convert it to safely typed TypeScript data.

function checkIndividualFilter(
  filter: any
): IndividualFilter<RunTypes.Key> | null {
  if (ops.indexOf(filter.op) === -1) {
    return null;
  }
  let filterKey: RunTypes.Key | null;
  // We allow both colon-separate string or object formats.
  if (typeof filter.key === 'string') {
    filterKey = Run.keyFromString(filter.key);
  } else if (typeof filter.key === 'object') {
    filterKey = Run.checkKey(filter.key.section, filter.key.name);
  } else {
    return null;
  }

  if (filterKey == null) {
    return null;
  }
  if (isMultiValue<RunTypes.Key>(filter)) {
    return {
      key: filterKey,
      op: filter.op,
      value: filter.value,
    };
  }

  return {
    key: filterKey,
    op: filter.op,
    value: Run.parseValue(filter.value),
  };
}

function checkGroupFilter(filter: any): GroupFilter<RunTypes.Key> | null {
  if (filter.op !== 'AND' && filter.op !== 'OR') {
    return null;
  }
  const filters = checkGroupFilterSet(filter.filters);
  if (!filters) {
    return null;
  }
  return {
    op: filter.op,
    filters,
  };
}

function checkGroupFilterSet(filters: any): Array<Filter<RunTypes.Key>> | null {
  if (!(filters instanceof Array)) {
    return null;
  }
  const result = filters.map(checkFilter);
  if (result.some(o => o == null)) {
    return null;
  }
  // We know none of them are null after the check above.
  return result as Array<Filter<RunTypes.Key>>;
}

function checkFilter(filter: any): Filter<RunTypes.Key> | null {
  if (filter.op === 'AND' || filter.op === 'OR') {
    return checkGroupFilter(filter);
  }
  return checkIndividualFilter(filter);
}

function checkAndFilter(filter: any): Filter<RunTypes.Key> | null {
  const parsed = checkFilter(filter);
  if (parsed == null) {
    return null;
  }
  if (parsed.op !== 'AND') {
    return null;
  }
  return parsed;
}

function checkOrOfAndFilter(filter: any): Filter<RunTypes.Key> | null {
  const parsed = checkFilter(filter);
  if (parsed == null) {
    return null;
  }
  if (parsed.op !== 'OR') {
    return null;
  }
  if (parsed.filters.length === 0) {
    return null;
  }
  if (parsed.filters[0].op !== 'AND') {
    return null;
  }
  return parsed;
}

export function fromJson(json: any): Filter<RunTypes.Key> | null {
  if (json == null) {
    return null;
  }
  return checkFilter(json);
}

export function fromJsonAnd(json: any): Filter<RunTypes.Key> | null {
  if (json == null) {
    return null;
  }
  return checkAndFilter(json);
}

export function fromJsonOrOfAnd(json: any): Filter<RunTypes.Key> | null {
  if (json == null) {
    return null;
  }
  return checkOrOfAndFilter(json);
}

export function fromOldURL(
  filterStrings: string[]
): Filter<RunTypes.Key> | null {
  /* Read filters from the old URL format */
  const result = filterStrings.map(filterString => {
    let parsed;
    try {
      parsed = JSON.parse(filterString);
    } catch (e) {
      return null;
    }
    if (!isArray(parsed) || parsed.length !== 3) {
      return null;
    }
    const [keyString, op, valueAny] = parsed;
    const value: RunTypes.Value = valueAny;
    const key = Run.keyFromString(keyString);
    if (key == null || key.section == null || key.name == null) {
      return null;
    }
    return {key, op, value};
  });
  if (result.some(f => f == null)) {
    return null;
  }
  const andFilters = checkGroupFilterSet(result);
  if (andFilters == null) {
    return null;
  }
  return {op: 'OR', filters: [{op: 'AND', filters: andFilters}]};
}

export function fromOldQuery(oldQuery: any): Filter<RunTypes.Key> | null {
  // Parses filters stored in the old format. Not super safe.
  if (!isArray(oldQuery)) {
    return null;
  }
  const individualFilters: any = oldQuery
    .map((f: any) =>
      f.key && f.key.section && f.key.value && f.op && f.value
        ? {
            key: {section: f.key.section, name: f.key.value},
            op: f.op,
            value: f.value,
          }
        : null
    )
    .filter(o => o);
  return {op: 'OR', filters: [{op: 'AND', filters: individualFilters}]};
}

export function fromURL(filterString: string): Filter<RunTypes.Key> | null {
  let result;
  try {
    result = JSON.parse(filterString);
  } catch {
    return null;
  }
  return fromJson(result);
}

export function toURL(filter: Filter<RunTypes.Key>): string {
  return JSON.stringify(filter);
}

export function flatIndividuals(
  filter: Filter<RunTypes.Key>
): Array<IndividualFilter<RunTypes.Key>> {
  if (isIndividual(filter)) {
    return [filter];
  } else if (isGroup(filter)) {
    return filter.filters.reduce(
      (acc, f) => acc.concat(flatIndividuals(f)),
      [] as Array<IndividualFilter<RunTypes.Key>>
    );
  }
  return [];
}

export function countIndividual(filter: Filter<RunTypes.Key>): number {
  return flatIndividuals(filter).length;
}

export function displayIndividualValue(filter: IndividualFilter): string {
  let value: string = '';
  if (isMultiValue(filter)) {
    if (filter.value != null) {
      value = filter.value.join(',');
    } else {
      value = 'null;';
    }
  } else {
    value = Run.displayValue(filter.value);
  }
  return value;
}

function displayIndividual(filter: IndividualFilter): string {
  return (
    displayKey(filter.key) +
    ' ' +
    filter.op +
    ' ' +
    displayIndividualValue(filter)
  );
}

export function summaryString(filter: Filter<RunTypes.Key>): string {
  // This just finds all individual filters in the tree and displays
  // them as a comma-separated list. Obviously not fully descriptive,
  // but works for the common case where we have a single AND within
  // an OR
  return flatIndividuals(filter).map(displayIndividual).join(', ');
}

export function domValue(
  filter: IndividualFilter<RunTypes.Key>
): RunTypes.DomValue | RunTypes.DomValue[] {
  if (isMultiValue(filter)) {
    if (filter.value == null) {
      return [];
    }

    return filter.value.map(Run.domValue);
  }

  return Run.domValue(filter.value);
}

export function simplifyInner<K extends FilterKey>(
  filter: Filter<K>
): Filter<K> | boolean | null {
  // Removes any group filters that are empty.
  // Converts group filters with one child to individual filters
  if (isGroup(filter)) {
    // Simplify children first
    const simplifiedChildren = filter.filters
      .map(simplifyInner)
      .filter(notEmpty);

    if (simplifiedChildren.length === 0) {
      return null;
    }

    let newChildren: Array<Filter<K>> = [];
    if (filter.op === 'AND') {
      for (const childFilter of simplifiedChildren) {
        if (childFilter === false) {
          return false;
        } else if (childFilter === true) {
          // x AND true = x
          // skip
        } else if (childFilter.op === filter.op && isGroup(childFilter)) {
          // x AND (y AND z) = x AND y AND z
          newChildren = newChildren.concat(childFilter.filters);
        } else {
          newChildren.push(childFilter);
        }
      }
      if (newChildren.length === 0) {
        return true;
      }
    } else if (filter.op === 'OR') {
      for (const childFilter of simplifiedChildren) {
        if (childFilter === true) {
          // x OR true = true
          return true;
        } else if (childFilter === false) {
          // x OR false = x
          // skip
        } else if (childFilter.op === filter.op && isGroup(childFilter)) {
          // x OR (y OR z) = x OR y OR z
          newChildren = newChildren.concat(childFilter.filters);
        } else {
          newChildren.push(childFilter);
        }
      }
      if (newChildren.length === 0) {
        return false;
      }
    }

    if (newChildren.length === 1) {
      // A group filter with one child is equivalent to the child.
      return newChildren[0];
    }

    return {op: filter.op, filters: newChildren};
  }

  // convert run name filters, used by selection manager to produce
  // true/false
  if (filtersAreEqual(filter, TRUE_FILTER)) {
    return true;
  }
  if (filtersAreEqual(filter, FALSE_FILTER)) {
    return false;
  }

  return filter;
}

export function simplify(
  filter: Filter<RunTypes.Key>
): Filter<RunTypes.Key> | boolean {
  const result = simplifyInner(filter);
  if (result == null) {
    // No filters at all, just match everything
    return true;
  }
  return result;
}

export function simplifiedFiltersToValidFilters(
  filters: Filter<RunTypes.Key> | true | false
): Filter<RunTypes.Key> {
  if (filters === false) {
    return FALSE_FILTER;
  }

  if (filters === true) {
    return TRUE_FILTER;
  }
  return filters;
}

export function simplifyFilters(
  filter: Filter<RunTypes.Key>
): Filter<RunTypes.Key> {
  return simplifiedFiltersToValidFilters(simplify(filter));
}

const INDIVIDUAL_OP_TO_MONGO: {[op in IndividualOp]: string} = {
  '=': '', // Here for type completion
  '!=': '$ne',
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
  IN: '$in',
  NIN: '$nin',
  REGEX: '$regex',
  EXISTS: '$exists',
  CONTAINS: '$contains',
  CONTAINS_WILDCARD: '$contains_wildcard',
  WITHINSECONDS: '$withinseconds',
};
function toMongoOpValue(
  op: IndividualOp,
  value: RunTypes.Value | RunTypes.Value[]
): any {
  if (op === '=') {
    return value;
  } else {
    return {[INDIVIDUAL_OP_TO_MONGO[op]]: value};
  }
}

function toMongoIndividual(filter: IndividualFilter): any {
  if (filter.key.name === '') {
    return null;
  }

  // In scatter plots, "Index" is a special option that just sorts the
  // runs in order. It's not really a key, so selection won't work.
  // As a result, page will crash and error with
  // "Error 1054 (42S22): Unknown column 'Index' in 'where clause'""
  // This seems like a hack we inherited 2 years ago, so we should
  // reconsider how we do filtering in scatter plots.
  if (filter.key.name === 'Index') {
    return null;
  }

  // It's only valid to compare null to '=' and '!='. We need to support the comparing to null so
  // that we can send down a filter to select the null group (when grouping the table).
  // This also allows the user to specify an '= null' filter. But a '> null' filter will get
  // automatically stripped (these cause a backend sqlalchemy crash) for example.
  // TODO: Make it impossible for the UI to generate filters that don't make sense, like
  // '> null'.
  if (filter.value == null && filter.op !== '=' && filter.op !== '!=') {
    return null;
  }

  // check for disabled filter
  // if disabled flag is undefined by default and it means
  // filter is enabled
  if (filter.disabled != null && filter.disabled) {
    return null;
  }

  if (filter.key.section === 'tags') {
    if (filter.op === 'IN') {
      return {tags: {$in: filter.value}};
    }
    if (filter.op === 'NIN') {
      return {tags: {$nin: filter.value}};
    }
    if (filter.value === false) {
      return {
        $or: [{tags: null}, {tags: {$ne: filter.key.name}}],
      };
    }

    return {tags: filter.key.name};
  }
  const path = keyToServerPath(filter.key);
  if (path == null) {
    return path;
  }
  // If the op is a comparison, ex <, >, then we need to treat the value as
  // scalar.
  if (opIsComparison(filter.op) && !valueIsScalar(filter.value)) {
    return {
      [path]: toMongoOpValue(filter.op, JSON.stringify(filter.value)),
    };
  }
  return {
    [path]: toMongoOpValue(filter.op, filter.value),
  };
}

const GROUP_OP_TO_MONGO = {
  AND: '$and',
  OR: '$or',
};
export function toMongo(filter: Filter<FilterKey>): any {
  if (isIndividual(filter)) {
    return toMongoIndividual(filter);
  }
  if (isGroup(filter)) {
    return {
      [GROUP_OP_TO_MONGO[filter.op]]: filter.filters
        .map(toMongo)
        .filter(o => o),
    };
  }
}

export function toString(filter: Filter<FilterKey> | true | false): string {
  if (filter === true) {
    return 'true';
  }
  if (filter === false) {
    return 'false';
  }
  if (isGroup(filter)) {
    return '(' + filter.filters.map(toString).join(' ' + filter.op + ' ') + ')';
  }

  const key = keyToString(filter.key);
  return (
    key +
    ' ' +
    filter.op +
    ' ' +
    (filter.value == null ? 'NULL' : filter.value.toString())
  );
}

export function asGroup<K extends FilterKey>(
  filter: Filter<K>
): GroupFilter<K> {
  return {op: 'OR', filters: [filter]};
}

// Filter Factories
export const hideCrashed: IndividualFilter<RunTypes.Key> = {
  op: '!=',
  value: 'crashed',
  key: {name: 'state', section: 'run'},
};
export const FILTER_LABEL_HIDE_CRASHED = 'Hide crashed runs';

export function usernameFilter(
  username: string
): IndividualFilter<RunTypes.Key> {
  return {
    op: '=',
    value: username,
    key: {name: 'username', section: 'run'},
  };
}
export const FILTER_LABEL_SHOW_MY_WORK = 'Show only my work';

// NOTE: the defaults here aren't great - the user probably wants to search on
// the "displayName", not the "name" ("name" is the internal name, usually a random sequence of characters)
export function runRegexFilter(
  regex: string,
  section: RunKeySection = 'run',
  name: string = 'name'
): IndividualFilter<RunTypes.Key> {
  return {
    op: 'REGEX',
    value: regex,
    key: {section, name},
  };
}

export function runContainsFilter(
  queryString: string,
  section: RunKeySection = 'run',
  name: string = 'name'
): IndividualFilter<RunTypes.Key> {
  return {
    op: 'CONTAINS',
    value: queryString,
    key: {section, name},
  };
}

export function runContainsWildcardFilter(
  queryString: string,
  section: RunKeySection = 'run',
  name: string = 'name'
): IndividualFilter<RunTypes.Key> {
  return {
    op: 'CONTAINS_WILDCARD',
    value: queryString,
    key: {section, name},
  };
}

export function runNamesFilter(
  names: string[]
): MultiValueFilter<RunTypes.Key> {
  return {
    key: {section: 'run', name: 'name'},
    op: 'IN',
    value: names,
  };
}

export function runCreatedAtFilter(
  op: ValueOp,
  value: string
): ValueFilter<typeof CREATED_AT_KEY> {
  return {
    op,
    key: CREATED_AT_KEY,
    value,
  };
}

export const defaultNewRunFilter: Filter<RunTypes.Key> = {
  key: {section: 'run', name: 'duration'},
  op: '>=',
  // TODO(volta): Make this value not stack,
  // Currently it only accepts values that have an equivalent.
  value: '5',
  meta: {unit: 'seconds'},
};

// Walk filter tree, applying user's function
export function treeMap<K extends FilterKey>(
  filter: Filter<K>,
  fn: (f: Filter<K>) => Filter<K>
): Filter<K> {
  const applied = fn(filter);
  if (isGroup(applied)) {
    return {...applied, filters: applied.filters.map(f => treeMap(f, fn))};
  }
  return applied;
}

// Walk filter tree, applying user's function to leaf nodes
export function treeForEach<K extends FilterKey>(
  filter: Filter<K>,
  fn: (f: Filter<K>) => void
): void {
  if (isGroup(filter)) {
    filter.filters.forEach(f => treeForEach(f, fn));
  } else {
    fn(filter);
  }
}

export function getUniqueKeysUsedInFilter<K extends FilterKey>(
  filter: Filter<K>
): K[] {
  const keys: K[] = [];
  treeForEach(filter, f => {
    if (isIndividual(f)) {
      keys.push(f.key);
    }
  });
  return uniqBy(keys, keyToString);
}

export function fixRunFilter(
  filter: Filter<RunTypes.Key>
): Filter<RunTypes.Key> {
  return treeMap(filter, f => {
    if (!isIndividual(f)) {
      return f;
    }

    if (typeof f.key === 'string') {
      return {...f, key: f.key};
    }

    return {...f, key: Run.fixConfigKey(f.key)};
  });
}

export function rootFilterSingle<K extends FilterKey>(
  filter: IndividualFilter<K>
): RootFilter<K> {
  return {
    op: 'OR',
    filters: [
      {
        op: 'AND',
        filters: [filter],
      },
    ],
  };
}

export function getAndFilterFromRootFilter<K extends FilterKey>(
  root: RootFilter<K>
): AndFilter<K> {
  if (!isRootFilter(root)) {
    throw new Error('filter is not a RootFilter');
  }
  return root.filters[0];
}

export function getFilterListFromRootFilter<K extends FilterKey>(
  root: RootFilter<K>
): Array<Filter<K>> {
  return getAndFilterFromRootFilter(root).filters;
}

function opIsComparison(op: IndividualOp): boolean {
  return ['>', '>=', '<', '<='].includes(op);
}

function valueIsScalar(value: RunTypes.Value | RunTypes.Value[]): boolean {
  return !Array.isArray(value) && typeof value !== 'object';
}
