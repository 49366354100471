import {useEffect} from 'react';
import {OperationVariables, QueryHookOptions} from 'react-apollo';

import {propagateErrorsContext} from '../../util/errors';
import {usePollInterval} from '../polling/hooks';
import {useQuery as useApolloQuery} from './apolloWithAbort';

// Our version of useQuery. It returns a loading state that can
// be used in a type-guard to ensure we get non-null data values
// when loading is false.

export type UseQueryOptions<D = any, V = OperationVariables> = Omit<
  QueryHookOptions<D, V>,
  'pollInterval'
> & {
  enablePolling?: boolean;
  pollMultiplier?: number;
};

export function useQuery<D, V>(document: any, options: UseQueryOptions<D, V>) {
  const globalPollInterval = usePollInterval();

  let pollInterval = options.enablePolling
    ? globalPollInterval *
      (options.pollMultiplier != null ? options.pollMultiplier : 1)
    : 0;
  if (pollInterval === 0) {
    pollInterval = 365 * 24 * 60 * 60000;
  }

  const {loading, data, refetch, networkStatus, fetchMore, error} =
    useApolloQuery<D, V>(document, {
      ...options,
      context: {...propagateErrorsContext(), ...options.context},
      pollInterval,
    });

  // Workaround for Apollo bug where old queries continue running in the background after component unmounts, causing
  // remounted components to use the old query result (and not update until next tick of the polling loop).
  // Forces a refetch on the first render to ensure the query kicks off when the component mounts.
  // This does not block the paint of the component as the previous query result is used while the new query fetches.
  // Reference: https://github.com/apollographql/apollo-client/issues/6391
  useEffect(() => {
    if (!loading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    data,
    refetch,
    networkStatus,
    fetchMore,
    error,
    initialLoading: Boolean(networkStatus === 1 || options.skip),
  };
}
