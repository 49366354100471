import {makeTrackEvent} from './makeTrackEvent';

export type SettingAction =
  | 'clear'
  | 'click'
  | 'blur'
  | 'change'
  | 'open drawer'
  | 'open section drawer'
  | 'open modal'
  | 'open popup';
export type Page = 'workspace' | 'report';
export type SettingLevel = 'section' | 'workspace' | 'panel' | 'report';
export type SettingType =
  | 'autoOrganizePrefix'
  /** Whether run names should be colored in panel tooltips */
  | 'colorRunNames'
  | 'displayFullRunName' // if the whole run name should be displayed
  | 'excludeOutliers'
  | 'highlightedCompanionRunOnly'
  | 'limit'
  /**
   * Workspace setting level uses `maxRuns`, but panel config uses `limit`.
   * This setting tells us maximum number of runs to include for a panel
   */
  | 'maxRuns'
  | 'numberOfRuns' // The number of runs selected by the user
  | 'pointVisualizationMethod' // The point visualization method selected by the user
  | 'removeLegends' // Whether or not the user has enabled panel legend removal
  | 'sectionGrouping' // The section grouping selected by the user
  | 'shouldAutoGeneratePanels'
  | 'showEmptySections' // Whether or not the user has enabled empty sections
  | 'showLegend'
  | 'smoothing'
  | 'smoothingType'
  | 'smoothingWeight'
  | 'sortAlphabetically'
  /**
   * workspace setting level uses `supressLegends` but panel configs use `showLegend`.
   * We want resolve to the panel config when we feed in setting information.
   * This setting tells us whether legends should be hidden in runs line plots
   */
  | 'suppressLegends'
  | 'tooltipNumberOfRuns' // The tooltip setting which controls number of runs in the panel overlay
  | 'tooltipsHighlightedRunOnly' // Whether or not a user is viewing just the highlighted run in companion plots
  | 'tooltipsRunColorsEnabled' // Whether or not the user has enabled run colors
  | 'useRunsTableGroupingInPanels' // respect the runs table grouping in the chart
  | 'xAxis'
  | 'xAxisMin'
  | 'xAxisMax'
  | 'yAxis'
  | 'yAxisMax'
  | 'yAxisMin';

export type TrackSettingProperties = {
  action: SettingAction;
  page: Page;
  settingLevel?: SettingLevel;
  setting?: SettingType;
  settingValue?: string;
  additionalProperties?: Record<string, any>;
};
export const trackSetting = makeTrackEvent<
  TrackSettingProperties,
  {
    action: {
      description: 'what type of action was performed';
      exampleValues: ['click', 'blur', 'change'];
    };
    page: {
      description: 'differentiates if setting was changed in workspace or reports';
      exampleValues: ['workspace', 'report'];
    };
    settingLevel: {
      description: 'which level is this setting being used within the page';
      exampleValues: ['section', 'workspace', 'panel'];
    };
    setting: {
      description: 'what is the setting that user interacted with';
      exampleValues: ['yAxis', 'xAxis'];
    };
    settingValue: {
      description: 'Stringifed version of the value because value is a reserved property in Segment. We also need to ensure data type is supported in Big Query.';
      exampleValues: ['true', '1', 'sampling'];
    };
    additionalProperties: {
      description: 'additional properties related to setting';
      exampleValues: [{description: 'selection changed'}];
    };
    _description: 'Tracks events related to settings';
    _location: 'Customization happens in both workspace and report pages';
    _motivation: 'To track usage';
  }
>('Settings');
