import {convertToModelsSeatType} from '../pages/Billing/AccountSettings/SettingsTab/OrganizationDefaultModelsSeatSetting';
import {OrganizationFlags} from '../util/accounts/pricing';
import {jsonParseSafe} from '../util/json';
import {ModelsSeatType} from './MembersTable/util';
import {MatchingOrganizations} from './useMatchingOrganizations';

export type MatchingTeamType = {
  id: string;
  name: string;
  orgName: string;
  memberCount: number;
  defaultModelsSeat: ModelsSeatType | undefined;
};

export function getMatchedTeams(
  openMatchingOrganizations: MatchingOrganizations,
  isOnboardingFlow: boolean,
  userTeamIDs: Set<string>
): {
  matchingTeams: MatchingTeamType[];
  defaultMatchingTeams: MatchingTeamType[];
} {
  const matches: MatchingTeamType[] = [];
  const defaultMatches: MatchingTeamType[] = [];
  openMatchingOrganizations.forEach(({name: orgName, teams, flags}) => {
    const orgFlags: OrganizationFlags =
      flags != null ? jsonParseSafe(flags) : {};
    const defaultEntityID = orgFlags.default_entity_id_for_domain_matching;
    const defaultModelsSeat = convertToModelsSeatType(
      orgFlags.default_models_seat
    );

    teams.forEach(({name, id, memberCount, settings}) => {
      if (
        (isOnboardingFlow || !userTeamIDs.has(id)) &&
        settings.openToMatchingOrgEmailDomainUsers
      ) {
        if (id === defaultEntityID) {
          defaultMatches.push({
            name,
            orgName,
            memberCount,
            id,
            defaultModelsSeat,
          });
        } else {
          matches.push({
            name,
            orgName,
            memberCount,
            id,
            defaultModelsSeat,
          });
        }
      }
    });
  });
  return {
    matchingTeams: matches.sort((a, b) => b.memberCount - a.memberCount),
    defaultMatchingTeams: defaultMatches.sort(
      (a, b) => b.memberCount - a.memberCount
    ),
  };
}
