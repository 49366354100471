// Info about a single render. Used for logging
import {envIsDev} from '../../config';
import {RenderLogDatapoint} from './types';

const logThresholds = {
  renderCount: 0, // log if render count is greater than this
  renderDuration: 0, // log if last render duration is greater than this (milliseconds)
};

// Logs render count, average render duration, median render duration, and last render duration.
// Currently only logs to the console, enabled if isMakeContextDebugEnabled is true and logThresolds are met
export function logRender<ContextValue>({
  contextDisplayName,
  // contextValue,
  allRenders,
}: {
  contextDisplayName: string;
  // contextValue?: ContextValue, // Note: enabling this might freeze your tab with a lot of data
  allRenders: Array<RenderLogDatapoint<ContextValue>>;
}) {
  const renderCount = allRenders.length;

  if (renderCount < logThresholds.renderCount) {
    return;
  }

  const allRenderDurations = allRenders.map(
    logData => logData.endTime - logData.startTime
  );
  const averageRenderDuration =
    allRenderDurations.reduce((sum, duration) => sum + duration, 0) /
    allRenderDurations.length;
  // We copy the array here ([...allRenderDurations]) because array.sort() mutates, and array.toSorted is not available (ES2023)
  const medianRenderDuration = [...allRenderDurations].sort()[
    Math.floor(allRenderDurations.length / 2)
  ];

  const lastRenderDuration = allRenderDurations[allRenderDurations.length - 1];

  if (lastRenderDuration < logThresholds.renderDuration) {
    return;
  }

  logdev(contextDisplayName, {
    renderCount,
    allLogData: allRenders,
    // Render durations
    last: lastRenderDuration,
    average: averageRenderDuration,
    median: medianRenderDuration,
    allRenderDurations,
  });
}

// console.log, but only if envIsDev is true
const logdev = (message?: any, ...optionalParams: any[]) => {
  if (envIsDev) {
    console.log(message, ...optionalParams);
  }
};
