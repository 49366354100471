import {useCallback, useEffect, useState} from 'react';

import {useUpdateUserInfoMutation} from '../generated/graphql';
import {Analytics} from '../services/analytics';
import {useViewer} from '../state/viewer/hooks';

export type ColorMode = 'light' | 'dark' | 'system';
const VALID_COLOR_SCHEMES: readonly ColorMode[] = ['light', 'dark', 'system'];

export type UseColorModeResult = {
  // The user's color scheme preference that is saved in their user info. Don't use this to determine dark mode.
  colorMode: ColorMode;

  // Update the user's color scheme preference.
  updateColorMode: (colorMode: ColorMode) => void;

  // A boolean indicating whether the current color scheme is actually rendered in dark mode.
  //
  // This is different than the user's preference, which is what we save. The user's preference
  // could be "system", which could be rendered in light mode or dark mode based on the OS's
  // color scheme.
  isDarkMode: boolean;
};

const PREFERS_DARK_MEDIA = window.matchMedia('(prefers-color-scheme: dark)');

export function useColorMode(): UseColorModeResult {
  const viewer = useViewer();
  const [updateUserInfo, {data, loading}] = useUpdateUserInfoMutation();

  const [isDarkMode, setIsDarkMode] = useState(
    viewer?.userInfo?.colorMode === 'dark'
  );
  const [colorMode, setColorMode] = useState<ColorMode>(
    viewer?.userInfo?.colorMode || 'light'
  );
  const [osColorMode, setOsColorMode] = useState<ColorMode>(
    PREFERS_DARK_MEDIA.matches ? 'dark' : 'light'
  );

  const updateColorMode = useCallback(
    (inColorMode: ColorMode) => {
      if (viewer?.userInfo == null) {
        return;
      }
      if (loading) {
        return;
      }
      if (!VALID_COLOR_SCHEMES.includes(inColorMode)) {
        Analytics.track('User unable to update color scheme', {
          colorMode: inColorMode,
          error: `${inColorMode} isn't a valid color scheme. Valid options are: ${VALID_COLOR_SCHEMES.join(
            ', '
          )}.`,
        });
        return;
      }

      // Optimistically update the color scheme state...
      setColorMode(inColorMode);

      // ...and then update the user info in the backend.
      updateUserInfo({
        variables: {
          userInfo: JSON.stringify({
            ...viewer?.userInfo,
            colorMode: inColorMode,
          }),
        },
      });
    },
    [updateUserInfo, viewer?.userInfo, loading]
  );

  // Watch for changes in the OS's color scheme
  useEffect(() => {
    const watchKeyboardShortcut = (event: KeyboardEvent) => {
      if (loading) {
        return;
      }
      // event.altKey - pressed Option key on Macs
      // event.ctrlKey - pressed Control key on Linux or Windows
      if ((event.altKey || event.ctrlKey) && event.code === 'KeyM') {
        event.preventDefault();

        if (isDarkMode) {
          updateColorMode('light');
        } else {
          updateColorMode('dark');
        }
      }
    };
    function watchDarkOSMedia({matches}: MediaQueryListEvent) {
      setOsColorMode(matches ? 'dark' : 'light');
    }

    document.addEventListener('keydown', watchKeyboardShortcut);
    PREFERS_DARK_MEDIA.addEventListener('change', watchDarkOSMedia);

    return () => {
      document.removeEventListener('keydown', watchKeyboardShortcut);
      PREFERS_DARK_MEDIA.removeEventListener('change', watchDarkOSMedia);
    };
  }, [isDarkMode, loading, updateColorMode]);

  // Update helper boolean for dark mode based on color scheme and OS media.
  useEffect(() => {
    let newDarkMode = false;
    if (colorMode === 'system') {
      newDarkMode = osColorMode === 'dark';
    } else {
      newDarkMode = colorMode === 'dark';
    }

    setIsDarkMode(newDarkMode);

    if (newDarkMode) {
      /**
       * @note This adds the 'night-mode' class to the <html> element. If we add the class to a different element,
       * the night mode css filter breaks position:fixed components, e.g. <ViewBar>.
       *
       * Google 'css filter position fixed' for more details. Surprising fix found here:
       * @link https://developpaper.com/explain-the-reasons-and-solutions-of-the-conflict-between-filter-and-fixed-in-detail/
       */
      document.documentElement.classList.add('night-mode');
      // Used for tailwind dark mode
      document.documentElement.setAttribute('data-mode', 'dark');
    } else {
      document.documentElement.classList.remove('night-mode');
      document.documentElement.removeAttribute('data-mode');
    }
  }, [colorMode, osColorMode, setIsDarkMode]);

  // Update the color scheme state from the user info
  useEffect(() => {
    if (data?.updateUser?.user?.userInfo.colorMode) {
      setColorMode(data.updateUser.user.userInfo.colorMode);
      Analytics.track('User updated color scheme', {
        colorMode: data.updateUser.user.userInfo.colorMode,
      });
    }
  }, [data?.updateUser?.user?.userInfo.colorMode]);

  return {
    colorMode,
    updateColorMode,
    isDarkMode,
  };
}
