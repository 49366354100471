import _ from 'lodash';

import {bucketLine} from '../../../util/plotHelpers/bucket';
import {
  evaluateExpression,
  Expression,
  expressionToString,
} from './../../../util/expr';
import {getBucketSpec} from './../../../util/plotHelpers/buckets/getBucketSpec';
import {getIdentifiers} from './../../../util/plotHelpers/expressions';
import {Line} from './../../../util/plotHelpers/types';

/**
 * Mutates the lines to update the output based on the evaluation of the defined expressions
 *
 * How line expressions are evaluated:
 * 1. If y-expressions are present, then the number of lines will be determined by the number of defined y-expressions and NOT the number of metrics listed in the y-axis input of the config
 * 2. Multi-metric expressions require additional processing: if the metrics are not identical in terms of their logged frequencies (meaning that y1, y2, etc... are plotted against identical x-values at the same intervals) then we have to compute the expressions based on an approximation. We do this by bucketing the metrics and then combining them
 */
export function evaluateYExpressions(
  lines: Line[],
  expressions: Expression[],
  supplementalMetrics: Record<string, Record<string, number>>
) {
  try {
    const newLines: Line[] = [];
    // return early on zero length lines
    if (lines.length === 0 || lines.every(line => line.data.length === 0)) {
      return [];
    }

    /**
     * Assume two expressions (`val1 + val2`, `val3 / val1`) for 10 runs that each log val1, val2, ..., val50
     * That will result in a query with keys `val1, val2, val3` with 10 runs for each
     * those 10 runs will get expanded into 60 lines (10 runs * 3 metrics * [avg | minMax] agg ) before evaluating the expression
     * the expressions will take the place of the metrics count, so the num of lines will be [numRuns * numExpressions * 2]
     */
    // first group all the lines by the underlying run name
    const linesGroupedByRun = lines.reduce((acc, line) => {
      if (!line.name) {
        console.warn('no line name, unexpected results');
      }
      if (!acc[line.name!]) {
        acc[line.name!] = [];
      }
      acc[line.name!].push(line);
      return acc;
    }, {} as Record<string, Line[]>);

    Object.keys(linesGroupedByRun).forEach(rName => {
      expressions.forEach(expr => {
        // get the metrics in the expression
        const metrics = getIdentifiers(expr);
        const matchedLines = linesGroupedByRun[rName].filter(l =>
          metrics.includes(l.metricName!)
        );

        const lineType = determineLineType(metrics);

        switch (lineType) {
          case 'single-metric': {
            matchedLines.forEach(l => {
              newLines.push(
                processSingleMetricLine([l], expr, supplementalMetrics)
              );
            });
            break;
          }
          case 'multi-metric': {
            const lineTypeLines = matchedLines.filter(
              l => l.meta.type === 'line'
            );
            const areaTypeLines = matchedLines.filter(
              l => l.meta.type === 'area'
            );
            if (lineTypeLines.length > 0) {
              newLines.push(
                processMultiMetricLine(lineTypeLines, expr, supplementalMetrics)
              );
            }
            if (areaTypeLines.length > 0) {
              newLines.push(
                processMultiMetricArea(areaTypeLines, expr, supplementalMetrics)
              );
            }
            break;
          }
          case 'no-metrics': {
            console.warn('Cannot process a line without a metric');
            break;
          }
          default: {
            // This case should never occur since we've handled all possible lineType values,
            // but we keep it to ensure exhaustive type checking
            const _: never = lineType;
          }
        }
      });
    });

    return newLines.filter(l => !!l);
  } catch (err) {
    console.error('Error evaluating y-expressions', {
      expressions: JSON.stringify(expressions),
      supplementalMetrics: JSON.stringify(supplementalMetrics),
      metrics: JSON.stringify(lines.map(l => l.metricName).join(', ')),
    });
    return lines;
  }
}

function determineLineType(metrics: string[]) {
  if (metrics.length === 1) {
    return 'single-metric';
  } else if (metrics.length > 1) {
    return 'multi-metric';
  }
  return 'no-metrics';
}

export function getBucketedLines(lines: Line[]) {
  const bucketSpec = getBucketSpec(lines);
  if (!bucketSpec) {
    return lines;
  }
  return lines.map(l => bucketLine(l, bucketSpec));
}

function prepLine(lines: Line[], expr: Expression) {
  const exprString = expressionToString(expr);

  const newLine = Object.assign({}, lines[0], {
    metricName: exprString,
    meta: lines[0].meta,
    name: lines[0].name,
    run: lines[0].run,
    title: `${lines[0].name}: ${exprString}`,
  });
  return newLine;
}

export function shouldUseBuckets(lines: Line[]) {
  return linesAreNotEqualLength(lines) || linesDoNotShareXValues(lines);
}

export function processMultiMetricArea(
  lines: Line[],
  expr: Expression,
  supplementalMetrics: Record<string, Record<string, number>>
) {
  const newLine = prepLine(lines, expr);
  const config = supplementalMetrics[newLine.name ?? ''] ?? {};
  const needsBucketing = shouldUseBuckets(lines);

  const transformedLines = needsBucketing ? getBucketedLines(lines) : lines;
  const [yValuesByX, y0ValuesByX] = aggregateMetricsByX(transformedLines);

  const mappableValues = Array.from(yValuesByX.keys()).filter(k => {
    return (
      !Object.values(yValuesByX.get(k)!).some(v => !_.isFinite(v)) &&
      !Object.values(y0ValuesByX.get(k)!).some(v => !_.isFinite(v))
    );
  });
  newLine.data = mappableValues.map(k => ({
    x: k,
    y: evaluateExpression(expr, Object.assign({}, config, yValuesByX.get(k)!)),
    // @ts-ignore we filtered above
    y0: evaluateExpression(expr, Object.assign({}, config, yValuesByX.get(k)!)),
  }));

  return newLine;
}
export function processMultiMetricLine(
  lines: Line[],
  expr: Expression,
  supplementalMetrics: Record<string, Record<string, number>>
) {
  const newLine = prepLine(lines, expr);
  const config = supplementalMetrics[newLine.name ?? ''] ?? {};
  const useBuckets = linesAreNotEqualLength(lines)
    ? linesDoNotShareXValues(lines)
    : false;

  const transformedLines = useBuckets ? getBucketedLines(lines) : lines;
  const [yValuesByX] = aggregateMetricsByX(transformedLines);

  const mappableValues = Array.from(yValuesByX.keys()).filter(k => {
    return !Object.values(yValuesByX.get(k)!).some(v => !_.isFinite(v));
  });
  newLine.data = mappableValues.map(k => ({
    x: k,
    y: evaluateExpression(expr, Object.assign({}, config, yValuesByX.get(k)!)),
  }));

  return newLine;
}

export function aggregateMetricsByX(lines: Line[]) {
  const yValuesByX = new Map<number, Record<string, number>>();
  const y0ValuesByX = new Map<number, Record<string, number | undefined>>();

  lines.forEach(line => {
    line.data.forEach(point => {
      if (!yValuesByX.has(point.x)) {
        yValuesByX.set(point.x, {});
      }
      yValuesByX.get(point.x)![line.metricName!] = point.y;

      if (line.meta.type === 'area') {
        if (!y0ValuesByX.has(point.x)) {
          y0ValuesByX.set(point.x, {});
        }
        y0ValuesByX.get(point.x)![line.metricName!] = point.y0;
      }
    });
  });

  return [yValuesByX, y0ValuesByX] as const;
}

export function linesAreNotEqualLength<T>(lines: Array<{data: T[]}>) {
  return lines.some(l => l.data.length !== lines[0].data.length);
}

export function linesDoNotShareXValues<T extends {x: number}>(
  lines: Array<{data: T[]}>
) {
  const [baseLine, ...otherLines] = lines;
  const baseSet = new Set(baseLine.data.map(p => p.x));
  return otherLines.some(l => {
    return l.data.some(p => !baseSet.has(p.x));
  });
}

export function processSingleMetricLine(
  lines: Line[],
  expr: Expression,
  supplementalMetrics: Record<string, Record<string, number>>
) {
  const matchedLine = Object.assign({}, lines[0]);

  if (!matchedLine) {
    console.error('No line found for expression', expr);
    return matchedLine;
  }
  const config = supplementalMetrics[matchedLine.name ?? ''] ?? {};

  /**
   * This gets slower because we have to return new object literals for each point while mapping - is there a way to do this with mutation that doesn't end up sharing references between the lines?
   */
  if (matchedLine.meta.type === 'line') {
    matchedLine.data = matchedLine.data.map(p => ({
      ...p,
      y: evaluateExpression(
        expr,
        Object.assign({}, config, {
          [matchedLine.metricName!]: p.y,
        })
      ),
    }));
  } else {
    matchedLine.data = matchedLine.data.map(p => ({
      ...p,
      y: evaluateExpression(
        expr,
        Object.assign({}, config, {
          [matchedLine.metricName!]: p.y,
        })
      ),
      y0: evaluateExpression(
        expr,
        Object.assign({}, config, {
          [matchedLine.metricName!]: p.y0!,
        })
      ),
    }));
  }

  return matchedLine;
}
