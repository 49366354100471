import {assertUnreachable, ValueOf} from '@wandb/weave/common/util/types';

import {logHandledError} from '../../services/errors/errorReporting';
import {
  FRIENDLY_ENTRY_TYPE_NAMES,
  MIN_NETWORK_ELAPSED_TIME_MILLIS,
  MINIMUM_LONGTASK_DURATION_MS,
  MINIMUM_NETWORK_DURATION_MS,
  NETWORK_EVENT_CONTEXT,
  TTI_DOMAIN_EXCLUSIONS,
  TTI_DOMAIN_INCLUSIONS,
  TTI_WANDB_ANALYTICS_DOMAIN,
} from './constants';
import {logToDataDog, reportTimedEvent, updatePageLongTask} from './report';
import {TimeChunk, updateTimeToInteractive} from './timeToInteractive';

/*
This file is responsible for reading data from the browser's built in PerformanceObserver and translating it into
events for our profiler.
*/

let lastSlowResponseTime = 0;

if (window.PerformanceObserver) {
  const observer = new PerformanceObserver(list => {
    list.getEntries().forEach(entry => {
      /* eslint-disable no-case-declarations */
      // FIXME: There are a few variables assigned in `case` statements that
      //        should be reworked. Ignore the issue here rather than fix and
      //        potentially introduce new bugs.
      switch (entry.entryType) {
        case 'resource':
          const url = entry.name;
          const domain = new URL(url).hostname.toLowerCase();
          const domainIsExcluded = TTI_DOMAIN_EXCLUSIONS.some(excludedDomain =>
            domain.includes(excludedDomain)
          );
          const domainIsIncluded = TTI_DOMAIN_INCLUSIONS.some(
            includedDomain =>
              TTI_WANDB_ANALYTICS_DOMAIN !== includedDomain &&
              domain.includes(includedDomain)
          );
          // We've found that trying to get all domains in the exclude list is too tricky,
          // so for now going with an include list and logging the unknowns.
          // We may switch back to exclude list in the future when we have a stable TTI and
          // are confident that we know what domains to exclude/include.
          if (domainIsIncluded) {
            considerForUpdateTTI(getTimeChunk(entry), TimeChunkTypes.NETWORK);
          }
          if (entry.duration < MIN_NETWORK_ELAPSED_TIME_MILLIS) {
            return;
          }
          // GraphQL queries are logged using reportQueryPerfEvent
          if (url.endsWith('/graphql') || url.endsWith('/graphql2')) {
            return;
          }
          reportPerfObserverEvent(entry, {
            ...NETWORK_EVENT_CONTEXT,
            url,
          });
          if (!domainIsExcluded && !domainIsIncluded) {
            // Flag unknown domains, so we can know to add them to TTI_DOMAIN_INCLUSIONS or TTI_DOMAIN_EXCLUSIONS
            logToDataDog(
              'PerformanceObserver resource had unknown domain: ' + domain,
              {unknownDomain: domain}
            );
          }
          break;
        case 'longtask':
          considerForUpdateTTI(getTimeChunk(entry), TimeChunkTypes.LONGTASK);
          updatePageLongTask(entry.duration);
          reportPerfObserverEvent(entry);
          if (entry.duration > 60000) {
            logHandledError(
              'PerformanceObserver longtask took longer than 60s',
              {extra: {entry}}
            );
          }

          break;
        case 'event':
          // we get a lot of repeated data for this entry type,
          // they come in order so just ignore the last one.
          if (lastSlowResponseTime !== entry.startTime) {
            lastSlowResponseTime = entry.startTime;
            reportPerfObserverEvent(entry);
          }
          break;

        case 'first-input':
          reportPerfObserverEvent(entry);
          break;
      }
    });
  });
  observer.observe({
    entryTypes: ['resource', 'event', 'first-input', 'longtask'],
  });
}

const reportPerfObserverEvent = (
  entry: PerformanceEntry,
  context: Record<string, any> = {}
) => {
  const perfObserverEventName =
    entry.entryType in FRIENDLY_ENTRY_TYPE_NAMES
      ? FRIENDLY_ENTRY_TYPE_NAMES[
          entry.entryType as keyof typeof FRIENDLY_ENTRY_TYPE_NAMES
        ]
      : entry.entryType;

  reportTimedEvent({
    // duration is in millis. We use floor because more precision isn't useful
    elapsedMs: Math.floor(entry.duration),
    absoluteStartTimeMs: Math.floor(entry.startTime), // note that this will not use the same value for 0 as perfStartTimeMs
    name: 'PerfObserver: ' + perfObserverEventName,
    context,
  });
};

export const getTimeChunk = (entry: PerformanceEntry): TimeChunk => ({
  // we round these since for TTI calculations we don't need sub-millisecond
  start: Math.round(entry.startTime),
  end: Math.round(entry.startTime + entry.duration),
});

export const TimeChunkTypes = {
  NETWORK: 'network',
  LONGTASK: 'longtask',
} as const;
export type TimeChunkType = ValueOf<typeof TimeChunkTypes>;

/*
In watching what's happening during real page loads, we've found that due to the UI
of workspaces and reports, there tend to be many small network requests and longtasks
occurring *after* the page appears loaded to the user. This makes TTI inaccurate for
measuring page load as perceived by users. 

So we are ignoring events that are very quick. Ultimately, we are trying to track whether the app feels
responsive, so this seems like a reasonable tradeoff. This is an extension of what is happening
with longtasks, where the browser won't report to us anything less than 50ms. 

It has the downside that during page load we sometimes have a cascade of requests happening, 
and a series of quick events would be missed. For example, if a page requires 20 400ms 
requests made in series, this logic will miss that and the TTI reported will be lower than
it should be.

Fundamentally, if the UI relies on a series of network requests, those round trips
are very inefficient - the backend should be returning all the data needed for a particular view
so no roundtripping is needed. This is aspirational since that's not the current case. So longer
term, this logic for TTI should work well.

For now, we are just trying to find users encountering truly bad load times, and using higher
bars for reporting a longtask or network request should filter out a bunch of noise.
*/
export const considerForUpdateTTI = (tc: TimeChunk, type: TimeChunkType) => {
  const duration = tc.end - tc.start;
  switch (type) {
    case TimeChunkTypes.LONGTASK:
      if (duration >= MINIMUM_LONGTASK_DURATION_MS) {
        updateTimeToInteractive(tc);
      }
      break;

    case TimeChunkTypes.NETWORK:
      if (duration >= MINIMUM_NETWORK_DURATION_MS) {
        updateTimeToInteractive(tc);
      }
      break;
    default:
      assertUnreachable(type);
  }
};
