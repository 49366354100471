import type {LogsGlobal} from '@datadog/browser-logs';
import {RumGlobal} from '@datadog/browser-rum';
import {createContext} from 'react';

import {getDatadogLogs, getDatadogRum} from './wrapper';

export type DatadogContextValue = {
  rum: RumGlobal;
  logs: LogsGlobal;
};

export const DatadogContext = createContext<DatadogContextValue>({
  rum: getDatadogRum(),
  logs: getDatadogLogs(),
});
