import * as PanelTypes from '../../state/views/panel/types';
import * as PanelBankSectionConfigTypes from '../../state/views/panelBankSectionConfig/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {PanelLoadingState} from '../PanelBank/PanelBankTiming';
import {OrganizedSettings} from '../PanelBank/types';

export type PanelContainerProps = {
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  disableRunLinks?: boolean;
  historyKeyInfo?: RunHistoryKeyInfo;
  isReadOnly?: boolean;
  panelBankSectionConfigRef: PanelBankSectionConfigTypes.Ref;
  panelRef: PanelTypes.Ref;
  inheritedSettings?: OrganizedSettings;
  shouldRenderContent?: boolean;
  shouldTurnOnComments?: boolean;
  loadingState?: PanelLoadingState;
  onContentHeightChange?(h: number): void;
  mode?: string;
};

export enum PanelPopupMode {
  EDIT,
  FULL_SCREEN,
  VIEW_SPEC,
}

export interface PanelProps {
  currentHeight: number;
  defaultMoveToSectionRef?: PanelBankSectionConfigTypes.Ref;
  disableRunLinks?: boolean;
  shouldRenderContent?: boolean;
  shouldTurnOnComments: boolean;
  onContentHeightChange?(h: number): void;
  mode?: string;
}
