// Manages selected state on the client-side, including partial selection state for groups.
// The future of selection managers
import update from 'immutability-helper';
import * as _ from 'lodash';

import {MultiStateCheckboxCheckedState} from '../components/MultiStateCheckbox';
import * as QueryTypes from '../util/queryTypes';
import {NULL_STRING, NULL_STRING_ANGLE_BRACKETS} from './constants';
import * as Filter from './filters';
import * as FilterTypes from './filterTypes';
import * as Run from './runs';
import * as RunTypes from './runTypes';
import * as SMOld from './selectionmanager_old';

export type BoundOp = '<=' | '>=' | 'IN';

export interface SelectionBound {
  readonly key: RunTypes.Key;
  readonly op: BoundOp;
  readonly value: RunTypes.BasicValue | RunTypes.BasicValue[];
}

// This gets converted into filters in SelectionManager.setSelectionsToBounds
export interface PanelSelectionConstraint {
  low?: number; // lower bound for a numeric dimension constraint
  high?: number; // upper bound for a numeric dimension constraint
  match?: RunTypes.BasicValue[]; // match array for a string dimension constraint
}

export interface PanelSelections {
  [key: string]: PanelSelectionConstraint;
}

export type SelectionPath = RunTypes.Value[];

type SelectionNodeSimple = RunTypes.Value;
interface SelectionNodeNested {
  value: RunTypes.Value;
  // skip indicates that a user didn't explicitly click on this selection,
  // the node is here because they clicked on a selection that's some child
  // of this node.
  skip?: boolean;
  children: SelectionTree;
}
type SelectionNode = SelectionNodeSimple | SelectionNodeNested;
export type SelectionTree = ReadonlyArray<SelectionNode>;

export enum Root {
  None,
  All,
  Some,
}

export interface SelectionState {
  root: Root;
  bounds: SelectionBound[];
  tree: SelectionTree;
}

export interface GroupSelectionState {
  grouping: QueryTypes.Grouping;
  selections: SelectionState;
  expandedRowAddresses: string[];
}

export const EMPTY_NONE_SELECTIONS: SelectionState = {
  root: Root.None,
  bounds: [],
  tree: [],
};

export const EMPTY_NONE: GroupSelectionState = {
  grouping: [],
  selections: EMPTY_NONE_SELECTIONS,
  expandedRowAddresses: [],
};

export const EMPTY_ALL_SELECTIONS: SelectionState = {
  root: Root.All,
  bounds: [],
  tree: [],
};

export const EMPTY_ALL: GroupSelectionState = {
  grouping: [],
  selections: EMPTY_ALL_SELECTIONS,
  expandedRowAddresses: [],
};

export const RUN_NAME_KEY = Run.key('run', 'name');

export function selectAll(state: GroupSelectionState): GroupSelectionState {
  return {...state, selections: EMPTY_ALL.selections};
}

export function selectAllMutate(state: GroupSelectionState): void {
  state.selections = EMPTY_ALL.selections;
}

export function selectNone(state: GroupSelectionState): GroupSelectionState {
  return {...state, selections: EMPTY_NONE.selections};
}

export function selectSome(
  state: GroupSelectionState,
  runs: RunTypes.Run[]
): GroupSelectionState {
  let outState = selectNone(state);
  runs.forEach(r => (outState = toggleSelection(outState, r, 1)));
  return outState;
}

function treePruneToDepth(
  tree: SelectionTree,
  remainingLevels: number
): SelectionTree {
  if (remainingLevels === 0) {
    return [];
  }
  return tree
    .map(n => {
      if (isNestedNode(n)) {
        const prunedChildren = treePruneToDepth(
          n.children,
          remainingLevels - 1
        );
        if (prunedChildren.length === 0) {
          if (n.skip) {
            return [];
          } else {
            return n.value;
          }
        } else {
          return {
            value: n.value,
            skip: n.skip,
            children: prunedChildren,
          };
        }
      } else {
        return n;
      }
    })
    .filter(n => !_.isArray(n) || n.length > 0);
}

export function setGrouping(
  state: GroupSelectionState,
  grouping: QueryTypes.Grouping
): GroupSelectionState {
  // Determine number of initial keys that new grouping shares with old
  let shareCount = 0;
  while (
    shareCount < grouping.length &&
    shareCount < state.grouping.length &&
    _.isEqual(grouping[shareCount], state.grouping[shareCount])
  ) {
    shareCount++;
  }
  // Remove paths longer than shareCount
  return {
    ...state,
    grouping,
    selections: {
      ...state.selections,
      tree: treePruneToDepth(state.selections.tree, shareCount),
    },
  };
}

function isNestedNode(node: SelectionNode): node is SelectionNodeNested {
  return node != null && !!(node as SelectionNodeNested).children;
}

function nodeValue(node: SelectionNode) {
  if (isNestedNode(node)) {
    return node.value;
  } else {
    return node;
  }
}

function toggleInTree(tree: SelectionTree, path: SelectionPath): SelectionTree {
  const value = path[0];
  const foundIndex = _.findIndex(tree, n => nodeValue(n) === value);
  if (path.length === 0) {
    throw new Error('Selection manager programming error');
  } else if (path.length === 1) {
    // base case, stick value here
    if (foundIndex === -1) {
      // add
      return update(tree, {$push: [value]});
    } else {
      // remove
      return update(tree, {$splice: [[foundIndex, 1]]});
    }
  } else {
    // recurse
    const rest = path.slice(1);
    if (foundIndex === -1) {
      // Don't have this value, so create new node
      return update(tree, {
        $push: [{value, skip: true, children: toggleInTree([], rest)}],
      });
    } else {
      // We have the value
      const node = tree[foundIndex];
      if (isNestedNode(node)) {
        // Already nested, so add to children
        const newChildren = toggleInTree(node.children, rest);
        if (newChildren.length > 0) {
          return update(tree, {[foundIndex]: {children: {$set: newChildren}}});
        } else {
          // We no longer have children, convert back to SimpleNode
          if (node.skip) {
            // This was a skip node (not explicity interacted with by the user),
            // so we remove it.
            return update(tree, {$splice: [[foundIndex, 1]]});
          } else {
            return update(tree, {[foundIndex]: {$set: node.value}});
          }
        }
      } else {
        // Convert to nested and add to children
        return update(tree, {
          [foundIndex]: {$set: {value, children: toggleInTree([], rest)}},
        });
      }
    }
  }
}

export function togglePath(
  state: GroupSelectionState,
  path: SelectionPath
): GroupSelectionState {
  if (path.length > state.grouping.length + 1) {
    throw new Error('Invalid selection state');
  }
  return {
    ...state,
    selections: {
      ...state.selections,
      tree: toggleInTree(state.selections.tree, path),
    },
  };
}

export function toggleExpandedRowAddress(
  state: GroupSelectionState,
  rowAddress: string
): GroupSelectionState {
  const {expandedRowAddresses} = state;
  return {
    ...state,
    expandedRowAddresses: _.includes(expandedRowAddresses, rowAddress)
      ? // remove rowAddress from expandedRowAddresses
        expandedRowAddresses.filter(a => a !== rowAddress)
      : // add rowAddress to expandedRowAddresses
        expandedRowAddresses.concat([rowAddress]),
  };
}

export function toggleSelection(
  state: GroupSelectionState,
  run: RunTypes.Run,
  depth: number
): GroupSelectionState {
  const keyPath = [...state.grouping, RUN_NAME_KEY].slice(0, depth);
  const path = keyPath.map(k => Run.getValue(run, k));
  return togglePath(state, path);
}

export function removeBound(
  state: GroupSelectionState,
  bound: SelectionBound
): GroupSelectionState {
  // TODO: Bug here. Need to remove bound where
  return {
    ...state,
    selections: {
      ...state.selections,
      bounds: state.selections.bounds.filter(
        b => !(_.isEqual(b.key, bound.key) && b.op === bound.op)
      ),
    },
  };
}

export function addBound(
  state: GroupSelectionState,
  bound: SelectionBound
): GroupSelectionState {
  state = removeBound(state, bound);
  if (bound.value == null) {
    return state;
  }
  return {
    ...state,
    selections: {
      ...state.selections,
      bounds: [...state.selections.bounds, bound],
    },
  };
}

function treeToFilters(
  grouping: QueryTypes.Grouping,
  tree: SelectionTree,
  additive: boolean
): Array<FilterTypes.Filter<RunTypes.Key>> {
  const key = grouping[0];
  if (key == null) {
    throw new Error('SelectionManager programming error');
  }
  const restKeys = grouping.slice(1);

  return _.flatMap(
    tree,
    n =>
      (isNestedNode(n)
        ? {
            op: additive ? 'AND' : 'OR',
            filters: [
              {
                key,
                op: additive ? '=' : '!=',
                value: n.value,
              },
              {
                op: (n.skip ? additive : !additive) ? 'OR' : 'AND',
                filters: treeToFilters(
                  restKeys,
                  n.children,
                  n.skip ? additive : !additive
                ),
              },
            ],
          }
        : {
            key,
            op: additive ? '=' : '!=',
            value: n,
          }) as FilterTypes.Filter<RunTypes.Key>
  );
}

// Converts "null", "<null>", "true", "false" to null or boolean
const stringValueTransform = (val: RunTypes.BasicValue) => {
  return val === NULL_STRING || val === NULL_STRING_ANGLE_BRACKETS
    ? null
    : val === 'true'
    ? true
    : val === 'false'
    ? false
    : val;
};

// Converts "null", "<null>", "true", "false" to null or boolean
const selectionBoundTransform = (
  selectionBound: SelectionBound
): SelectionBound => {
  if (selectionBound.op === 'IN') {
    return {
      ...selectionBound,
      value: (selectionBound.value as RunTypes.BasicValue[]).map(
        stringValueTransform
      ),
    };
  }
  return selectionBound;
};

export function toRunFilter(
  state: GroupSelectionState
): FilterTypes.Filter<RunTypes.Key> {
  const additive = state.selections.root === Root.None;
  // Bounds from panel selection (like in parallel coordinates or scatter plot)
  const boundsFilter = getBoundsFilter(state);
  const boundsFilters = boundsFilter != null ? [boundsFilter] : [];
  const subFilters = treeToFilters(
    [...state.grouping, RUN_NAME_KEY],
    state.selections.tree,
    additive
  );
  if (additive) {
    return Filter.Or([Filter.FALSE_FILTER, ...boundsFilters, ...subFilters]);
  } else {
    return Filter.And([Filter.TRUE_FILTER, ...boundsFilters, ...subFilters]);
  }
}

export function getBoundsFilter(
  state: GroupSelectionState
): FilterTypes.Filter<RunTypes.Key> | null {
  if (state.selections.bounds.length === 0) {
    return null;
  }
  return Filter.And(
    state.selections.bounds.map(selectionBoundTransform) as Array<
      FilterTypes.MultiValueFilter<RunTypes.Key>
    >
  );
}

function groupCheckedState(
  root: Root,
  tree: SelectionTree,
  path: SelectionPath
): MultiStateCheckboxCheckedState {
  let result = root === Root.All ? 'checked' : 'unchecked';
  for (const value of path) {
    const foundIndex = _.findIndex(tree, n => nodeValue(n) === value);
    if (foundIndex === -1) {
      return result as MultiStateCheckboxCheckedState;
    }
    const node = tree[foundIndex];
    if (isNestedNode(node)) {
      tree = node.children;
      if (!node.skip) {
        result = result === 'checked' ? 'unchecked' : 'checked';
      }
    } else {
      return result === 'checked' ? 'unchecked' : 'checked';
    }
  }
  return 'partial';
}

export function getCheckedState(
  state: GroupSelectionState,
  run: RunTypes.Run,
  level: number
): MultiStateCheckboxCheckedState {
  if (level === state.grouping.length + 1) {
    // Leaf node (run) state
    const filters = toRunFilter(state);
    return Filter.match(filters, run) ? 'checked' : 'unchecked';
  } else if (state.selections.bounds.length > 0) {
    return 'partial';
  } else if (level === 0) {
    if (state.selections.tree.length > 0) {
      return 'partial';
    }
    return state.selections.root === Root.All ? 'checked' : 'unchecked';
  } else {
    const keyPath = state.grouping.slice(0, level);
    const path = keyPath.map(k => Run.getValue(run, k));
    return groupCheckedState(
      state.selections.root,
      state.selections.tree,
      path
    );
  }
}

export function runIsInBounds(
  state: GroupSelectionState,
  run: RunTypes.Run,
  level: number
): boolean | null {
  if (level !== state.grouping.length + 1) {
    // TODO: We currently abort for all non-leaf nodes,
    // which means this check only supports runs and not groups.
    return null;
  }
  const boundsFilter = getBoundsFilter(state);
  if (boundsFilter == null) {
    return null;
  }
  return Filter.match(boundsFilter, run);
}

export function isNoneSelected(state: GroupSelectionState) {
  return (
    state.selections.root === Root.None && state.selections.tree.length === 0
  );
}

export function isSelectingIndividualRuns(
  state?: GroupSelectionState
): boolean {
  if (state == null) {
    return false;
  }

  // No grouping, individual runs selected
  if (
    state.selections.root === Root.None &&
    state.grouping.length === 0 &&
    state.selections.tree.length > 0
  ) {
    return true;
  }

  // Groupings, no groups selected, only individual runs inside leaf groups
  if (state.selections.root === Root.None && state.grouping.length > 0) {
    const nGroups = state.grouping.length;

    // Recursively check the selection tree
    const checkTree = (tree: SelectionNode, depth: number): boolean => {
      if (depth < nGroups) {
        // For non-leaf nodes we expect nested node.  If not we have a group selection
        return (
          isNestedNode(tree) &&
          tree.children.every(t => checkTree(t, depth + 1))
        );
      }
      // For any leaf nodes we expect the group to be skipped and non-empty list of run IDs
      return (
        isNestedNode(tree) && tree.skip === true && tree.children.length > 0
      );
    };
    return state.selections.tree.every(t => checkTree(t, 1));
  }

  return false;
}

export function getConstraintsForKey(
  state: GroupSelectionState,
  key: RunTypes.Key
): PanelSelectionConstraint {
  // Range constraints (for numeric dimensions)
  const lowerBound = _.find(
    state.selections.bounds,
    b => _.isEqual(b.key, key) && b.op === '>='
  );
  const upperBound = _.find(
    state.selections.bounds,
    b => _.isEqual(b.key, key) && b.op === '<='
  );

  let low: number | undefined;
  let high: number | undefined;

  if (lowerBound) {
    if (typeof lowerBound.value === 'number') {
      // normal case
      low = lowerBound.value;
    } else if (typeof lowerBound.value === 'string') {
      // assume string is a date in utc - convert to milisecond timestamp
      low = new Date(lowerBound.value).getTime();
    }
  }
  if (upperBound) {
    if (typeof upperBound.value === 'number') {
      // normal case
      high = upperBound.value;
    } else if (typeof upperBound.value === 'string') {
      // assume string is a date in utc - convert to milisecond timestamp
      high = new Date(upperBound.value).getTime();
    }
  }

  // Match constraints (for string and boolean dimensions)
  const matchConstraint = state.selections.bounds.find(
    b => _.isEqual(b.key, key) && b.op === 'IN'
  ) as FilterTypes.MultiValueFilter<RunTypes.Key>;
  const match =
    matchConstraint == null || matchConstraint.value.length === 0
      ? undefined
      : (matchConstraint.value as RunTypes.BasicValue[]);
  return {
    low,
    high,
    match,
  };
}

export function setSelectionsToBounds(
  panelSelections: PanelSelections,
  groupSelection: GroupSelectionState
): GroupSelectionState {
  let selection = groupSelection;
  Object.keys(panelSelections).forEach(keyString => {
    const key = Run.keyFromString(keyString);
    if (key) {
      const keySelections = panelSelections[keyString];
      // Use >= and <= filter operators for numerical ranges
      ['low', 'high'].forEach(bound => {
        const boundValue = keySelections[bound as 'low' | 'high'];
        if (boundValue != null) {
          selection = addBound(selection, {
            key,
            op: bound === 'low' ? '>=' : '<=',
            value: Run.isTimeKeyString(keyString)
              ? new Date(boundValue).toISOString()
              : boundValue,
          });
        }
      });
      if (keySelections.match != null) {
        // Use IN filter operator for string dimension matches
        selection = addBound(selection, {
          key,
          op: 'IN',
          value: keySelections.match.map(stringValueTransform),
        });
      }
    }
  });
  return selection;
}

export function clearSelections(
  keyStrings: string[],
  groupSelection: GroupSelectionState
): GroupSelectionState {
  let selection = groupSelection;
  // Loop through keys and remove bounds for each key
  keyStrings.forEach(keyString => {
    const key = Run.keyFromString(keyString || '');
    if (key != null) {
      selection = {
        ...selection,
        selections: {
          ...selection.selections,
          // remove bounds for this key
          bounds: selection.selections.bounds.filter(
            b => !_.isEqual(key, b.key)
          ),
        },
      };
    }
  });
  return selection;
}

export function fromJSON(json: any): GroupSelectionState {
  let result = EMPTY_NONE;
  if (json == null || json.selections == null || json.grouping == null) {
    return result;
  }
  if (json.selections.addGroups) {
    result = fromOldJSON(json);
  } else {
    result = json;
  }
  return {
    selections: result.selections,
    grouping: result.grouping.map(Run.fixConfigKey),
    expandedRowAddresses: result.expandedRowAddresses ?? [],
  };
}

function fromOldJSON(json: {
  grouping: QueryTypes.Grouping;
  selections: SMOld.SelectionManagerJSON;
}): GroupSelectionState {
  const selections = json.selections;
  if (selections.root === SMOld.Root.None) {
    let state = {...EMPTY_NONE, grouping: json.grouping};
    for (const addGroup of selections.addGroups) {
      state = togglePath(state, [addGroup.groupValue]);
    }
    for (const subtractSubgroup of selections.subtractSubgroups) {
      state = togglePath(state, [
        subtractSubgroup.groupValue,
        subtractSubgroup.subgroupValue,
      ]);
    }
    for (const addRun of selections.addRuns.concat(selections.subtractRuns)) {
      if (addRun.memberOf != null && addRun.memberOf.groupValue) {
        const memberOf = addRun.memberOf;
        if (SMOld.isSubgroup(memberOf)) {
          state = togglePath(state, [
            memberOf.groupValue,
            memberOf.subgroupValue,
            addRun.id,
          ]);
        } else {
          state = togglePath(state, [memberOf.groupValue, addRun.id]);
        }
      } else {
        state = togglePath(state, [addRun.id]);
      }
    }
    return state;
  } else if (selections.root === SMOld.Root.All) {
    let state = {...EMPTY_ALL, grouping: json.grouping};
    for (const subtractGroup of selections.subtractGroups) {
      state = togglePath(state, [subtractGroup.groupValue]);
    }
    for (const addSubgroup of selections.addSubgroups) {
      state = togglePath(state, [
        addSubgroup.groupValue,
        addSubgroup.subgroupValue,
      ]);
    }
    for (const subtractRun of selections.subtractRuns.concat(
      selections.addRuns
    )) {
      if (subtractRun.memberOf != null && subtractRun.memberOf.groupValue) {
        const memberOf = subtractRun.memberOf;
        if (SMOld.isSubgroup(memberOf)) {
          state = togglePath(state, [
            memberOf.groupValue,
            memberOf.subgroupValue,
            subtractRun.id,
          ]);
        } else {
          state = togglePath(state, [memberOf.groupValue, subtractRun.id]);
        }
      } else {
        state = togglePath(state, [subtractRun.id]);
      }
    }
    return state;
  }
  return EMPTY_NONE;
}
