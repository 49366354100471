import {ApolloLink} from 'apollo-link';

import {reportQueryPerfEvent} from './profiler';

export const getPerfTimingLink = (apolloContextName: string = '') =>
  new ApolloLink((operation, forward) => {
    // Called before operation is sent to server
    operation.setContext({wandbStartMs: Date.now()});
    return forward(operation).map(data => {
      // Called after server responds
      // perf logging should never cause crash, so wrap it in a try catch
      try {
        const startMs = operation.getContext().wandbStartMs;
        const elapsedMs = Date.now() - startMs;
        reportQueryPerfEvent({
          elapsedMs,
          startMs,
          // note that for custom chart queries, operationName will be null
          operationName: apolloContextName + operation.operationName,
          variables: operation.variables,
        });
      } catch (e) {
        console.error(`Error while trying to do apollo perf logging`, e);
      }
      return data;
    });
  });
