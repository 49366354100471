import {
  PanelBankSectionConfig,
  PanelBankSectionConfigWithVisiblePanels,
} from '../../components/PanelBank/types';
import {getCascadingPanelSettings} from '../../components/WorkspaceDrawer/Settings/hooks/useCascadingSettings';
import {
  LinePlotSettings,
  POINT_VISUALIZATION_OPTIONS,
} from '../../components/WorkspaceDrawer/Settings/types';
import {Config, LayedOutPanel} from '../panelTypes';
import {panelBankSectionFromJSON, upgradeToPanelBank} from '../parseSpecUtils';
import {ReportSpecVersion} from './shared';

// This is run in the loadFinished reducer, for reports.
// (A report section's panels = a single PanelBankSection)
// It returns a single PanelBankSection
// Put all migrations to the PanelBankSectionConfig object here.
export const migrateReportToPanelBank = (
  viewType: 'runs' | 'runs/draft',
  existingPanelBankSectionConfig: PanelBankSectionConfig,
  legacyConfig: Config | null,
  specVersion: ReportSpecVersion
): PanelBankSectionConfig => {
  const panelBankSectionConfig =
    specVersion === ReportSpecVersion.V0 && legacyConfig != null
      ? (upgradeToPanelBank(legacyConfig, true) as PanelBankSectionConfig)
      : existingPanelBankSectionConfig; // no-op

  return panelBankSectionFromJSON(panelBankSectionConfig, viewType);
};

/**
 * When we create a report from the workspace pages, we want to apply
 * cascading settings hierarchy to the line plot panels.
 * panel settings >> section settings >> workspace settings
 */
export function getReportPanel(
  p: LayedOutPanel,
  linePlotSettings: LinePlotSettings | undefined,
  section: PanelBankSectionConfigWithVisiblePanels
) {
  if (p.viewType !== 'Run History Line Plot') {
    return p;
  }

  const cascadingPanelSettings = getCascadingPanelSettings(
    // bucketing is not available in reports
    {
      pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.Sampling,
    },
    linePlotSettings,
    section?.sectionSettings?.linePlot,
    p.config
  );

  return {
    ...p,
    config: {
      ...p.config,
      ...cascadingPanelSettings,
    },
  };
}
