import {useDeepMemo, usePrevious} from '@wandb/weave/hookUtils';
import _ from 'lodash';
import {useEffect, useMemo, useRef} from 'react';

import {
  useFirstNonEmptyValueWhileLoading,
  useStaleValueWhileLoading,
} from '../../util/hooks';
import {Run} from '../../util/runTypes';
import {useSharedPanelState} from '../Panel/SharedPanelStateContext';
import {useIsRenderingInPopup} from '../Panel/SinglePanelInspectorContainer';
import {usePanelsSharedRefsContext} from '../Views/contexts/PanelsSharedRefsContext';
import {useRunsData} from './../../state/runs/hooks';
import {useParts} from './../../state/views/hooks';
import {Query} from './../../util/queryTypes';
import {Range, runsLinePlotTransformQuery} from './common';
import {usePanelConfigContext} from './PanelConfigContext';
import {RunsLinePlotConfig} from './types';

const usePanelQuery = (
  pageQuery: Query,
  config: RunsLinePlotConfig,
  queryStepRange: Range
) => {
  const {limit: numRuns, parsedExpressions} = usePanelConfigContext();
  const transformed = runsLinePlotTransformQuery({
    query: pageQuery,
    runsLinePlotConfig: config,
    xStepRange: queryStepRange,
    parsedExpressions,
    defaultMaxRuns: numRuns,
  });

  return useDeepMemo(transformed);
};

export const usePanelRunsData = (
  config: RunsLinePlotConfig,
  pageQuery: Query,
  queryStepRange: Range
) => {
  const panelQuery = usePanelQuery(pageQuery, config, queryStepRange);
  const {runSetRefs} = usePanelsSharedRefsContext();
  const runsets = useParts(runSetRefs);
  /**
   * In a workspace there will be one runset with one query. Previously this first query was hardcoded and coupled to everything
   * TODO: confirm if this is even used in reports
   */
  const firstId = panelQuery.queries?.[0]?.id ?? '';
  const lastPanelQuery = usePrevious(panelQuery);

  const isRegexMetric = config.useMetricRegex && config.metricRegex;
  const isEmptyMetricRegex = config.useMetricRegex && !config.metricRegex;
  const isEmptyMetricDropdown =
    !config.useMetricRegex && (!config.metrics || config.metrics.length === 0);

  // This block manages the state and data fetching for the usePanelRunsData hook.
  // It handles caching, polling, and shared state synchronization for an expandable line plot
  // visualization that renders initially in the workspace but can be expanded to render in
  // its own popup. The same component is used for both rendering contexts.
  //
  // Caching Strategy:
  // - The workspace plot polls, updating the shared data.
  // - Initially, the plot in the popup reads the shared data.
  // - When the query of the expanded plot changes via zooming, it decouples from the shared data.
  // - The two instances then poll separately.
  //
  // shouldReadSharedData:
  // - Determines if the shared data should be read based on the query and popup state.
  // - Ensures that the shared data is only read during the initial load so that users can interact
  //   with the plot in the popup without affecting the workspace plot.
  //
  // shouldWriteSharedData:
  // - Determines if the shared data should be written based on the popup state and initial query.

  //#region usePanelRunsDataStateManagement
  const isPopup = useIsRenderingInPopup();

  const {queryStateById, setQueryStateById} = useSharedPanelState();
  const isFirstPanelQuery = useRef(true);

  if (isFirstPanelQuery.current) {
    isFirstPanelQuery.current =
      lastPanelQuery == null || lastPanelQuery === panelQuery;
  }

  const {query: sharedQuery = null, result: sharedResult = null} =
    queryStateById[firstId] ?? {};

  const shouldReadSharedData = useMemo(
    () =>
      _.isEqual(sharedQuery, panelQuery) &&
      isFirstPanelQuery.current &&
      isPopup &&
      /**
       * We can't read from the shared cache with a regex metric because there's a race condition where unpacking the regex into a list of metrics isn't fast enough to happen before the panel renders. This ends up caching a loading state which then never resolves.
       */
      !isRegexMetric,
    [sharedQuery, panelQuery, isPopup, isRegexMetric]
  );

  const shouldWriteSharedData = !isPopup && isFirstPanelQuery.current;

  const shouldSkipRunsQuery =
    isEmptyMetricRegex || isEmptyMetricDropdown || shouldReadSharedData;

  let {data} = useRunsData(panelQuery, shouldSkipRunsQuery);

  if (shouldReadSharedData) {
    data = sharedResult;
  }

  useEffect(() => {
    const matchingRunset = runsets.find(rs => rs.id === firstId);
    if (shouldWriteSharedData && matchingRunset) {
      setQueryStateById(matchingRunset.id, {query: panelQuery, result: data});
    }
  }, [
    data,
    firstId,
    panelQuery,
    runsets,
    shouldWriteSharedData,
    setQueryStateById,
  ]);
  //#endregion

  let runsByDisplayName = useMemo(
    () =>
      data.filtered.reduce((acc, run) => {
        acc[run.displayName] = run;
        return acc;
      }, {} as Record<string, Run>),
    [data.filtered]
  );

  const loading = data.loading;

  /* If we are loading, we want to keep the last loaded data around so that we can show a small spinner over the previous data,
  instead of showing a huge spinner that causes the plot to disappear. (See logic in layout.tsx).*/
  runsByDisplayName = useStaleValueWhileLoading(runsByDisplayName, loading);
  data = useStaleValueWhileLoading(data, loading);

  const originalRunsByDisplayName = useFirstNonEmptyValueWhileLoading(
    runsByDisplayName,
    loading
  );

  const originalData = useFirstNonEmptyValueWhileLoading(
    data,
    loading,
    d => d.filtered.length === 0
  );

  // if we are zooming out all the way, we want to quickly flip back to the original zoomed out data
  if (queryStepRange.min == null && queryStepRange.max == null && loading) {
    runsByDisplayName = originalRunsByDisplayName;
    data = originalData;
  }

  return useMemo(
    () => ({
      // We need to spread the data object here so that the loading property is not inherited from oldData if it is stale.
      data: {...data, loading},
      loading,
      runsByDisplayName,
    }),
    [data, runsByDisplayName, loading]
  );
};
