import * as globals from '@wandb/weave/common/css/globals.styles';
import React, {FC, memo} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {useColorMode} from '../../util/useColorMode';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {NightAwareProps} from '.';

type EmptyStateProps = {
  header?: string;
  body?: string;
};

const EmptyStateComp: FC<EmptyStateProps> = ({header, body}) => {
  const {isDarkMode} = useColorMode();

  if (!header && !body) {
    return null;
  }

  return (
    <EmptyStateContainer>
      <EmptyStateText isNightMode={isDarkMode}>
        {header && <EmptyStateHeader>{header}</EmptyStateHeader>}
        {body && <EmptyStateBody>{body}</EmptyStateBody>}
      </EmptyStateText>
    </EmptyStateContainer>
  );
};

export const EmptyState = memo(EmptyStateComp);

const EmptyStateContainer = styled.div`
  padding: 24px;
  margin-top: 16px;
  background-color: ${globals.MOON_50};
  border-radius: 4px;
  text-align: center;
`;

const EmptyStateText = styled.div<NightAwareProps>`
  color: ${p => (!p.isNightMode ? globals.MOON_500 : globals.MOON_600)};
`;

const EmptyStateHeader = styled.div`
  font-weight: 600;
`;

const EmptyStateBody = styled.div`
  margin-top: 8px;
`;
