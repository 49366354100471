import React, {useContext, useEffect} from 'react';
import {useLocation} from 'react-router';

import {apolloClient} from '../apolloClient';
import {
  OrganizationSubscriptionInfoQuery,
  PlanType,
} from '../generated/graphql';
import {useGetIsAcademicUser} from '../pages/TrialEndPage/useGetIsAcademicUser';
import {
  isAcademicApplicationPath,
  isBillingPath,
  isStartupProgramPath,
  isTrialEndPath,
  isUserSettingsPath,
} from '../routes';
import {ACADEMIC_APPLICATION_PATH} from '../routes/paths';
import {Analytics} from '../services/analytics';
import {useViewer} from '../state/viewer/hooks';
import {isTrialPlanPrimaryPlan} from '../util/accounts/pricing';
import {isAfter} from '../util/date';
import history from '../util/history';
import {useRampFlagAccountSelector} from '../util/rampFeatureFlags';
import {trialEndPage} from '../util/urls';
import {EOTF_ORGANIZATION_SUBSCRIPTION_BASIC_INFO} from './EndOfTrialEnforcement.query';
import {AccountSelectorContext} from './Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from './Search/SearchNav/types';

type RedirectProps = {
  organizationId: string;
  organizationName: string;
};

const EndOfTrialRedirect = ({
  organizationId,
  organizationName,
}: RedirectProps) => {
  const viewer = useViewer();
  const location = useLocation();
  const entityName = viewer?.entity;
  const id = viewer?.id;
  const isAcademicUser = useGetIsAcademicUser();

  useEffect(() => {
    async function redirectIfTrialExpired() {
      apolloClient
        .query<OrganizationSubscriptionInfoQuery>({
          query: EOTF_ORGANIZATION_SUBSCRIPTION_BASIC_INFO,
          variables: {organizationId},
        })
        .then(({data}) => {
          const primarySub = data?.organization?.subscriptions?.find(
            s => s.plan.planType === PlanType.Primary
          );

          const isTrialPlan =
            primarySub?.plan != null &&
            isTrialPlanPrimaryPlan(primarySub?.plan);
          const isPlanExpired =
            primarySub?.expiresAt != null &&
            isAfter(new Date(), new Date(primarySub?.expiresAt));
          const isAccountSettings = isBillingPath(location.pathname);
          const isTrialEndPage = isTrialEndPath(location.pathname);
          const isAcademicApplicationPage = isAcademicApplicationPath(
            location.pathname
          );
          const isStartupProgramPage = isStartupProgramPath(location.pathname);
          const isUserSettings = isUserSettingsPath(location.pathname);
          const shouldRedirect =
            isTrialPlan &&
            isPlanExpired &&
            !isAccountSettings &&
            !isTrialEndPage &&
            !isAcademicApplicationPage &&
            !isUserSettings &&
            !isStartupProgramPage;
          if (shouldRedirect) {
            if (isAcademicUser) {
              Analytics.track(
                'Aggressively Redirected to Academic Application',
                {
                  location: 'expired-trial',
                  id,
                  entityName,
                  organizationName,
                }
              );
              history.push(ACADEMIC_APPLICATION_PATH);
            } else {
              Analytics.track('Aggressively Redirected', {
                location: 'expired-trial',
                id,
                entityName,
                organizationName,
              });
              history.push(trialEndPage());
            }
          }
        });
    }
    redirectIfTrialExpired();
  }, [
    location.pathname,
    organizationId,
    id,
    entityName,
    organizationName,
    isAcademicUser,
  ]);
  return null;
};

export const EndOfTrialEnforcement = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const skip =
    !enableAccountSelector ||
    selectedAccount == null ||
    selectedAccount.accountType === AccountType.Personal;

  return skip ? null : (
    <EndOfTrialRedirect
      organizationId={selectedAccount?.id ?? ''}
      organizationName={selectedAccount.name}
    />
  );
};
