import {ID} from '@wandb/weave/common/util/id';

import * as CustomRunColorsNormalize from './customRunColors/normalize';
import {getOrCreateDenormalizer} from './denormalize';
import * as DiscussionCommentNormalize from './discussionComment/normalize';
import * as DiscussionThreadNormalize from './discussionThread/normalize';
import * as FilterNormalize from './filter/normalize';
import * as GroupPageNormalize from './groupPage/normalize';
import * as GroupSelectionsNormalize from './groupSelections/normalize';
import * as MarkdownBlockNormalize from './markdownBlock/normalize';
import {
  DenormalizationOptions,
  FullNormFn,
  NormFunctionMap,
  PartWithRef,
  StateType,
} from './normalizerSupport';
import * as PanelNormalize from './panel/normalize';
import * as PanelBankConfigNormalize from './panelBankConfig/normalize';
import * as PanelBankSectionConfigNormalize from './panelBankSectionConfig/normalize';
import * as PanelsNormalize from './panels/normalize';
import * as PanelSettingsNormalize from './panelSettings/normalize';
import * as ProjectPageNormalize from './projectPage/normalize';
import * as ReportNormalize from './report/normalize';
import * as ReportDraftNormalize from './reportDraft/normalize';
import * as RunPageNormalize from './runPage/normalize';
import * as RunSetNormalize from './runSet/normalize';
import * as SectionNormalize from './section/normalize';
import * as SortNormalize from './sort/normalize';
import * as SweepPageNormalize from './sweepPage/normalize';
import * as TempSelectionsNormalize from './tempSelections/normalize';
import * as Types from './types';
import * as WorkspaceSettingsNormalize from './workspaceSettings/normalize';

const normFunctions: NormFunctionMap = {
  'project-view': ProjectPageNormalize.normalize,
  'group-view': GroupPageNormalize.normalize,
  'sweep-view': SweepPageNormalize.normalize,
  'run-view': RunPageNormalize.normalize,
  runs: ReportNormalize.normalize,
  'runs/draft': ReportDraftNormalize.normalize,
  runSet: RunSetNormalize.normalize,
  section: SectionNormalize.normalize,
  'markdown-block': MarkdownBlockNormalize.normalize,
  panels: PanelsNormalize.normalize,
  panel: PanelNormalize.normalize,
  panelSettings: PanelSettingsNormalize.normalize,
  sort: SortNormalize.normalize,
  filters: FilterNormalize.normalize,
  'group-selections': GroupSelectionsNormalize.normalize,
  'run-colors': CustomRunColorsNormalize.normalize,
  'temp-selections': TempSelectionsNormalize.normalize,
  'panel-bank-config': PanelBankConfigNormalize.normalize,
  'panel-bank-section-config': PanelBankSectionConfigNormalize.normalize,
  'discussion-thread': DiscussionThreadNormalize.normalize,
  'discussion-comment': DiscussionCommentNormalize.normalize,
  'workspace-settings': WorkspaceSettingsNormalize.normalize,
};

export function normalize<T extends Types.ObjType>(
  type: T,
  viewID: string,
  whole: Types.ObjSchemaFromType<T>['whole']
): {
  partRef: Types.PartRefFromType<T>;
  partsWithRefs: Array<PartWithRef<Types.ObjType>>;
} {
  const partsWithRefs: Array<PartWithRef<Types.ObjType>> = [];
  const partNormFn = normFunctions[type] as any as FullNormFn<
    Types.ObjSchemaFromType<T>
  >;
  const ctx = {
    viewID,
    result: partsWithRefs,
    idFn: () => ID(),
  };
  const partRef = partNormFn(whole, ctx);
  return {partRef, partsWithRefs};
}

export function addObj<T extends Types.ObjType>(
  partsState: StateType,
  type: T,
  viewID: string,
  whole: Types.ObjSchemaFromType<T>['whole'],
  opts?: {
    // Indicates that whole is an object that is not known to immer.
    // We can freeze it as a performance optimization, which tells immer
    // not to walk it to see if something changed
    wholeIsDefinitelyNew?: boolean;
  }
): Types.PartRefFromType<T> {
  const {wholeIsDefinitelyNew} = opts ?? {};
  const {partRef, partsWithRefs} = normalize(type, viewID, whole);
  for (const {ref, part} of partsWithRefs) {
    partsState[ref.type][ref.id] = wholeIsDefinitelyNew
      ? Object.freeze(part)
      : part;
  }
  return partRef;
}

export function addObjImmutable<T extends Types.ObjType>(
  originalParts: StateType,
  type: T,
  viewID: string,
  whole: Types.ObjSchemaFromType<T>['whole']
): {parts: StateType; ref: Types.PartRefFromType<T>} {
  /* For some reason, the type inference for addObjsImmutable is not working.
   * We know [whole] is the right type here, so it's fine to ignore the type error.
   * This type error is only detected by tsc, not by the linter running in vscode.
   * It's likely that we will be able to remove this when we update to a newer
   * version of TypeScript.
   */
  // @ts-ignore
  const {parts, refs} = addObjsImmutable<T>(originalParts, type, viewID, [
    whole,
  ]);
  return {parts, ref: refs[0]};
}

export function addObjsImmutable<T extends Types.ObjType>(
  originalParts: StateType,
  type: T,
  viewID: string,
  wholes: Types.ObjSchemaFromType<T>['whole'][]
): {parts: StateType; refs: Array<Types.PartRefFromType<T>>} {
  const newParts = Object.assign({}, originalParts);
  const newRefs: Array<Types.PartRefFromType<T>> = [];
  for (const whole of wholes) {
    const {partRef, partsWithRefs} = normalize(type, viewID, whole);
    for (const {ref, part} of partsWithRefs) {
      if (newParts[ref.type] === originalParts[ref.type]) {
        // @ts-ignore
        newParts[ref.type] = Object.assign({}, newParts[ref.type]);
      }
      newParts[ref.type][ref.id] = part;
    }
    newRefs.push(partRef);
  }
  return {parts: newParts, refs: newRefs};
}

export function partExists<R extends Types.AllPartRefs>(
  state: StateType,
  partRef: R
): boolean {
  const partsOfType = state[partRef.type];
  if (partsOfType == null) {
    throw new Error('invalid state');
  }
  return partsOfType[partRef.id] != null;
}

interface DenormalizeWithPartsResult<T extends Types.ObjType> {
  whole: Types.WholeFromTypeWithRef<T>;
  partsWithRef: Array<PartWithRef<Types.ObjType>>;
}

export function denormalizeWithParts<T extends Types.ObjType>(
  state: StateType,
  ref: Types.PartRefFromType<T>,
  opts?: DenormalizationOptions
): DenormalizeWithPartsResult<T> {
  return getOrCreateDenormalizer().denormalizeWholeWithParts(
    state,
    ref,
    opts
  ) as DenormalizeWithPartsResult<T>;
}

export function denormalize<T extends Types.ObjType>(
  state: StateType,
  ref: Types.PartRefFromType<T>,
  opts?: DenormalizationOptions
): Types.WholeFromTypeWithRef<T> {
  return getOrCreateDenormalizer().denormalizeWhole(
    state,
    ref,
    opts
  ) as Types.WholeFromTypeWithRef<T>;
}
