import * as globals from '@wandb/weave/common/css/globals.styles';
import {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router';

import {apolloClient} from '../../apolloClient';
import {makeContext} from '../../components/MakeContext/makeContext';
import {envIsIntegration, envIsLocal} from '../../config';
import {
  GlobalUsageTabInfoQuery,
  UsageAggregation,
  UsageTabInfoQuery,
  UsageType,
  useGlobalUsageTabInfoQuery,
  useUsageTabInfoQuery,
} from '../../generated/graphql';
import {UsageTabURLPart} from '../../routes/paths';
import {
  addMonthsUTC,
  compareAsc,
  DateFormat,
  differenceInCalendarDaysUTC,
  format,
  isAfter,
  isBefore,
  max,
  startOfDayUTC,
  subDaysUTC,
  subMonthsUTC,
} from '../../util/date';
import {Bar} from '../../util/plotHelpers/types';
import {removeNullUndefinedOrFalse} from '../../util/utility';
import {GLOBAL_USAGE_TAB_INFO, USAGE_TAB_INFO} from './UsageTabContent.query';

// Set empty bar for non existent data a smaller proportion of the max value to give
// the tooltip a height to hover on
const EMPTY_BAR_PROPORTION_VALUE = 0.05;
// NOTE - months are 0-indexed, so this is currently July 11, 2024!
// This is the first day that we started collecting historic data for each UsageType
const EARLIEST_SAAS_STORAGE_DATA_DATE = new Date(2024, 6, 11);
EARLIEST_SAAS_STORAGE_DATA_DATE.setUTCHours(0, 0, 0, 0);

// Mid-month is hard to verify so we'll make Jan 1st 2025 first day for onprem storage data
const EARLIEST_ONPREM_STORAGE_DATA_DATE = new Date(2025, 0, 1);
EARLIEST_ONPREM_STORAGE_DATA_DATE.setUTCHours(0, 0, 0, 0);

export enum RolloutGroup {
  Onprem = 'onprem',
  Saas = 'saas',
}
export const EARLIEST_DATA_DATE: Partial<
  Record<RolloutGroup, Partial<Record<UsageType, Date>>>
> = {
  [RolloutGroup.Saas]: {
    [UsageType.Storage]: EARLIEST_SAAS_STORAGE_DATA_DATE,
  },
  [RolloutGroup.Onprem]: {
    [UsageType.Storage]: EARLIEST_ONPREM_STORAGE_DATA_DATE,
  },
};

export function getEarliestUsageRolloutDate(
  usageType: UsageType
): Date | undefined {
  if (envIsIntegration) {
    return; // no cutoff date for integration tests
  } else if (envIsLocal) {
    return EARLIEST_DATA_DATE[RolloutGroup.Onprem]?.[usageType];
  } else {
    return EARLIEST_DATA_DATE[RolloutGroup.Saas]?.[usageType];
  }
}

export enum TimeRange {
  LAST_12_MONTHS = '12 months',
  LAST_24_MONTHS = '24 months',
}

type ProcessedData = Bar & {end: Date; hideValue?: true};

const currentDate = new Date(); // top level variable since we don't want different aggregation calculations to use different dates around midnight
currentDate.setUTCHours(0, 0, 0, 0);

export function valueAggregator(
  data: Pick<UsageAggregation, 'start' | 'end' | 'value'>,
  usageType: UsageType,
  currentAggregatedValue: number,
  aggregationCutoffDate: Date | undefined
): number {
  switch (usageType) {
    case UsageType.Storage: {
      // The backend sends the summed total storage over all the days in the interval, so to get the average
      // calendar for the calendar month for the interval, divide each value by the number of days in the inclusive interval.
      let intervalStart = new Date(data.start);
      let intervalEnd = new Date(data.end);
      const usageTypeEarliestDataDate = getEarliestUsageRolloutDate(usageType);
      const earliestDataDate = max(
        removeNullUndefinedOrFalse([
          aggregationCutoffDate,
          usageTypeEarliestDataDate,
        ])
      ); // Choose whichever date is later to determine the earliest time we could have data

      /**
       * If the earliest data date falls within the interval, then we need to cut the interval short by moving the intervalStart up to the earliest data date for the org
       * If it's outside the interval, we do nothing:
       *     - If it's before the interval, we can divide by the whole interval length, so do nothing.
       *     - If it's after the interval, the value should be 0 anyway, so it doesn't matter what length we divide by, so do nothing.
       */
      if (
        earliestDataDate != null &&
        isBefore(intervalStart, earliestDataDate) &&
        isAfter(intervalEnd, earliestDataDate)
      ) {
        intervalStart = earliestDataDate;
      }

      // If it's the current month's interval, the interval's end needs to be moved back to the current date.
      if (
        isBefore(intervalStart, currentDate) &&
        isAfter(intervalEnd, currentDate)
      ) {
        intervalEnd = currentDate;
      }

      // Floor it up to 0 because if end is before start, that means it's before we have data, so we shouldn't divide by a negative number here (mostly applicable with mock data)
      const numDaysInInterval = Math.max(
        0,
        differenceInCalendarDaysUTC(intervalEnd, intervalStart)
      );

      return (
        currentAggregatedValue + data.value / (numDaysInInterval + 1) // add 1 since it's an inclusive interval
      );
    }
    default:
      return currentAggregatedValue + data.value;
  }
}

function getKey(start: Date, end: Date) {
  return `${start.getUTCFullYear()}-${start.getUTCMonth()}-${start.getUTCDate()},${end.getUTCFullYear()}-${end.getUTCMonth()}-${end.getUTCDate()}`;
}

// Use UTC dates so that the months don't get messed up by local timezones
function getLabel(start: Date, end: Date) {
  return `${format(start, DateFormat.MONTH_DAY, 'UTC')} - ${format(
    end,
    DateFormat.DAY,
    'UTC'
  )}, ${format(start, DateFormat.YEAR, 'UTC')}:`;
}

function getXAxisLabel(start: Date) {
  return format(start, DateFormat.MONTH_YEAR, 'UTC');
}

function processData(
  usage: UsageAggregation[],
  usageType: UsageType,
  aggregationCutoffDate: Date | undefined,
  intervalMarkers: Date[]
): ProcessedData[] {
  // Group usage by bucket and sum
  const usageByDate = usage.reduce((acc, data) => {
    const start = new Date(data.start);
    const end = new Date(data.end);
    const key = getKey(start, end);
    if (key in acc) {
      acc[key].value = valueAggregator(
        data,
        usageType,
        acc[key].value,
        aggregationCutoffDate
      );
    } else {
      acc[key] = {
        value: valueAggregator(data, usageType, 0, aggregationCutoffDate),
        label: getLabel(start, end),
        start,
        end,
      };
    }
    return acc;
  }, {} as {[key: string]: {value: number; label: string; start: Date; end: Date}});

  // Check for missing data and pad with 0s - this handles data before aggregationCutoffDate or org.createdAt
  intervalMarkers.forEach((start, i) => {
    if (i !== intervalMarkers.length - 1) {
      const end = subDaysUTC(intervalMarkers[i + 1], 1);
      const key = getKey(start, end);
      if (!(key in usageByDate)) {
        usageByDate[key] = {value: 0, label: getLabel(start, end), start, end};
      }
    }
  });

  const emptyBarValue = Math.max(
    ...Object.values(usageByDate).map(
      ({value}) => EMPTY_BAR_PROPORTION_VALUE * value
    ),
    1
  );

  return Object.keys(usageByDate)
    .sort((key1, key2) =>
      compareAsc(usageByDate[key1].start, usageByDate[key2].start)
    ) // sort by bucket start date
    .map(key => {
      const isBeforeInitialCutoff =
        aggregationCutoffDate != null &&
        isBefore(usageByDate[key].end, startOfDayUTC(aggregationCutoffDate));
      const earliestDataDate = getEarliestUsageRolloutDate(usageType);
      const isBeforeEarliestDataDate =
        earliestDataDate != null &&
        isBefore(usageByDate[key].end, earliestDataDate);

      if (isBeforeInitialCutoff || isBeforeEarliestDataDate) {
        return {
          key: getXAxisLabel(usageByDate[key].start),
          value: emptyBarValue,
          color: globals.hexToRGB(globals.MOON_300, 0.48),
          title: isBeforeEarliestDataDate
            ? `No data for this period. Data starts ${format(
                earliestDataDate,
                DateFormat.MONTH_DAY_YEAR,
                'UTC'
              )}`
            : 'New customer. No data for this period.',
          end: usageByDate[key].end,
          hideValue: true,
        };
      }
      return {
        key: getXAxisLabel(usageByDate[key].start),
        value: usageByDate[key].value,
        color: globals.hexToRGB(globals.MAGENTA_300, 0.48),
        title: usageByDate[key].label,
        end: usageByDate[key].end,
      };
    });
}

function getNIntervals(startOfNextMonth: Date, n: number) {
  return Array.from({length: n}, (x, i) =>
    subMonthsUTC(startOfNextMonth, i)
  ).reverse();
}

export function getIntervalMarkers(timeRange: TimeRange): Date[] {
  const startOfCurrentMonth = new Date();
  // Ignore local time zone
  startOfCurrentMonth.setUTCHours(0, 0, 0, 0);
  startOfCurrentMonth.setUTCDate(1);
  const startOfNextMonth = addMonthsUTC(startOfCurrentMonth, 1);
  switch (timeRange) {
    case TimeRange.LAST_12_MONTHS:
      // For n intervals, we need n + 1 interval markers
      return getNIntervals(startOfNextMonth, 13);
    case TimeRange.LAST_24_MONTHS:
      return getNIntervals(startOfNextMonth, 25);
  }
}

function getUsageTab(tab: string | undefined): UsageType {
  switch (tab) {
    case UsageTabURLPart.TRACKED_HOURS:
      return UsageType.TrackedHours;
    case UsageTabURLPart.WEAVE:
      return UsageType.Weave;
    default:
      return UsageType.Storage;
  }
}

export type HistoricUsageChartContextValue = {
  processedData: ProcessedData[] | undefined;
  loading: boolean;
  setSelectedTimeRange: (timeRange: TimeRange) => void;
  selectedTimeRange: TimeRange;
  usageType: UsageType;
  error: Error | undefined;
  aggregationCutoffDate: Date | undefined;
  fetchCSVUsage: (
    usageType: UsageType,
    intervalMarkers: Date[]
  ) => Promise<UsageAggregation[]>;
};

export function useOrganizationalHistoricUsageChart(): HistoricUsageChartContextValue {
  const {orgName, tab: selectedTab} = useParams<{
    orgName: string;
    tab?: string;
  }>();
  const usageType = getUsageTab(selectedTab);

  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>(
    TimeRange.LAST_12_MONTHS
  );

  // Cache the processed data so we don't have to re-process it every time the usage type or time range switches back
  const [usageData, setUsageData] = useState<
    Partial<Record<UsageType, Partial<Record<TimeRange, ProcessedData[]>>>>
  >({});

  const [aggregationCutoffDate, setAggregationCutoffDate] = useState<
    Date | undefined
  >();

  const intervalMarkers = getIntervalMarkers(selectedTimeRange);
  const {loading, error} = useUsageTabInfoQuery({
    variables: {
      orgName,
      usageType,
      intervalMarkers: intervalMarkers.map(marker => marker.toISOString()),
    },
    onCompleted: data => {
      const usage = data.organization?.usage;
      if (usage != null) {
        const processedUsage = processData(
          usage,
          usageType,
          data.organization?.createdAt != null
            ? new Date(data.organization?.createdAt)
            : undefined,
          intervalMarkers
        );
        setUsageData(prevUsageData => {
          const newUsage = {...prevUsageData}; // Create a copy so react re-renders when it's updated
          const newUsageUsageType = newUsage[usageType] ?? {};
          newUsageUsageType[selectedTimeRange] = processedUsage;
          newUsage[usageType] = newUsageUsageType;
          return newUsage;
        });
      }
      const createdAt = data.organization?.createdAt;
      if (createdAt != null) {
        setAggregationCutoffDate(new Date(createdAt));
      }
    },
    fetchPolicy: 'no-cache', // Avoid using the cache to avoid filling it up with a zillion rows when we only need to cache the processed data. Apollo is super slow when the cache is large - but we don't need it!
    skip: usageData[usageType]?.[selectedTimeRange] != null, // only query each usage type/time range once and cache processed data in this context provider to improve performance
  });

  const usageTypeEarliestDataDate = getEarliestUsageRolloutDate(usageType);
  const earliestDataDate = max(
    removeNullUndefinedOrFalse([
      aggregationCutoffDate,
      usageTypeEarliestDataDate,
    ])
  ); // Choose whichever date is later to determine the earliest time we could have data

  const fetchCSVUsage = useCallback(
    async (
      usageType: UsageType,
      intervalMarkers: Date[]
    ): Promise<UsageAggregation[]> => {
      return apolloClient
        .query<UsageTabInfoQuery>({
          query: USAGE_TAB_INFO,
          variables: {
            orgName,
            usageType,
            intervalMarkers,
          },
        })
        .then(({data}) => {
          const fetchedUsage = data.organization?.usage ?? [];
          return fetchedUsage.filter(
            ({end}) =>
              earliestDataDate == null ||
              isAfter(new Date(end), earliestDataDate) // don't filter anything unless there's an earliestDataDate
          );
        });
    },
    [earliestDataDate, orgName]
  );

  return useMemo(
    () => ({
      processedData: usageData[usageType]?.[selectedTimeRange],
      loading,
      setSelectedTimeRange,
      selectedTimeRange,
      usageType,
      error,
      aggregationCutoffDate,
      fetchCSVUsage,
    }),
    [
      usageData,
      loading,
      selectedTimeRange,
      setSelectedTimeRange,
      usageType,
      error,
      aggregationCutoffDate,
      fetchCSVUsage,
    ]
  );
}

export function useGlobalHistoricUsageChart(): HistoricUsageChartContextValue {
  const {tab: selectedTab} = useParams<{
    tab?: string;
  }>();
  const usageType = getUsageTab(selectedTab);

  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>(
    TimeRange.LAST_12_MONTHS
  );

  // Cache the processed data so we don't have to re-process it every time the usage type or time range switches back
  const [usageData, setUsageData] = useState<
    Partial<Record<UsageType, Partial<Record<TimeRange, ProcessedData[]>>>>
  >({});

  const [aggregationCutoffDate, setAggregationCutoffDate] = useState<
    Date | undefined
  >();

  const intervalMarkers = getIntervalMarkers(selectedTimeRange);
  const {loading, error} = useGlobalUsageTabInfoQuery({
    variables: {
      usageType,
      intervalMarkers: intervalMarkers.map(marker => marker.toISOString()),
    },
    onCompleted: data => {
      const usage = data.usage;
      if (usage != null) {
        const processedUsage = processData(
          usage,
          usageType,
          undefined, // should probably be when instance is created, eh
          intervalMarkers
        );
        setUsageData(prevUsageData => {
          const newUsage = {...prevUsageData}; // Create a copy so react re-renders when it's updated
          const newUsageUsageType = newUsage[usageType] ?? {};
          newUsageUsageType[selectedTimeRange] = processedUsage;
          newUsage[usageType] = newUsageUsageType;
          return newUsage;
        });
      }
      const createdAt = null; // use real date later
      if (createdAt != null) {
        setAggregationCutoffDate(new Date(createdAt));
      }
    },
    fetchPolicy: 'no-cache', // Avoid using the cache to avoid filling it up with a zillion rows when we only need to cache the processed data. Apollo is super slow when the cache is large - but we don't need it!
    skip: usageData[usageType]?.[selectedTimeRange] != null, // only query each usage type/time range once and cache processed data in this context provider to improve performance
  });

  const fetchCSVUsage = useCallback(
    async (
      usageType: UsageType,
      intervalMarkers: Date[]
    ): Promise<UsageAggregation[]> => {
      return apolloClient
        .query<GlobalUsageTabInfoQuery>({
          query: GLOBAL_USAGE_TAB_INFO,
          variables: {
            usageType,
            intervalMarkers,
          },
        })
        .then(({data}) => {
          return data.usage ?? [];
        });
    },
    []
  );

  return useMemo(
    () => ({
      fetchCSVUsage,
      processedData: usageData[usageType]?.[selectedTimeRange],
      loading,
      setSelectedTimeRange,
      selectedTimeRange,
      usageType,
      error,
      aggregationCutoffDate,
    }),
    [
      fetchCSVUsage,
      usageData,
      loading,
      selectedTimeRange,
      setSelectedTimeRange,
      usageType,
      error,
      aggregationCutoffDate,
    ]
  );
}

export const {
  Provider: HistoricUsageChartContextProvider,
  hook: useHistoricUsageChartContext,
} = makeContext<HistoricUsageChartContextValue>({
  contextType: 'HistoricUsageChart',
});
