import {NetworkStatus} from 'apollo-client';
import React, {createContext, useEffect, useMemo, useState} from 'react';

import {
  OrganizationSubscriptionType,
  useAccountSelectorContextProviderEntityQuery,
  useOrganizationSubscriptionBasicInfoByNameQuery,
  useViewerUserInfoQuery,
} from '../../../generated/graphql';
import {getAccountType} from '../../../pages/Billing/AccountSettings/util';
import {PlanName} from '../../../pages/Billing/util';
import {isProfilePath, useCurrentRouteMatch} from '../../../routes/utils';
import {useViewer} from '../../../state/viewer/hooks';
import {getPrimarySub} from '../../../util/accounts/pricing';
import {useAdminModeActive} from '../../../util/admin';
import {safeLocalStorage, safeSessionStorage} from '../../../util/localStorage';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import {Account, AccountType} from './types';
import {useAccountList} from './useAccountList';

export function safelyParseAccountJson(
  json: string,
  accountList: Account[]
): Account | null {
  try {
    const {id} = JSON.parse(json); // Ignore anything else that may be from old values before a mutation might've happened
    const matchingAccount = accountList.find(account => account.id === id);
    return matchingAccount != null
      ? {...matchingAccount, id} // Use the most up to date data since the session storage value might be stale
      : JSON.parse(json); // If there's no matching account, they might be impersonating or using admin mode, so just use the potentially stale data
  } catch (e) {
    return null;
  }
}

export const ACCOUNT_SELECTOR_VALUE = 'account-selector-value';
export const LAST_ACCOUNT_SELECTOR_VALUE = 'last-account-selector-value';
export const LAST_NON_IMPERSONATED_ACCOUNT = 'last-non-impersonated-account';

type AccountSelectorContextProviderProps = {
  selectedAccount: Account | null;
  setSelectedAccount: (value: Account | null) => void;
  lastNonImpersonatedAccount: Account | null;
  setLastNonImpersonatedAccount: (value: Account | null) => void;
  canSeeOrgInURL: boolean; // Used for individual pages to know whether to redirect to no-access
};

export function useDefaultAccount(): Account | null {
  const viewer = useViewer();
  const {accountList, isLoading} = useAccountList();
  const defaultEntityOrganization = viewer?.teams.edges.find(
    team => team.node.id === viewer?.defaultEntity?.id
  );
  const account = accountList?.find(
    o => o.id === defaultEntityOrganization?.node.organizationId
  );
  const isPersonalEntity =
    defaultEntityOrganization?.node.name === viewer?.username;
  const defaultAccount = useMemo(() => {
    if (isLoading || viewer == null) {
      return null;
    }
    let personalPaidAccount = null;
    if (account == null) {
      personalPaidAccount = accountList.find(
        accountItem =>
          accountItem?.accountType === AccountType.Personal &&
          accountItem.personalOrgId != null
      );
    }
    return account
      ? {
          name: account?.name ?? '',
          id: account?.id ?? '',
          accountType: isPersonalEntity
            ? AccountType.Personal
            : account?.accountType,
          isEnterprise: account?.isEnterprise,
          memberCount: account?.memberCount,
          username: account?.username,
        }
      : personalPaidAccount
      ? personalPaidAccount
      : {
          name: viewer?.username ?? '',
          id: viewer?.id ?? '',
          accountType: AccountType.Personal,
          username: viewer?.username ?? '',
        };
  }, [account, isPersonalEntity, viewer, isLoading, accountList]);
  return defaultAccount;
}

const initialState = {
  selectedAccount: null,
  setSelectedAccount: () => {},
  lastNonImpersonatedAccount: null,
  setLastNonImpersonatedAccount: () => {},
  canSeeOrgInURL: true,
};

export const AccountSelectorContext =
  createContext<AccountSelectorContextProviderProps>(initialState);
AccountSelectorContext.displayName = 'AccountSelectorContext';

type MatchParams = {
  params: {
    entityName?: string;
    orgName?: string;
  };
};

export const AccountSelectorContextProvider: React.FC = ({children}) => {
  const viewer = useViewer();
  const {accountList, isLoading: isAccountListLoading} = useAccountList();
  const {data: viewerData, loading: userInfoLoading} = useViewerUserInfoQuery();
  const userInfo = viewerData?.viewer?.userInfo;

  const {
    params: {orgName, entityName},
  }: MatchParams = useCurrentRouteMatch() ?? {params: {}};
  const hasNoEntityOrOrgInUrl = entityName == null && orgName == null;

  const [selectedAccount, setSelectedAccount] = useState(() => {
    const currentlySelectedAccount = safeSessionStorage.getItem(
      ACCOUNT_SELECTOR_VALUE
    );
    return currentlySelectedAccount && hasNoEntityOrOrgInUrl // URL orgName/entityName gets precedence over session storage
      ? safelyParseAccountJson(currentlySelectedAccount, accountList)
      : null;
  });
  const [lastSelectedAccount, setLastSelectedAccount] = useState(() => {
    const lastAccount = safeLocalStorage.getItem(LAST_ACCOUNT_SELECTOR_VALUE);
    return lastAccount
      ? safelyParseAccountJson(lastAccount, accountList)
      : null;
  });
  const [lastNonImpersonatedAccount] = useState(() => {
    const userState = safeLocalStorage.getItem(LAST_NON_IMPERSONATED_ACCOUNT);
    return userState ? safelyParseAccountJson(userState, accountList) : null;
  });

  useEffect(() => {
    if (!isAccountListLoading && accountList.length > 0) {
      const matchingAccount = accountList.find(
        account => account.id === selectedAccount?.id
      );
      if (
        matchingAccount != null &&
        JSON.stringify(selectedAccount) !== JSON.stringify(matchingAccount)
      ) {
        updateSelectedAccount(matchingAccount);
      }
    }
  }, [accountList, selectedAccount, isAccountListLoading]);

  const defaultAccount = useDefaultAccount();

  const [canSeeOrgInURL, setCanSeeOrgInURL] = useState(true);

  useEffect(() => {
    // if viewer hasn't selected an account yet,
    // set the selected account to the user's last selected account or default entity organization
    if (!selectedAccount && (hasNoEntityOrOrgInUrl || !canSeeOrgInURL)) {
      updateSelectedAccount(lastSelectedAccount ?? defaultAccount);
    }
  }, [
    selectedAccount,
    lastSelectedAccount,
    defaultAccount,
    hasNoEntityOrOrgInUrl,
    canSeeOrgInURL,
  ]);

  useEffect(() => {
    // update selected account to the new user's when impersonating
    if (
      selectedAccount &&
      viewer?.username &&
      selectedAccount?.username != null &&
      selectedAccount.username !== viewer?.username
    ) {
      updateSelectedAccount(defaultAccount);
    }
  }, [selectedAccount, viewer?.username, defaultAccount]);

  useEffect(() => {
    // If user has a selected account that does not belong to the user
    // and user is not impersonating, then select next available user owned account.
    const isHidePersonalEntityFlagOn =
      userInfo?.isPersonalEntityHidden ?? false;
    const isUserNotImpersonating =
      viewer?.username &&
      selectedAccount?.username != null &&
      selectedAccount.username === viewer?.username;
    const isUserNotUsingAdminMode = selectedAccount?.isDummyAccount !== true;
    const isAccountListMissingSelectedAccount =
      accountList.every(a => a.name !== selectedAccount?.name) &&
      accountList.length > 0;
    if (
      selectedAccount &&
      isUserNotUsingAdminMode &&
      isUserNotImpersonating &&
      isHidePersonalEntityFlagOn &&
      isAccountListMissingSelectedAccount
    ) {
      updateSelectedAccount(accountList[0]);
    }
  }, [selectedAccount, viewer?.username, userInfo, accountList]);

  const {data: entityOrg, networkStatus: entityNetworkStatus} =
    useAccountSelectorContextProviderEntityQuery({
      variables: {teamName: entityName ?? ''},
      skip: !entityName,
    });

  // The organization associated with the orgName in the URL gets precedence over the
  // entity's org (in the event that they don't match).
  const orgNameFromCurrentUrl =
    orgName ?? entityOrg?.entity?.organization?.name;
  const skipOrgQuery = !orgNameFromCurrentUrl;
  const {data: org, networkStatus} =
    useOrganizationSubscriptionBasicInfoByNameQuery({
      variables: {name: orgNameFromCurrentUrl ?? ''},
      skip: skipOrgQuery,
    });

  const usersAccounts = useAccountList();
  const enableAccountSelector = useRampFlagAccountSelector();
  const isAdminMode = useAdminModeActive();

  // This useEffect handles updating the selected account based on the org/entity in the URL,
  // including for admin mode or public URLs. It should only fire
  //    1. if the org name is different from the selected account's name to avoid issues where
  //       the org name is the same as a personal account's name.
  //    2. if the selected account needs to be initialized
  useEffect(() => {
    if (
      enableAccountSelector &&
      !userInfoLoading &&
      !usersAccounts.isLoading &&
      (selectedAccount?.name !== orgNameFromCurrentUrl ||
        selectedAccount == null)
    ) {
      const orgID = org?.organization?.id;
      const orgAccount = orgID
        ? usersAccounts.accountList.find(o => o.id === orgID)
        : null;
      if (orgAccount != null) {
        updateSelectedAccount(orgAccount);
      }

      const isViewersPersonalEntity =
        orgAccount == null &&
        viewer?.username != null &&
        (entityName === viewer?.username || orgName === viewer?.username);

      const shouldHidePersonalEntity =
        userInfo?.isPersonalEntityHidden ?? false;

      // This is the personal account, so switch it
      if (
        isViewersPersonalEntity &&
        (!isProfilePath(location.pathname) || selectedAccount == null) // If we're on the profile page, we don't want to switch to the personal account unless it hasn't been initialized yet
      ) {
        // If this is the personal account (and not the profile page)
        const personalAccount = usersAccounts.accountList.find(
          account => account?.accountType === AccountType.Personal
        );
        if (personalAccount && !shouldHidePersonalEntity) {
          updateSelectedAccount(personalAccount);
        }
      }

      const orgExists =
        orgNameFromCurrentUrl != null && org?.organization != null;
      // If there's an org in the URL, and it's not the personal account org
      if (orgAccount == null && !isViewersPersonalEntity && orgExists) {
        // We are viewing an org that we're not a member of, so create a dummy account for the account selector
        const organization = org?.organization ?? undefined;
        const primarySubType = getPrimarySub(organization)?.subscriptionType;
        const primarySubPlan = getPrimarySub(organization)?.plan?.name;
        updateSelectedAccount({
          name: orgNameFromCurrentUrl ?? '',
          accountType: getAccountType(organization!),
          id: org?.organization?.id,
          username: viewer?.username ?? '',
          memberCount: org?.organization?.memberCount,
          isEnterprise:
            primarySubPlan === PlanName.Enterprise &&
            primarySubType !== OrganizationSubscriptionType.Academic &&
            primarySubType !== OrganizationSubscriptionType.AcademicTrial,
          isDummyAccount: true,
        });
      }

      // If there's a personal entity in the URL, but it's not ours, and we're in admin mode, then switch
      // to the personal entity as a dummy account. UNLESS it's on the profile path, in which case, we're just
      // viewing their profile (not as them).
      const isPersonalEntity =
        entityOrg != null && entityOrg.entity?.isTeam === false;
      if (
        orgAccount == null &&
        !isViewersPersonalEntity &&
        !orgExists &&
        isPersonalEntity &&
        isAdminMode &&
        selectedAccount?.name !== entityName
      ) {
        updateSelectedAccount({
          name: entityName ?? '',
          accountType: AccountType.Personal,
          id: entityOrg?.entity?.members?.[0]?.id ?? '', // There's only one member in a personal entity, so just use their id
          username: viewer?.username ?? '',
          isDummyAccount: true,
        });
      }

      const orgOrEntityInUrl = orgName != null || entityName != null;
      const dontKnowEntityOrg =
        skipOrgQuery && entityNetworkStatus === NetworkStatus.ready; // The entity's org has been queried, but we can't see the name

      // While info we need is loading, we assume they can see the org in the url to avoid premature redirects to no access.
      const isLoading =
        (!skipOrgQuery && networkStatus !== NetworkStatus.ready) ||
        (entityName != null && entityNetworkStatus !== NetworkStatus.ready) ||
        viewer?.username == null;

      // If there's an org in the URL, but no organization is returned, this means it's an org
      // that doesn't exist or we don't have permissions to see
      const cannotSeeOrgFromUrl =
        orgOrEntityInUrl &&
        org?.organization == null &&
        (networkStatus === NetworkStatus.ready || dontKnowEntityOrg); // Need to check network status, otherwise there's no way to tell if the query hasn't completed vs the query returned null

      setCanSeeOrgInURL(
        isLoading || isViewersPersonalEntity || !cannotSeeOrgFromUrl
      );

      // If there's no entity or org in the URL, but selected account was previously selected to be a dummy account,
      // we should switch back to the default account (the last selected account may also be a dummy account somehow).
      // This could happen if in admin mode viewing an org's page, then clicking the home button for example.
      if (!orgOrEntityInUrl && selectedAccount?.isDummyAccount === true) {
        updateSelectedAccount(defaultAccount);
      }
    }
  }, [
    selectedAccount,
    entityOrg,
    usersAccounts,
    org,
    orgName,
    entityName,
    viewer?.username,
    networkStatus,
    defaultAccount,
    orgNameFromCurrentUrl,
    selectedAccount?.isDummyAccount,
    skipOrgQuery,
    entityNetworkStatus,
    enableAccountSelector,
    userInfo,
    userInfoLoading,
    isAdminMode,
  ]);

  const updateSelectedAccount = (value: Account | null) => {
    const stringValue = JSON.stringify(value);
    setSelectedAccount(value);
    safeSessionStorage.setItem(ACCOUNT_SELECTOR_VALUE, stringValue);
    setLastSelectedAccount(value);
    safeLocalStorage.setItem(LAST_ACCOUNT_SELECTOR_VALUE, stringValue);
  };

  const updateLastNonImpersonatedAccount = (value: Account | null) => {
    const stringValue = JSON.stringify(value);
    safeLocalStorage.setItem(LAST_NON_IMPERSONATED_ACCOUNT, stringValue);
  };

  return (
    <AccountSelectorContext.Provider
      value={{
        selectedAccount,
        setSelectedAccount: updateSelectedAccount,
        lastNonImpersonatedAccount,
        setLastNonImpersonatedAccount: updateLastNonImpersonatedAccount,
        canSeeOrgInURL,
      }}>
      {children}
    </AccountSelectorContext.Provider>
  );
};
