import {ExcludeOutliersValues} from '../../types';

type ExcludeOutliersSettings = {
  excludeOutliers?: ExcludeOutliersValues;
  ignoreOutliers?: boolean;
  showMinMaxOnHover?: 'always' | 'never' | 'onHover';
};

/**
 * Priority
 * 1: if there's a new excludeOutliers setting on the panel, use that
 * 2: Depending on visualization mode, use the child's showMinMaxOnHover or ignoreOutliers setting
 * 3: Otherwise, use the parent's excludeOutliers setting
 */
export const getCascadingExcludeOutliers = (
  isFullFidelityMode: boolean,
  parentSetting: ExcludeOutliersSettings,
  childSetting: ExcludeOutliersSettings
): ExcludeOutliersValues | undefined => {
  if (childSetting.excludeOutliers) {
    return childSetting.excludeOutliers;
  }

  if (isFullFidelityMode) {
    if (childSetting.showMinMaxOnHover) {
      if (childSetting.showMinMaxOnHover === 'never') {
        return 'exclude-outliers';
      }
      return 'include-outliers';
    }
  }
  if (childSetting.ignoreOutliers != null) {
    return childSetting.ignoreOutliers
      ? 'exclude-outliers'
      : 'include-outliers';
  }

  if (parentSetting.excludeOutliers) {
    return parentSetting.excludeOutliers;
  }

  if (isFullFidelityMode) {
    if (parentSetting.showMinMaxOnHover) {
      if (parentSetting.showMinMaxOnHover === 'never') {
        return 'exclude-outliers';
      }
      return 'include-outliers';
    }
  } else {
    if (parentSetting.ignoreOutliers != null) {
      return parentSetting.ignoreOutliers
        ? 'exclude-outliers'
        : 'include-outliers';
    }
  }

  return undefined;
};
