import {getCookie, setCookie} from '@wandb/weave/common/util/cookie';
import {ApolloLink, Observable, Operation} from 'apollo-link';
import {onError} from 'apollo-link-error';
import {createHttpLink} from 'apollo-link-http';
import {RetryLink} from 'apollo-link-retry';
import * as queryString from 'query-string';
import {Dispatch, Store} from 'redux';

import {backendHost} from '../config';
import {captureError} from '../integrations';
import {
  displayError,
  displayErrorPortal,
  resetErrorPortal,
} from '../state/global/actions';
// eslint-disable-next-line import/default
import CompressViewSpecWorker from '../workers/compressViewSpec.worker?worker&inline';
import {getPerfTimingLink} from './getPerfTimingLink';
import {safeLocalStorage} from './localStorage';
import {isOnReportView} from './urls';

export const compressSpecInWorker = (data: any): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const worker = new CompressViewSpecWorker();

    worker.onmessage = (
      event: MessageEvent<{
        success: boolean;
        compressed: string | null;
        error?: string;
      }>
    ) => {
      const {success, compressed, error} = event.data;
      if (success) {
        resolve(compressed);
      } else {
        reject(new Error(error));
      }
      worker.terminate();
    };

    worker.postMessage(data);
  });
};

// These global vars are set by `createCircularDependencies` below :P
// There should definitely be a better way of doing this.
let dispatch: Dispatch;
// auth is from auth.js and is currently untyped
let auth: any = null;

export const apolloEndpoint = `${backendHost()}/graphql`;

const httpLink = createHttpLink({
  fetch: (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    // we force using window.fetch because we need to use datadog's updated instance of window.fetch
    return window.fetch(input, init);
  },
  uri: apolloEndpoint,
  // Our credentials may be a cookie on a different domain to backend
  // `api.wandb.ai` vs `wandb.ai`
  // https://www.apollographql.com/docs/react/networking/authentication/#cookie
  credentials: 'include',
});

const useParallelGzipMiddleware = new ApolloLink((operation, forward) => {
  // For queries that return large results, the content encoding step is
  // 10x faster with the backend's parallel-gzip encoding over the default
  // brotli encoding.
  if (
    operation.operationName === 'Views2View' ||
    operation.operationName === 'Views2RawView' ||
    operation.operationName === 'HistoryKeys'
  ) {
    setHeader(operation, 'x-wandb-compression', 'parallel-gzip');
  }
  return forward!(operation);
});

// Adds a header specifying that an admin wants to use their
// admin privileges based on a cookie that they
// can manually toggle.
const useAdminPrivilegesMiddleware = new ApolloLink((operation, forward) => {
  const useAdminPrivileges = getCookie('use_admin_privileges') === 'true';
  if (useAdminPrivileges) {
    setHeader(operation, 'use-admin-privileges', 'true');
  }
  return forward!(operation);
});

const useViewAsMiddleware = new ApolloLink((operation, forward) => {
  const useViewAs = getCookie('impersonated_username');
  if (useViewAs) {
    setHeader(operation, 'impersonated-username', useViewAs);
  }
  return forward!(operation);
});

// This middleware currently compresses the spec field on UpsertView2 only.
// Once https://github.com/wandb/core/pull/19264/files is merged, we can modify this middleware
// to compress the entire request body for better performance.
const compressionMiddleware = new ApolloLink((operation, forward) => {
  const safeForward = forward!;
  return new Observable(observer => {
    if (operation.operationName === 'UpsertView2') {
      const {spec, specIsGzipped} = operation.variables;
      if (spec && !specIsGzipped) {
        compressSpecInWorker(spec)
          .then(compressed => {
            operation.variables.spec = compressed;
            operation.variables.specIsGzipped = true;
            safeForward(operation).subscribe(observer);
          })
          .catch(e => {
            console.error('Error compressing view spec', e);
            safeForward(operation).subscribe(observer);
          });
      } else {
        safeForward(operation).subscribe(observer);
      }
    } else {
      safeForward(operation).subscribe(observer);
    }
  });
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const safeForward = forward!;
  return new Observable(observer => {
    auth.ensureCurrentIdToken().then((t: string | null) => {
      // The signup flow accepts a token
      const qs = queryString.parse(window.location.search);
      const token = qs.token || t;
      const apiKey = qs.apiKey || getCookie('anon_api_key');
      const accessToken = qs.accessToken;

      setHeader(operation, 'X-Origin', window.location.origin);

      if (token) {
        setHeader(operation, 'authorization', `Bearer ${token}`);
      }

      if (apiKey) {
        // The user is using an API key instead of a JWT to authenticate.
        setCookie('anon_api_key', apiKey as string);

        setHeader(
          operation,
          'X-Wandb-Anonymous-Auth-Id',
          btoa(apiKey as string)
        );
      }

      // If the query string contains the API key, scrub it so that users
      // don't accidentally share links containing their key.
      if (qs.apiKey) {
        const newQueryString = {...qs};
        delete newQueryString.apiKey;
        window.location.search = queryString.stringify(newQueryString);
      }

      if (accessToken && isOnReportView()) {
        setHeader(operation, 'access-token', accessToken as string);
      }

      safeForward(operation).subscribe(observer);
    });
  });
});

const stackdriverMiddleware = new ApolloLink((operation, forward) => {
  const safeForward = forward!;
  const qs = queryString.parse(window.location.search);

  if (qs.trace) {
    console.log('DOING TRACE');
    const requestCountString = safeLocalStorage.getItem('request_count');
    const count =
      requestCountString != null ? parseInt(requestCountString, 10) : 0;
    operation.setContext(({headers = {}}: {headers: any}) => ({
      headers: {
        ...headers,
        'X-Cloud-Trace-Context':
          safeLocalStorage.getItem('page_id') + '/' + count + ';o=1',
      },
    }));
    safeLocalStorage.setItem('request_count', (count + 1).toString());
  }

  return safeForward(operation);
});

const errorLink = onError(
  ({operation, response, networkError, graphQLErrors}) => {
    // NOTE: Don't accidentally return something from this function! You'll
    // potentially get an error that says "retriedResult.subscribe is not a
    // function" because you're not returning an observable.

    console.log('Apollo NetworkErrors:', networkError);
    console.log('Apollo GraphQLErrors:', graphQLErrors);

    // Regardless of propagateErrors, we need to bubble up 401's so the frontend
    // can prompt us to login.
    if (
      // eslint-disable-next-line no-extra-boolean-cast
      !Boolean(operation.getContext().noLogin) &&
      networkError &&
      (networkError as any).statusCode === 401
    ) {
      dispatch(displayErrorPortal('Session expired. Forcing login...'));
      dispatch(
        displayError({
          code: 401,
          message: networkError.message,
        })
      );
      return;
    }

    // When propagateErrors is set on the context, the query issuer wants to
    // handle errors manually so abort processing.
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(operation.getContext().propagateErrors)) {
      return;
    }

    if (graphQLErrors) {
      // Capture GraphQL errors
      graphQLErrors.forEach(({message, locations, path}) => {
        captureError(`[GraphQL Error] ${message}`, 'apollo_graphql_error', {
          level: 'info',
          extra: {
            operation: operation.operationName,
            path,
            locations,
          },
        });
      });
      // suppressing these errors for now
      // https://wandb.atlassian.net/browse/WB-12328 for context
      const displayableErrors = graphQLErrors.filter(
        ({path}) =>
          !path || !(path[0] === 'project' && path[1] === 'runKeySuggestions')
      );
      if (displayableErrors.length > 0) {
        // Just show the last error if we have multiple graphQL errors.
        dispatch(displayError(displayableErrors[displayableErrors.length - 1]));
      }
      return;
    }

    if (networkError) {
      if (networkError.message === 'Failed to fetch') {
        dispatch(displayErrorPortal('Offline. Trying to reconnect...'));
        return;
      }

      captureError(
        `[Network Error] ${networkError.message}`,
        'apollo_network_error',
        {
          level: 'info',
          extra: {
            operation: operation.operationName,
            error: networkError,
            response,
          },
        }
      );

      const {result, errResult} = networkError as any;
      const errorMessage =
        (errResult && errResult.error) ||
        (result && result.error) ||
        'Application Error';
      dispatch(
        displayError({
          code: (networkError as any).statusCode || 503,
          message: errorMessage,
        })
      );
    }
  }
);

const resetErrorPortalLink = new ApolloLink((operation, forward) => {
  const observable = forward(operation);
  return new Observable(observer => {
    observable.subscribe({
      complete: () => {
        // We delay resetting the error portal so the user can see the message
        setTimeout(() => dispatch(resetErrorPortal()), 3000);
        observer.complete();
      },
      error: e => observer.error(e),
      next: n => observer.next(n),
    });
  });
});

// Note apollo is a POS and doesn't consistently return the error object.
// We check for the case where error.statusCode is not present, which typically
// means a request failed in a retryable way (for us).
// https://github.com/apollographql/apollo-link/issues/300

const retryLink = new RetryLink({
  /**
   * The delay config object doesn't seem to do exponential backoff well, so we're implementing a custom function for it here. Count will range from 0 to `attempts.max`.
   */
  delay: count => {
    const sign = Math.random() < 0.5 ? -1 : 1;
    const random = sign * Math.ceil(Math.random() * 75);

    // we add a little bit of randomness to the delay so all the panels aren't retrying at exactly the same time
    const delay = 200 * Math.pow(2, count) + random;
    return delay;
  },
  attempts: {
    max: 7,
    retryIf: (error, operation) => {
      if (operation.getContext().doNotRetry) {
        return false;
      }

      /**
       * TODO(adrian): We should probably not be retrying 500s.
       *
       * It's safer for us to aggressively retry like this but it causes lots of spurious requests and makes a mess in the logs and in Sentry.
       */
      const is500Error = error && error.statusCode && error.statusCode >= 500;

      // Happens when the fetch API receives a rejected promise, which happens on temporary server failures sometimes.
      const isFetchError =
        (error && error.statusCode == null) ||
        error.message === 'Unexpected token < in JSON at position 0';

      // Only retry internal errors
      return is500Error || isFetchError;
    },
  },
});

export const apolloLink = ApolloLink.from([
  useParallelGzipMiddleware,
  useAdminPrivilegesMiddleware,
  useViewAsMiddleware,
  authMiddleware,
  stackdriverMiddleware,
  errorLink,
  resetErrorPortalLink,
  retryLink,
  getPerfTimingLink(),
  compressionMiddleware,
  httpLink,
]);

export const createCircularDependencies = (store: Store, authIn: any) => {
  dispatch = store.dispatch;
  auth = authIn;
};

interface OperationContext {
  headers?: {[key: string]: string};
}

function setHeader(op: Operation, key: string, value: string) {
  op.setContext(({headers = {}}: OperationContext) => ({
    headers: {
      ...headers,
      [key]: value,
    },
  }));
}
