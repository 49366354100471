export interface Chart {
  config: {lines: string[]};
  layout: {x: number; y: number; w: number; h: number};
}

export type ChartAreaOption =
  | 'minmax'
  | 'none'
  | 'samples'
  | 'stddev'
  | 'stderr';

// Samples means we show the user a random set of actual values
export type ChartAggOption =
  | 'last'
  | 'max'
  | 'mean'
  | 'median'
  | 'min'
  | 'samples'
  | 'sum';

/**
 * The default key is the primary suffix that we want to use binned data off the API response.
 * It was 'Avg' previously, but it's been set to 'Last' so that we can use a real point off the history data. 'Last' refers to the final point in each bucket. It will generally be synonomous with the max xAxis value
 *
 * NOTE: it should eventually be possible to change this back to "Avg" (or something else entirely) but right now there are a few bits of behavior not fully synced with this.
 */
export const BIN_POINT_TO_USE = 'Last';
