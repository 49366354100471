import {WorkspaceLayoutSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {setInShallowClone} from '../../../util/utility';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as PanelBankConfigUtils from '../panelBankConfig/utils';
import {ActionType, ViewReducerState} from '../reducerSupport';
import {isReportView} from '../util';
import * as Actions from './actions';
import * as Types from './types';

/**
 * Updates `shouldAutoGeneratePanels` workspace setting.
 * Returns new state only if the value would be changed.
 *
 * This util _only_ updates the setting, without any of the
 * extra logic defined in `disableAutoGeneratePanels` and
 * `enableAutoGeneratePanels`. In general, you should use
 * those instead unless you're certain it's not necessary.
 */
export const setShouldAutoGeneratePanels = (
  prevState: ViewReducerState,
  ref: Types.Ref,
  value: WorkspaceLayoutSettings['shouldAutoGeneratePanels']
) => {
  const prevValue = prevState.parts[ref.type][ref.id].shouldAutoGeneratePanels;
  if (prevValue === value) {
    return prevState;
  }
  return setInShallowClone(
    prevState,
    ['parts', ref.type, ref.id, 'shouldAutoGeneratePanels'],
    value
  );
};

export const disableAutoGeneratePanels = (
  prevState: ViewReducerState,
  workspaceSettingsRef: Types.Ref,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  expectedPanels: ExpectedPanelsState
): [ViewReducerState, ActionType] => {
  let newState = setShouldAutoGeneratePanels(
    prevState,
    workspaceSettingsRef,
    false
  );

  // hide auto-gen info banner as necessary
  const viewRef = newState.views[workspaceSettingsRef.viewID].partRef;
  if (viewRef && !isReportView(viewRef)) {
    const {panelAutoGenInfoHiddenAt} = newState.parts[viewRef.type][viewRef.id];
    if (panelAutoGenInfoHiddenAt == null) {
      newState = setInShallowClone(
        newState,
        ['parts', viewRef.type, viewRef.id, 'panelAutoGenInfoHiddenAt'],
        new Date().toISOString()
      );
    }
  }

  // Clear out hidden panels since we don't need to track deleted panels in manual mode
  const panelBankConfig =
    newState.parts[panelBankConfigRef.type][panelBankConfigRef.id];
  const hiddenSectionRef = panelBankConfig.sectionRefs.slice(-1)[0];
  newState = setInShallowClone(
    newState,
    ['parts', hiddenSectionRef.type, hiddenSectionRef.id, 'panelRefs'],
    []
  );

  const inverseAction = Actions.enableAutoGeneratePanels(
    workspaceSettingsRef,
    panelBankConfigRef,
    expectedPanels
  );
  return [newState, inverseAction];
};

export const enableAutoGeneratePanels = (
  prevState: ViewReducerState,
  workspaceSettingsRef: Types.Ref,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  expectedPanels: ExpectedPanelsState
): [ViewReducerState, ActionType] => {
  // update setting
  let newState = setShouldAutoGeneratePanels(
    prevState,
    workspaceSettingsRef,
    true
  );

  // init missing expected panels as hidden panels, which allows us
  // to maintains "deleted" panel state across auto and manual modes
  const diffAndInitResults = PanelBankConfigUtils.immutableDiffAndInitPanels(
    newState,
    panelBankConfigRef,
    expectedPanels,
    true, // should generate panels
    true // should init as hidden panels
  );
  newState = diffAndInitResults.state;

  // NOTE: This is an example where `expectedPanels` NOT being part of the `views` reducer is a
  // problem. `expectedPanels` must be included on the action payload since it's not accessible
  // within the views reducer. Aside from being a bit clunky, this means we will be using a
  // snapshot of `expectedPanels` at the time the action was created. Most of the time this won't
  // be an issue, but the snapshot could become outdated for undo/redo actions. It's very subtle,
  // but there's potential for a bug to occur here. Ideally, we'd move `expectedPanels` back into
  // the views reducer, but that would cause noticable perf degradations for all view actions
  // using immer (AKA most of them). I think a subtle bug that *might* occur *only* when you
  // undo/redo a auto-gen setting change is a very reasonable tradeoff for general perf.
  // - Connie July 2024

  const inverseAction = Actions.disableAutoGeneratePanels(
    workspaceSettingsRef,
    panelBankConfigRef,
    expectedPanels
  );

  return [newState, inverseAction];
};
