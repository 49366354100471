import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {useSyncedPollingSubscriber} from '../../state/polling/hooks';
import {Query} from '../../state/runs/types';
import {BucketedQueryManager} from '../PanelRunsLinePlot/bucketedData/bucketedQueryManager';
import {Range} from '../PanelRunsLinePlot/common';
import {Zoom} from '../PanelRunsLinePlot/types';
import {usePanelsSharedRefsContext} from '../Views/contexts/PanelsSharedRefsContext';
import {useParts} from './../../state/views/hooks';

export interface SharedPanelQueryState {
  query: Query | null;
  result: any;
}

interface SharedPanelStateContextType {
  bucketQueryManagerById: React.MutableRefObject<
    Record<string, BucketedQueryManager>
  >;
  queryStateById: Record<string, SharedPanelQueryState>;
  setQueryStateById: (runSetId: string, qs: SharedPanelQueryState) => void;
  setUserZoomState: React.Dispatch<React.SetStateAction<Zoom>>;
  setXDomainQuery: (xDomainQuery: Range) => void;
  userZoomState: Zoom;
  xDomainQuery: Range;
}

export const SharedPanelStateContext =
  createContext<SharedPanelStateContextType | null>(null);

const defaultQueryState = {
  query: null,
  result: null,
};

function useIds() {
  const {runSetRefs} = usePanelsSharedRefsContext();

  const runsets = useParts(runSetRefs);
  const runsetIds = useMemo(() => runsets.map(rs => rs.id), [runsets]);

  return runsetIds ?? [];
}

export const SharedPanelStateContextProvider = ({
  children,
  config,
  useRunsetIds = useIds,
}: {
  children: React.ReactNode;
  config: {
    [key: string]: unknown;
    xAxisMin?: number | null;
    xAxisMax?: number | null;
  };
  useRunsetIds?: typeof useIds;
}) => {
  const runsetIds = useRunsetIds();
  /**
   * Query states
   */
  const [queryStateById, setQueryState] = useState<
    Record<string, SharedPanelQueryState>
  >({});

  const setQueryStateById: SharedPanelStateContextType['setQueryStateById'] =
    useCallback(
      (runsetId, qs) => {
        setQueryState(prev => ({
          ...prev,
          [runsetId]: qs,
        }));
      },
      [setQueryState]
    );

  const bucketQueryManagerById = useRef<Record<string, BucketedQueryManager>>(
    (runsetIds ?? []).reduce((acc, rid) => {
      acc[rid] = new BucketedQueryManager(rid);
      return acc;
    }, {} as Record<string, BucketedQueryManager>)
  );

  /**
   * Every time the runset IDs change we need to update the query states:
   * 1. Remove any query states that aren't active
   * 2. Add any new query states that are active
   * 3. Update any bucket managers that are active
   */
  useEffect(() => {
    const existingBucketManagers = Object.keys(bucketQueryManagerById.current);

    // Remove any bucket managers that aren't referenced, initialize any new ones
    existingBucketManagers.forEach(id => {
      if (!runsetIds.includes(id)) {
        delete bucketQueryManagerById.current[id];
      } else {
        bucketQueryManagerById.current[id] = new BucketedQueryManager(id);
      }
    });

    setQueryState(prevQs => {
      const newState = {...prevQs};

      // remove any query states that aren't active
      Object.keys(newState).forEach(id => {
        if (!runsetIds.includes(id)) {
          delete newState[id];
        }
      });

      // Either preserve the state or add a new one for the active runsets
      runsetIds.forEach(id => {
        newState[id] = prevQs[id] ? prevQs[id] : {...defaultQueryState};

        // any new ids need to spin up a new bucket manager
        if (!('id' in bucketQueryManagerById.current)) {
          bucketQueryManagerById.current[id] = new BucketedQueryManager(id);
        }
      });

      return newState;
    });
  }, [runsetIds]);

  const [userZoomState, setUserZoomState] = useState<Zoom>({});
  const [xDomainQuery, setXDomainQuery] = useState<Range>({
    min: config.xAxisMin ?? null,
    max: config.xAxisMax ?? null,
  });
  const setXDomainQueryChecked = useCallback(
    (xDomainQuery: Range) => {
      setXDomainQuery(curVal =>
        curVal.min === xDomainQuery.min && curVal.max === xDomainQuery.max
          ? curVal
          : xDomainQuery
      );
    },
    [setXDomainQuery]
  );

  const {lastTickedAt} = useSyncedPollingSubscriber('BucketedQueryManager');

  useEffect(() => {
    Object.values(bucketQueryManagerById.current).forEach(bqm => {
      bqm.poll();
    });
  }, [bucketQueryManagerById, lastTickedAt]);

  const value = useMemo(
    () => ({
      bucketQueryManagerById,
      queryStateById,
      setQueryStateById,
      setUserZoomState,
      setXDomainQuery: setXDomainQueryChecked,
      userZoomState,
      xDomainQuery,
    }),
    [
      bucketQueryManagerById,
      queryStateById,
      setQueryStateById,
      setXDomainQueryChecked,
      userZoomState,
      xDomainQuery,
    ]
  );

  return (
    <SharedPanelStateContext.Provider value={value}>
      {children}
    </SharedPanelStateContext.Provider>
  );
};

export const useSharedPanelState = (): SharedPanelStateContextType => {
  const ctx = useContext(SharedPanelStateContext);
  if (ctx == null) {
    throw new Error(
      'useSharedPanelState must be used within a SharedPanelStateContextProvider'
    );
  }
  return ctx;
};
