import {CacheApiCache} from './cacheapi';
import {LongTermCache} from './types';
import {isCacheEnabled} from './util';

const globalCache = new CacheApiCache();

/* Currently this function is just a wrapper around the globalCache.
  If we want to add more caches in the future, or route between OPFS and CacheAPI,
  we can do that here.
*/
export function getCache(): LongTermCache {
  return globalCache;
}

// this is the entry point to get the currently configured cache
export async function getCacheIfEnabled(): Promise<LongTermCache | null> {
  const cache = getCache();
  const isEnabled = isCacheEnabled();
  if (isEnabled) {
    return cache;
  }
  return null;
}
