import {IconAddNew, IconRemove} from '@wandb/weave/components/Icon';
import {Pill} from '@wandb/weave/components/Tag';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import numeral from 'numeral';
import React, {useContext} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Icon, StrictInputProps} from 'semantic-ui-react';

import {Coupon} from '../../../generated/graphql';
import {
  PlanWithPrivileges,
  PrimaryStripePlanInfoWithUnitPrice,
} from '../../../util/accounts/pricing';
import {contactSalesPricing, pricing} from '../../../util/urls';
import {
  formatAsDollarWithCents,
  getCouponDescription,
  getDiscountedMonthlyCostPerSeat,
} from '../AccountSettings/BillingTab/PlanCard/PlanCardComponents';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {TeamsCheckoutModalContext} from './TeamsPlanCheckoutModal';
import * as S from './TeamsPlanTab.styles';

export const PlanSeatPanel = ({
  plan,
}: {
  plan: PrimaryStripePlanInfoWithUnitPrice;
}) => {
  const contextValue = useContext(TeamsCheckoutModalContext);
  if (contextValue == null) {
    return null;
  }
  const {
    seats,
    setSeats,
    yearlyPlan,
    monthlyPlan,
    minSeats,
    isAlreadyTeamsMonthly,
    coupon,
  } = contextValue;
  const yearlyDiscountAmount =
    (seats * (12 * monthlyPlan.unitPrice - yearlyPlan.unitPrice)) / 100;
  return (
    <>
      {isAlreadyTeamsMonthly &&
        (plan.billingInterval === 'year' ? (
          <S.SavingMessageContainer>
            <Icon name="star" className="large" />
            <S.SavingMessage>
              You are saving ${yearlyDiscountAmount} with an annual
              subscription.
            </S.SavingMessage>
          </S.SavingMessageContainer>
        ) : (
          <S.SavingWarningMessageContainer>
            <Icon name="lightbulb outline" className="large" />
            <S.SavingWarningMessage>
              Save ${yearlyDiscountAmount} per year by switching to an annual
              plan.
            </S.SavingWarningMessage>
          </S.SavingWarningMessageContainer>
        ))}
      <S.AddSeatTitle>Add Users</S.AddSeatTitle>
      <SeatControl
        proposedPlan={plan}
        seats={seats}
        minSeats={minSeats}
        setSeats={setSeats}
        coupon={coupon}
      />
    </>
  );
};

export const SeatControl = ({
  proposedPlan,
  seats,
  setSeats,
  minSeats = 1,
  coupon,
}: {
  proposedPlan: PrimaryStripePlanInfoWithUnitPrice;
  seats: number;
  setSeats: (seats: number) => void;
  minSeats?: number;
  coupon?: Coupon | null;
}) => {
  const totalPrice = (proposedPlan.unitPrice * seats) / 100;
  let discountedTotalPrice = null;
  // `coupon` is currently only passed in at the TrialEndPage when purchasing a new yearly Teams plan for the first time.
  // This has not been tested with existing Teams plans when increasing the number of seats - the math may or may not be correct.
  if (coupon != null && proposedPlan.billingInterval === 'year') {
    const discountedMonthlyPricePerSeat = getDiscountedMonthlyCostPerSeat(
      proposedPlan.unitPrice / (12 * 100),
      coupon,
      seats,
      true
    );
    discountedTotalPrice = discountedMonthlyPricePerSeat * seats * 12;
  }
  if (coupon != null && proposedPlan.billingInterval === 'month') {
    const discountedMonthlyPricePerSeat = getDiscountedMonthlyCostPerSeat(
      proposedPlan.unitPrice / 100,
      coupon,
      seats,
      false
    );
    discountedTotalPrice = discountedMonthlyPricePerSeat * seats;
  }
  return (
    <Tailwind>
      <div className="flex flex-col">
        <S.AddSeatBody>
          <S.NumberInputSection>
            <NumberInputWithRange
              min={minSeats}
              max={proposedPlan.maxSeats}
              value={seats}
              setValue={setSeats}
            />
          </S.NumberInputSection>
          <S.AmountSection>
            <S.TotalAmountSection>
              {discountedTotalPrice == null ? (
                <S.TotalAmount>${totalPrice.toLocaleString()}</S.TotalAmount>
              ) : (
                <div className="flex items-end">
                  <div className="mr-[9px] text-2xl text-moon-600 line-through">
                    ${totalPrice.toLocaleString()}
                  </div>
                  <S.TotalAmount>
                    {formatAsDollarWithCents(discountedTotalPrice)}
                  </S.TotalAmount>
                </div>
              )}
              <S.BillingCycle>/{proposedPlan.billingInterval}</S.BillingCycle>
            </S.TotalAmountSection>
          </S.AmountSection>
        </S.AddSeatBody>
        {coupon != null && (
          <div className="mt-1 flex justify-end">
            <Pill label={getCouponDescription(coupon)} color="gold" />
          </div>
        )}
      </div>
      <S.AddSeatMaxWarningWrapper>
        {seats >= proposedPlan.maxSeats ? (
          <S.AddSeatMaxWarning>
            You've reached the maximum number of seats for a teams plan. To add
            more seats,{' '}
            <S.ContactSalesLink href={contactSalesPricing()}>
              contact sales
            </S.ContactSalesLink>
            .
          </S.AddSeatMaxWarning>
        ) : null}
      </S.AddSeatMaxWarningWrapper>
    </Tailwind>
  );
};

export const StruckPlanSeatControl = ({
  proposedPlan,
  struckPlan,
}: {
  proposedPlan: PrimaryStripePlanInfoWithUnitPrice;
  struckPlan: PrimaryStripePlanInfoWithUnitPrice;
}) => {
  const contextValue = useContext(TeamsCheckoutModalContext);
  if (contextValue == null) {
    return null;
  }
  const {seats, setSeats, minSeats} = contextValue;
  const totalPrice = (proposedPlan.unitPrice * seats) / 100;
  const struckPrice = (struckPlan.unitPrice * seats * 12) / 100;
  return (
    <>
      <S.AddSeatBody>
        <S.NumberInputSection>
          <NumberInputWithRange
            min={minSeats}
            max={proposedPlan.maxSeats}
            value={seats}
            setValue={setSeats}
          />
          <S.StruckPlanAnnotation>
            x {struckPlan.unitPrice / 100}/user x 12 mo
          </S.StruckPlanAnnotation>
        </S.NumberInputSection>
        <S.AmountSection>
          <S.StruckAmountSection>
            <S.StruckAmount>${struckPrice.toLocaleString()}</S.StruckAmount>
            <S.BillingCycle>/{proposedPlan.billingInterval}</S.BillingCycle>
          </S.StruckAmountSection>
          <S.TotalAmountSection>
            <S.TotalAmount>${totalPrice.toLocaleString()}</S.TotalAmount>
            <S.BillingCycle>/{proposedPlan.billingInterval}</S.BillingCycle>
          </S.TotalAmountSection>
        </S.AmountSection>
      </S.AddSeatBody>
      <S.AddSeatMaxWarningWrapper>
        {seats >= proposedPlan.maxSeats ? (
          <S.AddSeatMaxWarning>
            You've reached the maximum number of seats for a teams plan. To add
            more seats,{' '}
            <S.ContactSalesLink href={contactSalesPricing()}>
              contact sales
            </S.ContactSalesLink>
            .
          </S.AddSeatMaxWarning>
        ) : null}
      </S.AddSeatMaxWarningWrapper>
    </>
  );
};

export const NumberInputWithRange = ({
  min,
  max,
  value,
  setValue,
}: {
  min: number;
  max: number;
  value: number;
  setValue: (value: number) => void;
}) => {
  const handleChange = (event => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      setValue(newValue);
    }
  }) as StrictInputProps['onChange'];

  const handleDecrement = () => {
    if (value > min) {
      setValue(value - 1);
    }
  };

  const handleIncrement = () => {
    if (value < max) {
      setValue(value + 1);
    }
  };

  return (
    <S.NumberInput
      type="number"
      className="mini compact attached"
      value={value}
      min={min}
      max={max}
      onChange={handleChange}>
      <S.NumberButton
        className="left icon mini attached"
        variant="secondary"
        size="large"
        onClick={handleDecrement}
        disabled={value <= min}>
        <IconRemove />
      </S.NumberButton>
      <input />
      <S.NumberButton
        className="right icon mini attached"
        variant="secondary"
        size="large"
        onClick={handleIncrement}
        disabled={value >= max}>
        <IconAddNew />
      </S.NumberButton>
    </S.NumberInput>
  );
};

export const TeamsPlanDescription = ({plan}: {plan: PlanWithPrivileges}) => {
  const DESCRIPTION_LIST = [
    'Unlimited teams for collaboration',
    'Team-based access controls',
    'Slack & email alerts',
    `Up to ${plan.maxSeats} seats`,
    `Up to ${numeral(plan.defaultPrivileges.computeHours).format('0,00')} ${
      plan.billingInterval === 'year' ? 'annual' : 'monthly'
    } tracked hours`,
    `${plan.defaultPrivileges.storageLimitGB} GB free Storage included`,
    'Priority email & chat support',
  ];
  return (
    <S.DescriptionList>
      {DESCRIPTION_LIST.map((text, i) => (
        <S.DescriptionListItem key={i}>{text}</S.DescriptionListItem>
      ))}
      <S.DescriptionListItem key="view pricing">
        <S.ViewPricingLink href={pricing()}>View pricing</S.ViewPricingLink>
      </S.DescriptionListItem>
    </S.DescriptionList>
  );
};
