import PanelError from '@wandb/weave/common/components/elements/PanelError';
import _ from 'lodash';
import React, {memo, useCallback} from 'react';

import {DEFAULT_LINE_PLOT_MAX_GROUP_RUNS} from '../../components/WorkspaceDrawer/Settings/runLinePlots/maxRunDefaults';
import {prettyXAxisLabel} from '../../util/plotHelpers/axis';
import {
  getPlotFontSize,
  plotFontSizeToPx,
} from '../../util/plotHelpers/plotFontSize';
import {
  filterFullFidelityLines,
  filterLines,
} from '../../util/plotHelpers/plotHelpers';
import {convertScalarSecondsToTimestep} from '../../util/plotHelpers/time';
import {Line} from '../../util/plotHelpers/types';
import {useResizeObserverDebounced} from '../../util/useResizeObserverDebounced';
import {PanelTitle} from '../PanelTitle/PanelTitle';
import {LinePlot} from '../vis/LinePlot';
import {DomainMaybe} from '../vis/types';
import {logHandledError} from './../../services/errors/errorReporting';
import {BarChart} from './BarChart';
import {getTitleFromConfig} from './common';
import {getErrorMessage} from './getGraphError';
import {PlotLayout} from './Layout';
import {LinePlotLegend} from './LinePlotLegend';
import {LinePlotLegendWarning} from './LinePlotLegendWarning';
import {MissingRunBanner} from './MissingRunBanner';
import {usePanelConfigContext} from './PanelConfigContext';
import {usePanelInteractionContext} from './PanelInteractionContext';
import {usePanelTimeContext} from './PanelTimeContext';
import {usePanelZoom} from './PanelZoomContext';
import {SamplingWarning} from './SamplingWarning';
import {RunsLinePlotPanelInnerProps, Zoom} from './types';
import {useLegendVisibility} from './useLegendVisibility';
import {isBarPlot, isHistogramPlot} from './utils/checkTypes';

type GraphProps = RunsLinePlotPanelInnerProps;

const expandStyles: React.CSSProperties = {
  height: '100%',
  width: '100%',
};
const GraphComp: React.FC<GraphProps> = props => {
  // destructure
  const {lines, config, loading, runNameTruncationType} = props;

  // state
  const [isHovered, setIsHovered] = React.useState(false);

  const {
    isResizing,
    height = 1,
    ref: elementRef,
    width = 1,
  } = useResizeObserverDebounced<HTMLDivElement>(1);
  const {isFullFidelity, isSingleRun, excludeOutliers, xAxis, showLegend} =
    usePanelConfigContext();
  const {setTimeFactor} = usePanelTimeContext();
  const {
    handleUserZoomChange,
    xDomainChart: historySpaceXDomain,
    yDomain,
  } = usePanelZoom();
  const {setZooming, zooming} = usePanelInteractionContext();

  const fontSize = getPlotFontSize(config.fontSize ?? 'auto', height);

  // Additional constraints are applied for legends depending on panel width/height and if
  // view is single run
  const isLegendShown = useLegendVisibility(isSingleRun, showLegend, {
    height: height,
    width: width,
  });

  const timestep = lines?.[0]?.timestep;

  // make sure to convert x domain to the correct time type
  const xDomain = React.useMemo(
    () =>
      timestep && historySpaceXDomain != null
        ? ([
            historySpaceXDomain[0] == null
              ? historySpaceXDomain[0]
              : convertScalarSecondsToTimestep(
                  historySpaceXDomain[0],
                  timestep
                ),
            historySpaceXDomain[1] == null
              ? historySpaceXDomain[1]
              : convertScalarSecondsToTimestep(
                  historySpaceXDomain[1],
                  timestep
                ),
          ] as DomainMaybe)
        : historySpaceXDomain != null && xAxis === '_timestamp'
        ? ([
            historySpaceXDomain[0] == null
              ? historySpaceXDomain[0]
              : historySpaceXDomain[0] * 1000,
            historySpaceXDomain[1] == null
              ? historySpaceXDomain[1]
              : historySpaceXDomain[1] * 1000,
          ] as DomainMaybe)
        : historySpaceXDomain,
    [historySpaceXDomain, timestep, xAxis]
  );

  const xScale = config.xLogScale ? 'log' : 'linear';
  const filteredLines: Line[] = React.useMemo(
    () =>
      isFullFidelity
        ? filterFullFidelityLines(lines, xScale)
        : filterLines(lines, xScale),
    [isFullFidelity, lines, xScale]
  );

  // don't include auxiliary lines in the legend, they're "real" for the purposes of
  // chart rendering but not for the purposes of the legend
  const legendLines = React.useMemo(
    () => filteredLines.filter(l => !l.aux),
    [filteredLines]
  );

  // NOTE: remove performs a mutation
  const hiddenLines = React.useMemo(
    () =>
      _.remove(
        filteredLines,
        ({data, nanPoints}) =>
          data.length === 0 && (nanPoints == null || nanPoints.length === 0)
      ),
    [filteredLines]
  );

  const zoomCallback = useCallback(
    (zoom: Zoom) => {
      if (zoom.xAxisMin == null && zoom.xAxisMax == null) {
        setTimeFactor(null);
        setZooming(false);
      } else {
        setTimeFactor(timestep ?? null);
        setZooming(true);
      }
      handleUserZoomChange(zoom);
    },
    [handleUserZoomChange, setTimeFactor, setZooming, timestep]
  );

  // derived values
  const [errorDetail, errorMessage] = getErrorMessage(config, lines, {
    activeZoom:
      Array.isArray(xDomain) && (xDomain[0] != null || xDomain[1] != null),
    zoomCallback,
  });

  const renderBarChart = isBarPlot(props, zooming);
  const renderPanelError = !loading && errorMessage != null;

  React.useEffect(
    () => {
      if (!!errorDetail && !loading) {
        logHandledError(errorDetail as string, {
          extra: {
            metrics: config.metrics?.join(', '),
            xAxis: xAxis ?? '',
          },
        });
      }
    },
    // don't trigger this if the loading changes, ONLY on changes in the message
    // there's the possibility this could thrash if the message changes rapidly
    // from "" to "[some message]" if this is interaction based, but we'll tune
    // if we see this start hammering the logs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorDetail, config.metrics, xAxis]
  );

  const [isDrawing, setIsDrawing] = React.useState(false);

  if (renderBarChart || renderPanelError || (props.hasError && !loading)) {
    return (
      <div
        className="chart"
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        ref={elementRef}>
        <PanelTitle title={getTitleFromConfig(config)} fontSize={fontSize} />
        <div className="chart-content" data-test="chart-content">
          {renderPanelError ? (
            <PanelError
              message={
                props.hasError ? (
                  <div>
                    An unexpected error was detected. Will try again shortly.
                  </div>
                ) : (
                  errorMessage
                )
              }
            />
          ) : (
            <BarChart {...props} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div data-test="chart-content" ref={elementRef} style={expandStyles}>
      <PlotLayout
        fontSize={plotFontSizeToPx.legend[fontSize]}
        handleHover={setIsHovered}
        loading={loading}
        numLines={filteredLines.length}
        Graph={
          isResizing ? (
            <div />
          ) : (
            <LinePlot
              excludeOutliers={excludeOutliers}
              filteredLines={filteredLines}
              fontSize={fontSize}
              isDrawing={isDrawing}
              isHovered={isHovered}
              runNameTruncationType={runNameTruncationType}
              setIsDrawing={setIsDrawing}
              singleRun={isSingleRun}
              svg={props.exportMode}
              timestep={timestep}
              xAxis={prettyXAxisLabel(xAxis, props.lines)}
              xAxisTitle={config.xAxisTitle}
              xDomain={xDomain}
              xScale={xScale}
              yAxis={''}
              yAxisTitle={config.yAxisTitle}
              yDomain={yDomain}
              yScale={config.yLogScale ? 'log' : 'linear'}
              zoomCallback={zoomCallback}
              zooming={zooming}>
              {<MissingRunBanner lines={filteredLines} />}
            </LinePlot>
          )
        }
        legendPosition={config.legendPosition ?? 'north'}
        Legend={
          <LinePlotLegend
            disableRunLinks={props.disableRunLinks}
            expandLegend={props.exportMode}
            fontSize={fontSize}
            hiddenLines={hiddenLines}
            lines={legendLines}
            isVertical={!['east', 'west'].includes(config.legendPosition ?? '')}
            singleRun={isSingleRun}
            disallowSelect={isDrawing}
          />
        }
        showLegend={isLegendShown}
        Title={
          <PanelTitle
            title={getTitleFromConfig(config)}
            fontSize={fontSize}
            disallowSelect={isDrawing}
          />
        }
        Warning={
          <LinePlotLegendWarning
            isHistogram={isHistogramPlot(config, props.keyInfo)}
            isReadOnly={props.readOnly ?? false}
            lines={legendLines}
            loading={loading}
            maxGroupRuns={
              config.groupRunsLimit ?? DEFAULT_LINE_PLOT_MAX_GROUP_RUNS
            }
            totalRuns={props.data.totalRuns}
            disallowSelect={isDrawing}
          />
        }
      />
      <SamplingWarning width={width} style={{zIndex: 1}} />
    </div>
  );
};
const Graph = memo(GraphComp);
Graph.displayName = 'Graph';

// TODO(axel): This wrapper is only here because I'm not confident that some props are guaranteed to exist.
// Find out what's up and kill this wrapper.
const GraphWrapperComp: React.FC<RunsLinePlotPanelInnerProps> = props => {
  if (props.data.histories == null) {
    return <p>No Histories</p>;
  }

  return <Graph {...props} />;
};
export const GraphWrapper = memo(GraphWrapperComp);
GraphWrapper.displayName = 'GraphWrapper';
