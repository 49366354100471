import {useMemo} from 'react';
import {useParams} from 'react-router';

import {useIsInLoadedReportContext} from '../../../../../pages/ReportPage/LoadedReportContext';
import {
  useRampFlagDefaultGorillaOutliers,
  useRampFlagFullFidelityInReports,
} from '../../../../../util/rampFeatureFlags';
import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {
  DerivedPointVisualizationOption,
  LinePlotSettings,
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
} from '../../types';
import {getLinePlotSettingWithDefault} from '../linePlotDefaults';

/**
 * Narrow down the point visualization method options into the two used in the radio UX
 * 'bucketing-gorilla' and 'sampling'
 */
export const getDerivedPointVisualizationOption = (
  p: PointVisualizationOptions | undefined
): DerivedPointVisualizationOption => {
  if (
    p === POINT_VISUALIZATION_OPTIONS.BucketingGorilla ||
    p === POINT_VISUALIZATION_OPTIONS.ForceBucketing
  ) {
    return POINT_VISUALIZATION_OPTIONS.BucketingGorilla;
  }

  if (
    p === POINT_VISUALIZATION_OPTIONS.Sampling ||
    p === POINT_VISUALIZATION_OPTIONS.SamplingByDefault
  ) {
    return POINT_VISUALIZATION_OPTIONS.Sampling;
  }

  return getLinePlotSettingWithDefault(p, 'pointVisualizationMethod');
};

export const useCascadingPointAggregationMethod = (
  parentSettings: LinePlotSettings | undefined,
  panelSettings?: RunsLinePlotConfig
): {
  pointVisualizationMethod: DerivedPointVisualizationOption;
} => {
  const {entityName} = useParams<{entityName?: string}>();
  if (!entityName) {
    // This check should narrow the type of `entityName` from `undefined | string`
    // to `string` in subsequent code.
    throw new RangeError(`Current entity name unexpectedly empty!`);
  }

  const isInReport = useIsInLoadedReportContext();
  const allowsFullFidelityInReports =
    useRampFlagFullFidelityInReports(entityName);
  const isFullFidelityDefaultEligible =
    useRampFlagDefaultGorillaOutliers(entityName);

  return useMemo(
    () =>
      getCascadingPointAggregationMethod(
        {
          allowsFullFidelityInReports,
          isInReport,
          isFullFidelityDefaultEligible,
        },
        parentSettings,
        panelSettings
      ),
    [
      allowsFullFidelityInReports,
      isFullFidelityDefaultEligible,
      isInReport,
      panelSettings,
      parentSettings,
    ]
  );
};

export const getCascadingPointAggregationMethod = (
  config: {
    allowsFullFidelityInReports: boolean;
    isInReport: boolean;
    isFullFidelityDefaultEligible: boolean;
  },
  parentSettings: LinePlotSettings | undefined,
  panelSettings?: RunsLinePlotConfig
) => {
  const pointVisualizationMethodSettings = getCascadingPointVisualizationMethod(
    config,
    parentSettings?.pointVisualizationMethod,
    panelSettings?.pointVisualizationMethod
  );

  return {
    pointVisualizationMethod:
      pointVisualizationMethodSettings.pointVisualizationMethod,
  };
};

export const getCascadingPointVisualizationMethod = (
  config: {
    allowsFullFidelityInReports: boolean;
    isInReport: boolean;
    isFullFidelityDefaultEligible: boolean;
  },
  parentSetting?: LinePlotSettings['pointVisualizationMethod'],
  panelSetting?: RunsLinePlotConfig['pointVisualizationMethod']
): {
  pointVisualizationMethod: DerivedPointVisualizationOption;
  isForced: boolean;
} => {
  // bucketing is not available in reports
  // users w/out access to run outliers will always be in sampling mode
  if (config.isInReport && !config.allowsFullFidelityInReports) {
    return {
      pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.Sampling,
      isForced: false,
    };
  }

  // if user specified a value, this takes precedence
  if (panelSetting != null) {
    return {
      pointVisualizationMethod:
        getDerivedPointVisualizationOption(panelSetting),
      isForced: false,
    };
  }

  /**
   * users who are enrolled to default to bucketing mode will be defaulted IF
   * they have yet to select a preferred point visualization method for the workspace
   */
  if (
    config.isFullFidelityDefaultEligible &&
    (parentSetting === POINT_VISUALIZATION_OPTIONS.SamplingByDefault ||
      parentSetting == null)
  ) {
    if (config.isInReport) {
      return {
        pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.Sampling,
        isForced: false,
      };
    }
    return {
      pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      isForced: true,
    };
  }

  return {
    pointVisualizationMethod: getDerivedPointVisualizationOption(parentSetting),
    isForced: false,
  };
};
