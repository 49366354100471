import {logHandledError} from '../../../services/errors/errorReporting';
import {startPerfTimer} from '../../profiler';
import {checkRampFlagEnableOPFSCache} from '../../rampFeatureFlags';
import {getEntityNameFromPath} from '../util';

export const opfsEnabled = async (entityName?: string): Promise<boolean> => {
  const teamName = entityName || getEntityNameFromPath();

  return await checkRampFlagEnableOPFSCache(teamName);
};

export async function logPerfAndErrors<T>(
  operationName: string,
  fn: () => Promise<T>,
  namespace: string = 'OPFS',
  context?: Record<string, any>
): Promise<T | void> {
  const {endPerfTimer} = startPerfTimer(operationName, context);
  try {
    const result = await fn();
    return result;
  } catch (err: any) {
    // Ignore expected errors
    if (shouldIgnoreError(err)) {
      return;
    }

    logHandledError(`[${namespace}: ${operationName}] Error - ${err}`);
  } finally {
    endPerfTimer();
  }
}

function shouldIgnoreError(err: any): boolean {
  // The user is a dirty hoarder and has no space left for us :'(
  // Or maybe we're the ones who stored too much data :P
  if (errNameIs(err, `QuotaExceededError`)) {
    return true;
  }

  // Ignore errors that occur when the browser doesn't have adequate OPFS support
  if (
    errNameIs(err, `TypeError`) &&
    (errMsgContains(err, `is not a function`) ||
      errMsgContains(err, `navigator.storage`))
  ) {
    return true;
  }

  // Firefox doesn't allow usage of OPFS in private (incognito) mode.
  // There may be other examples of cases where the browser is configured
  // to not allow OPFS usage.
  if (errNameIs(err, `SecurityError`)) {
    return true;
  }

  // Ignore errors that occur when we cannot obtain a lock on the file.
  // This can happen when multiple callers are trying to store a value under the same key.
  // I don't believe it has any negative impact on the user experience, since
  // parallel callers should be writing identical values.
  // TODO: Verify this assumption and maybe prevent simultaneous write attempts
  if (errNameIs(err, `NoModificationAllowedError`)) {
    return true;
  }

  // Transient internal errors. To my knowledge, these are not directly caused by app code.
  // They're probably exacerbated by overly eager access patterns, though.
  if (errNameIs(err, `InvalidStateError`)) {
    return true;
  }

  // Ignore errors that occur when the file doesn't exist
  if (isNotFoundError(err)) {
    return true;
  }

  // Ignore JSON parse errors
  if (errMsgContains(err, `json parse error`)) {
    return true;
  }

  return false;
}

function isNotFoundError(err: any): boolean {
  // Chrome
  if (errMsgContains(err, `a requested file or directory could not be found`)) {
    return true;
  }

  // Firefox and Safari
  if (errNameIs(err, `NotFoundError`)) {
    return true;
  }

  return false;
}

function errNameIs(err: any, name: string): boolean {
  if (!(`name` in err && typeof err.name === `string`)) {
    return false;
  }

  return err.name.toLowerCase() === name.toLowerCase();
}

function errMsgContains(err: any, substr: string): boolean {
  if (!(`message` in err && typeof err.message === `string`)) {
    return false;
  }

  return err.message.toLowerCase().includes(substr.toLowerCase());
}

export async function getOPFSFileTextContent(
  fileName: string
): Promise<string> {
  const root = await navigator.storage.getDirectory();
  const fileHandle = await root.getFileHandle(fileName);
  const file = await fileHandle.getFile();
  const text = await file.text();
  return text;
}

// I may just be being dumb, but I couldn't find a way to clear the OPFS store in my browser.
// This is just here as a convenience when a full wipe is needed.
async function clearOPFS(): Promise<void> {
  try {
    const root = await navigator.storage.getDirectory();
    const promises = [];
    for await (const [name] of root as any) {
      console.log(`Removing ${name} from OPFS...`);
      promises.push(root.removeEntry(name));
    }
    await Promise.all(promises);
    console.log(`Removed ${promises.length} files from OPFS`);
  } catch (err) {
    logHandledError(`Error clearing OPFS cache - ${err}`);
  }
}
(window as any).clearOPFS = clearOPFS;

export async function storeTextFileInOPFS(
  fileName: string,
  textContent: string
): Promise<void> {
  const root = await navigator.storage.getDirectory();
  const draftHandle = await root.getFileHandle(fileName, {create: true});

  // For some reason, TS in CI doesn't think `FileSystemFileHandle.createWritable` exists
  // Let's just cast for now
  const writable = await (draftHandle as any).createWritable();

  await writable.write(textContent);
  await writable.close();
}
