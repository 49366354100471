// This is the main LinePlot used in the workspaces and a key performance hotspot.
// When the user mouses over a LinePlot, we draw a "crosshair" (or "flag") with
// the legend in it, and bold the line that the user is mousing over. To do this
// in real-time, we render the LinePlot in layers. There are three layers:
// - The main layer, which renders all line data (including the currently bolded
//   lines but without bolding them).
// - The bold line layer, which renders just the bolded lines.
// - The crosshair layer, which renders the legend.

import React, {FC, memo, useCallback, useMemo, useState} from 'react';
import useResizeObserver from 'use-resize-observer';

import {useInteractStateWhenOnScreen} from '../../state/interactState/hooks';
import {PlotFontSize} from '../../util/plotHelpers/plotFontSize';
import {convertTimestepToSeconds} from '../../util/plotHelpers/time';
import {type Line, type Timestep} from '../../util/plotHelpers/types';
import {TruncationType} from '../common/TruncateText/TruncateTextTypes';
import {usePanelConfigContext} from '../PanelRunsLinePlot/PanelConfigContext';
import {Zoom} from '../PanelRunsLinePlot/types';
import {
  ExcludeOutliersValues,
  isFullFidelityMode,
} from '../WorkspaceDrawer/Settings/types';
import {makeClampPoint} from './clampPoint';
import {BrushEndHandler, DomainArea} from './highlight';
import * as S from './LinePlot.styles';
import LinePlotCrosshair from './LinePlotCrosshair';
import {LinePlotPlot} from './LinePlotPlot';
import {LinePlotPlotWithHighlighting} from './LinePlotPlotWithHighlighting';
import {PositionedLayer} from './PlotBounds';
import {Domain, DomainMaybe} from './types';
import {useCalculatedDomain} from './useCalculatedDomain';

interface LinePlotProps {
  children?: React.ReactNode;
  excludeOutliers?: ExcludeOutliersValues;
  filteredLines: Line[];
  fontSize?: PlotFontSize;
  isDrawing: boolean;
  isHovered: boolean;
  runNameTruncationType?: TruncationType;
  setIsDrawing: (isDrawing: boolean) => void;
  singleRun: boolean; // is inside a single run page (turns off highlighting)
  svg?: boolean;
  timestep?: Timestep;
  xAxis: string; // Name of the xAxis
  xAxisTitle?: string; // optional string for exactly this xaxis title displayed in graph
  xDomain?: DomainMaybe;
  xScale?: 'linear' | 'log';
  yAxis: string; // Name of the yAxis
  yAxisTitle?: string; // optional string for exactly this yaxis title displayed in graph
  yDomain?: DomainMaybe;
  yScale?: 'linear' | 'log';
  zoomCallback?(zoom: Zoom): void;
  zooming?: boolean; // is zooming happening
}

function isNullDomain(domain: DomainMaybe): domain is Domain {
  return domain[0] === null || domain[1] === null;
}

const LinePlotComp: FC<LinePlotProps> = ({
  excludeOutliers,
  filteredLines: linesForOverlay,
  fontSize = 'small',
  isDrawing,
  isHovered,
  runNameTruncationType,
  setIsDrawing,
  singleRun,
  svg,
  timestep,
  xAxis,
  xAxisTitle,
  xDomain: userXDomain,
  xScale = 'linear',
  yAxis,
  yAxisTitle,
  yDomain: userYDomain,
  yScale = 'linear',
  zoomCallback,
}: LinePlotProps) => {
  // state
  const [hideCrosshair, setHideCrosshair] = useState(false);
  const [lastDrawLocation, setLastDrawLocation] = useState<DomainArea | null>(
    null
  );
  const {height, ref: innerRef, width} = useResizeObserver<HTMLDivElement>();

  // hooks
  const {pointVisualizationMethod} = usePanelConfigContext();
  const [domRef, activeRun] = useInteractStateWhenOnScreen(
    interactState => interactState.highlight['run:name']
  );

  // derived values
  const isHeatmap = linesForOverlay[0]?.type === 'heatmap';
  const [calcXDomain, calcYDomain] = useCalculatedDomain({
    filteredLines: linesForOverlay,
    excludeOutliers,
    isFullFidelityMode: isFullFidelityMode(pointVisualizationMethod),
    isHeatmap,
    userXDomain,
    userYDomain,
  });
  /**
   * TODO: These are cast as Domain but the types are actually DomainMaybe
   */
  const notNullXDomain = calcXDomain as Domain;
  const notNullYDomain = calcYDomain as Domain;

  const crosshairXDomain = useMemo(() => {
    return isHeatmap
      ? [notNullXDomain[0] - 0.5, notNullXDomain[1] + 0.5]
      : notNullXDomain;
  }, [isHeatmap, notNullXDomain]);

  const pointClamper = useMemo(() => {
    const safeYDomain = calcYDomain as Domain;

    return makeClampPoint(
      [safeYDomain[0], safeYDomain[1]],
      [safeYDomain[0] - 100000, safeYDomain[1] + 100000]
    );
  }, [calcYDomain]);

  // After calculating the domain, let's filter the lines a bit again. We want to avoid
  // a bug (https://github.com/wandb/core/issues/2323) where lines aren't drawn when
  // there are extreme outliers present. So let's clamp points to an offset of the domain.
  // We'll still use the unclamped points in the crosshair so as to not obviously present "wrong" data.
  const clampedFilteredLines: Line[] = useMemo(
    function clampLineValues() {
      if (isNullDomain(calcYDomain)) {
        return linesForOverlay;
      }
      // we can do this because we checked for nulls above

      const returnLines = linesForOverlay.map(line => {
        return {
          ...line,
          data: line.data.map(pointClamper),
          // there are no nan points on the aux lines
          nanPoints: (line.nanPoints ?? []).map(pointClamper),
        };
      });
      return returnLines;
    },
    [linesForOverlay, calcYDomain, pointClamper]
  );

  // if user passed in multiple heatmaps only display the first one and nothing else
  const linesOrHeatmap = useMemo(
    function linesOrHeatMapMemo() {
      const heatmap = clampedFilteredLines.find(
        line => line.type === 'heatmap'
      );
      if (heatmap == null) {
        return clampedFilteredLines;
      }
      return [heatmap];
    },
    [clampedFilteredLines]
  );

  const handleCrosshairBrushEnd: BrushEndHandler = useCallback(
    (area, newZoomedYAxis) => {
      setLastDrawLocation(area);

      if (zoomCallback != null) {
        let left = area?.left;
        let right = area?.right;
        if (timestep != null) {
          if (left != null) {
            left = convertTimestepToSeconds(left, timestep);
          }
          if (right != null) {
            right = convertTimestepToSeconds(right, timestep);
          }
        } else if (xAxis === 'Wall Time') {
          left = left == null ? left : left / 1000;
          right = right == null ? right : right / 1000;
        }

        // only pass the area if the zoom callback gives a specific y Zoom range
        // passing undefined will let the chart autoscale to the range of the shown y-axis
        zoomCallback({
          xAxisMin: left,
          xAxisMax: right,
          yAxisMin: newZoomedYAxis ? area?.bottom : undefined,
          yAxisMax: newZoomedYAxis ? area?.top : undefined,
        });
      }
    },
    [timestep, zoomCallback, xAxis]
  );

  const handleCrosshairMouseUp = useCallback(() => {
    setHideCrosshair(false);
  }, []);
  const handleCrosshairMouseDown = useCallback(() => {
    setHideCrosshair(true);
  }, []);

  /**
   * Dimming exists if:
   * - there is a valid run in an active hover state
   * - we are not in a single run view
   * - and the feature flag is flipped
   */
  const isDimmed = !!activeRun;
  const dimFactor = !isDimmed ? undefined : 0.4;

  const dataOutOfBoundsX = dataOutOfBounds(notNullXDomain, userXDomain);
  const dataOutOfBoundsY = dataOutOfBounds(notNullYDomain, userYDomain);

  const linesForHighlighting = React.useMemo(() => {
    const highlightedLines = linesOrHeatmap.filter(l => {
      return l.uniqueId === activeRun || l.name === activeRun;
    });

    return highlightedLines;
  }, [activeRun, linesOrHeatmap]);

  if (linesForOverlay.length === 0) {
    return null;
  }

  if (
    calcXDomain[0] == null ||
    calcXDomain[1] == null ||
    calcYDomain[0] == null ||
    calcYDomain[1] == null
  ) {
    return <S.InvalidDataAlert>Unable to process data.</S.InvalidDataAlert>;
  }

  if (dataOutOfBoundsX || dataOutOfBoundsY) {
    return (
      <S.InvalidDataAlert>
        Data out of bounds. Try adjusting the axis range.
      </S.InvalidDataAlert>
    );
  }

  return (
    <PositionedLayer ref={domRef}>
      <style>
        {`
          /* 
            We don't have direct access to React-vis crosshair box so we have to CSS it 
            These rules keep the overlay from overlapping the vertical bar showing the 
            intersection of the x-axis and the lines
          */
          .rv-crosshair__inner--right {
            left: 12px;
          }

          .rv-crosshair__inner--left {
            right: 12px;
          }
        `}
      </style>
      {/**
       * Note: this used to be wrapped in DelayedRender which was originally intended for a bug where React Vis wouldn't size correctly when siblings were added and removed to the component containing React-Vis (https://github.com/laxels/flex-grow-react-sibling). I believe this was the legend, but I've moved that up a level and have reason to believe that the original conditions requiring DelayedRender no longer exist.
       *
       * IF we get a bug where React Vis isn't sizing correctly in the line-plots please see:
       * https://github.com/wandb/core/pull/18454
       *
       * For performance we render multiple layers. This way we don't have to re-render the entire plot when the highlighted lines change.
       */}

      <PositionedLayer dim={dimFactor} ref={innerRef}>
        {height !== 0 && (
          <LinePlotPlot
            drawing={isDrawing}
            fontSize={fontSize}
            height={height || 200}
            lines={linesOrHeatmap}
            svg={svg}
            width={width || 200}
            xAxis={xAxis}
            xAxisTitle={xAxisTitle}
            xDomain={notNullXDomain}
            xScale={xScale}
            yAxis={yAxis}
            yAxisTitle={yAxisTitle}
            yDomain={notNullYDomain}
            yScale={yScale}
          />
        )}
      </PositionedLayer>

      <PositionedLayer>
        {height !== 0 && (
          <LinePlotPlotWithHighlighting
            drawing={isDrawing}
            fontSize={fontSize}
            height={height || 200}
            isHovered={isHovered}
            lines={linesForHighlighting}
            singleRun={singleRun}
            svg={svg}
            width={width || 200}
            xAxis={xAxis}
            xAxisTitle={xAxisTitle}
            xDomain={notNullXDomain}
            xScale={xScale}
            yAxis={yAxis}
            yAxisTitle={yAxisTitle}
            yDomain={notNullYDomain}
            yScale={yScale}
          />
        )}
      </PositionedLayer>

      <PositionedLayer>
        {height !== 0 && (
          <LinePlotCrosshair
            fontSize={fontSize}
            height={height || 200}
            hideCrosshair={hideCrosshair}
            isDrawing={isDrawing}
            isHovered={isHovered ?? false}
            lastDrawLocation={lastDrawLocation}
            lines={linesForOverlay}
            onBrushEnd={handleCrosshairBrushEnd}
            onMouseDown={handleCrosshairMouseDown}
            onMouseUp={handleCrosshairMouseUp}
            pointClamper={pointClamper}
            runNameTruncationType={runNameTruncationType}
            setIsDrawing={setIsDrawing}
            singleRun={singleRun}
            width={width || 200}
            xAxis={xAxis}
            xDomain={crosshairXDomain}
            xScale={xScale}
            yAxis={yAxis}
            yDomain={notNullYDomain}
            yScale={yScale}
          />
        )}
      </PositionedLayer>
    </PositionedLayer>
  );
};

export const LinePlot = memo(LinePlotComp);

function dataOutOfBounds(calcDomain: Domain, userDomain?: DomainMaybe) {
  if (userDomain == null || calcDomain == null) {
    return false;
  }

  const [boundsMin, boundsMax] = userDomain;
  const [dataMin, dataMax] = calcDomain;

  const boundsMaxSmallerThanDataMin =
    boundsMax != null && dataMin != null && boundsMax < dataMin;
  const boundsMinBiggerThanDataMax =
    boundsMin != null && dataMax != null && boundsMin > dataMax;

  return boundsMaxSmallerThanDataMin || boundsMinBiggerThanDataMax;
}
