import {isFunction} from 'lodash';
import React, {createContext, memo, useContext, useRef} from 'react';

import {useBetaFeatureMakeContextPerfLogging} from '../../util/useBetaFeature';
import {logRender} from './logRender';
import {
  FCC,
  MakeContext,
  ProviderAndHook,
  ProviderProps,
  RenderLogDatapoint,
} from './types';

/**
 * `makeContext` is a small function that streamlines React.Context creation and management,
 * enabling easy-peasy propsless data-sharing between components.
 *
 * It automatically infers types, eliminates the need for ridiculous default values and Provider/Consumer boilerplate,
 * and includes context enhancements like automatic render performance logging and conditional context access.
 // Change false to true to enable console debug output*
 * Basic Usage:
 *
 * // Call makeContext to make a Provider and hook
 * const {Provider: MyFeatureProvider, hook: useMyFeatureContext} = makeContext({
 *   contextType: 'MyFeature', // <-- this is the label you'll see in dev tools and log data.
 *   getContextValue: useMyFeature // <-- context value, or a function to generate the context value. (e.g. react hook, redux/zustand/mobx store, whatever)
 * });
 *
 * // Add your Provider component to the React tree
 * const MyComponent = () => (
 *   <MyFeatureProvider>
 *     <MyComponentLayout />
 *   </MyFeatureProvider>
 * );
 *
 * // Access context in a child component
 * const MyDeeplyNestedComponent = () => {
 *   const {myFeature} = useMyFeatureContext();
 *   // Component logic using myData
 * };
 *
 * @template ContextValue The type of value the context will provide. Inferred from @param getContextValue, only required if getContextValue is not provided.
 * @param {Object} options The options for creating the context.
 * @param {string} options.contextType A label for the context, used in dev tools and logs.
 * @param {ContextValue | (() => ContextValue)} [options.getContextValue] Optional value or function (e.g. a hook or store) that returns the context value.
 * @returns {ProviderAndHook<ContextValue>} An object containing a Provider and two hooks (`hook` for guaranteed context, `hookMaybe` for optional context).
 *
 * @see ./README.md for more info and details!
 *
 */

export const makeContext = <ContextValue,>({
  contextType,
  getContextValue,
}: MakeContext<ContextValue>): ProviderAndHook<ContextValue> => {
  const NewContext = createContext<ContextValue | undefined>(undefined);

  // Create the Provider component
  const NewContextProvider: FCC<ProviderProps<ContextValue>> = memo(
    ({contextValue, contextId, children}) => {
      // If you pass `contextId` as a prop to your provider, it will get appended to the contextType.
      // This is optional, but useful for logging, to differentiate between multiple instances of the same makeContext provider.
      // e.g. <MyPanelProvider contextId="1"> => 'MyPanelProvider-1' in logs
      const contextDisplayName = `${contextType}Context${
        contextId == null ? '' : `-${contextId}`
      }`;
      NewContext.displayName = contextDisplayName;

      // To enable console debug output, enable the 'makeContext perf logging' beta feature in the app (wandb.ai/settings)
      const isMakeContextDebugEnabled = useBetaFeatureMakeContextPerfLogging();

      // If debugging is enabled, start a timer to measure the time it takes to resolve the context value
      let startTime: number | null = null;
      if (isMakeContextDebugEnabled) {
        startTime = performance.now();
      }

      // This array is a log of all renders of this provider, for debugging and performance analysis
      const renderLog = useRef<
        Array<RenderLogDatapoint<ContextValue | undefined>>
      >([]);

      // Here's where we resolve the context value.
      // There are two ways to specify it:
      // 1. Pass in getContextValue to makeContext (preferred method)
      // 2. Pass in contextValue as a prop to the Provider (escape hatch for mini-refactors)
      const resolvedValue =
        contextValue ??
        (isFunction(getContextValue) ? getContextValue() : getContextValue);

      let endTime: number | null = null;
      if (isMakeContextDebugEnabled) {
        endTime = performance.now();
      }

      // Log the render performance data
      if (isMakeContextDebugEnabled && startTime != null && endTime != null) {
        renderLog.current.push({
          contextDisplayName,
          startTime,
          endTime,
        });

        logRender({
          contextDisplayName,
          // contextValue: resolvedValue, // Note: uncommenting this might freeze your tab with a lot of data
          allRenders: renderLog.current,
        });
      }

      if (resolvedValue === undefined) {
        return <>{children}</>;
      }

      return (
        <NewContext.Provider value={resolvedValue}>
          {children}
        </NewContext.Provider>
      );
    }
  );
  NewContextProvider.displayName = `${contextType}Provider`;

  // Create a hook for accessing the context
  // If you use this hook, you are guaranteed to get a value, because the Provider will throw an error if the context is undefined
  const useNewContext = (): ContextValue => {
    const context = useContext(NewContext);
    // Note the === undefined, because we want to allow context to be null
    if (context === undefined) {
      throw new Error(
        `ContextError: use${contextType}Context must be used inside of a <${contextType}Provider>`
      );
    }
    return context;
  };

  // Create a hook for maaaaybe accessing the context
  // If you use this hook, you are not guaranteed to get a value, because the Provider will not throw an error if the context is undefined
  const useNewContextMaybe = (): ContextValue | undefined => {
    return useContext(NewContext);
  };

  // Return the Provider and the hooks. You can destructure these in your component files
  return {
    Provider: NewContextProvider,
    hook: useNewContext,
    hookMaybe: useNewContextMaybe,
  };
};
