import {
  MIN_NETWORK_ELAPSED_TIME_MILLIS,
  NETWORK_EVENT_CONTEXT,
} from './constants';
import {getDefaultPageStats, reportTimedEvent} from './report';

/**
 * The following groups of events have sampling overrides because either:
 * a) they're low frequency events and we need to ingest more for analysis, or
 * b) they're high frequency events and we want to drop more to save money
 */
export const teamPageQueryExperiment: QuerySampleRates = {
  AllTeamProjects: 100, // log only 200 a day, bumping
  AllUserProjects: 100, // log only 200 a day, bumping
  ProjectsByTeam: 100,
  TeamPageProjects: 100, // take 100% of lo-frequency queries needed for comparisons
  TeamPageProjectsTwo: 100, // take 100% of lo-frequency queries needed for comparisons
};

export const pageQueryExperiments: QuerySampleRates = {
  EntityPage: 100, // low hundreds daily @ 30%
  GroupPage: 100, // low hundreds daily @ 30%
  ProfilePage: 100, // low hundreds daily @ 30%
  ProjectPage: 20, // 60k daily, reducing sample rate
  ReportPage: 100, // low hundreds daily @ 30%
  TeamPage: 100, // low hundreds daily @ 30%
};

type QuerySampleRates = Record<string, number>;
export const throttledNetworkEvents: QuerySampleRates = {
  ActiveExperiments: 10, // 20k daily @ 30%
  AvailableFilesQuery: 10, // 33k daily @ 30%
  BucketedRunsDeltaQuery: 100, // log all events to analyze beta
  BucketedRunsStateQuery: 100, // log all events to analyze beta
  HistoryKeys: 20, // 75k daily @ 30%
  RunHistoryQuery: 10, // 200k / 3 days @ 30%
  RunsStateDeltaQuery: 15, // 100k daily @ 30%
  SelectedCount: 10, // 40k daily at 30%
  ServerInfoQuery: 10, // 65k daily @ 30%
  UpsertView2: 15, //
  ViewDiscussionThreads: 10, // 50k daily @ 30%
  Viewer: 100, // ~100 events daily @ 30%
  WandbConfig: 10, // 70k daily @ 30%
};

/**
 * These are all the events that have explicit sampling rates
 */
export const customSampledEvents = {
  ...teamPageQueryExperiment,
  ...pageQueryExperiments,
  ...throttledNetworkEvents,
};

/**
 * Make a calculation based on the operation name and if it has a throttle limit
 * NOTE: the SAMPLE_THRESHOLD is a percentage, so 40 means 40% of events will be logged
 * We heavily sample events by default now that we collect even fast network events
 */
export const SAMPLE_THRESHOLD = 30;
export function dropLogForThrottledOperation(
  operationName: string,
  eventsWithCustomSampling = customSampledEvents,
  randomNum = Math.floor(Math.random() * 100)
) {
  const bespokeSampleRate =
    eventsWithCustomSampling[operationName] ?? SAMPLE_THRESHOLD;
  /**
   * The sample rate is expressed in the % of events that should NOT be dropped
   * Therefore any random number greater than the range 0-SAMPLE_THRESHOLD should be dropped
   * e.g. if the threshold is 40% and a random number will be greater than 40 60% of the time
   */
  if (randomNum > bespokeSampleRate) {
    return true;
  }

  return false;
}

/**
 * Logs stats for GraphQL operations to Datadog
 */
export const reportQueryPerfEvent = (
  {
    elapsedMs,
    startMs,
    operationName,
    variables,
  }: {
    elapsedMs: number;
    startMs: number;
    operationName: string;
    variables: Record<string, unknown>;
  },
  throttleLog: typeof dropLogForThrottledOperation = dropLogForThrottledOperation,
  reportEvent: typeof reportTimedEvent = reportTimedEvent
) => {
  const pageStats = getDefaultPageStats();

  const shouldDropLog = throttleLog(operationName);

  const operationTimesIsBelowThreshold =
    elapsedMs < MIN_NETWORK_ELAPSED_TIME_MILLIS;
  const operationIsTerminallySlow = elapsedMs > 5000; // log all the responses taking 5s+

  if (
    !operationIsTerminallySlow &&
    (operationTimesIsBelowThreshold || shouldDropLog)
  ) {
    return;
  }
  reportEvent({
    elapsedMs,
    absoluteStartTimeMs: startMs - pageStats.startTimeMs,
    name: 'GraphQL: ' + operationName,
    context: NETWORK_EVENT_CONTEXT,
    variables,
  });
};
