import React from 'react';

type Props = {
  title: React.ReactNode;
  subtitle: string | React.ReactElement;
  sideContent?: React.ReactElement;
  children?: React.ReactElement;
};

export const SettingsContent = ({
  title,
  subtitle,
  sideContent,
  children,
}: Props) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="flex h-full flex-col justify-between">
          {title}
          <div className="mt-4 text-sm text-moon-500">{subtitle}</div>
        </div>
        {sideContent}
      </div>
      {children}
    </div>
  );
};
