import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {FC, Fragment, memo} from 'react';
import {useLocation} from 'react-router';

import {AggressiveRedirectEnforcement} from '../../../components/AggressiveRedirectEnforcement';
import {useViewer} from '../../../state/viewer/hooks';
import {isLockablePathname} from '../../../util/userLockdown';
import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
import {useCurrentBannerStates} from '..';
import {
  HasStorageSubscriptionSection,
  StorageUsageBannerContent,
} from './utils';

export type ResourceSubscriptionLimitExceededWarningBannerProps = {
  organizationId: string;
  organizationName: string;
  isOrgAdmin: boolean;
  isBillingAdmin: boolean;
  billingAdminEmail?: string;
  hasStorageSubscription: boolean;
  orgStorageBytesLimitGB: number;
};

export const ResourceSubscriptionLimitExceededWarningBannerGlobalList: FC =
  () => {
    const currentBannerStates = useCurrentBannerStates();
    return (
      <Tailwind>
        <div className="space-y-8">
          {currentBannerStates.map(state => {
            const storageSubscriptionLimitExceededWarningBannerProps =
              state.storageAndEnforcementBannerFlags
                .storageSubscriptionLimitExceededWarningBannerProps;

            return (
              <Fragment key={state.account.name + state.account.accountType}>
                {storageSubscriptionLimitExceededWarningBannerProps && (
                  <ResourceSubscriptionLimitExceededWarningBanner
                    {...storageSubscriptionLimitExceededWarningBannerProps}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </Tailwind>
    );
  };

const ResourceSubscriptionLimitExceededWarningBannerComp: FC<
  ResourceSubscriptionLimitExceededWarningBannerProps
> = ({
  organizationId,
  organizationName,
  isBillingAdmin,
  isOrgAdmin,
  billingAdminEmail,
  hasStorageSubscription,
  orgStorageBytesLimitGB,
}) => {
  const location = useLocation();
  const isLockableLocation = isLockablePathname(location.pathname);
  const viewer = useViewer();

  if (isLockableLocation) {
    return (
      <AggressiveRedirectEnforcement
        analyticsLocation={`storage limit exceeded banner`}
        organizationName={organizationName}
        entityName={viewer?.entity}
      />
    );
  }
  return (
    <Tailwind>
      <AccountStatusBanner
        eventData={{
          location: `storage subscription limit exceeded banner`,
          organizationId,
          resource: 'storage',
        }}>
        <AccountStatusBannerFlexWrapper>
          <div className="text-xs">
            <Icon name="warning" />
          </div>

          <AccountStatusBannerTextWrapper>
            <span>
              <AccountStatusBannerBoldText>
                You have exceeded your {orgStorageBytesLimitGB} GB of free
                storage.
              </AccountStatusBannerBoldText>{' '}
              <span>
                {hasStorageSubscription ? (
                  <HasStorageSubscriptionSection
                    organizationName={organizationName}
                  />
                ) : (
                  <StorageUsageBannerContent
                    statusMsg="Access to your data will be restricted"
                    organizationName={organizationName}
                    billingAdminEmail={billingAdminEmail}
                    isBillingAdmin={isBillingAdmin}
                    isOrgAdmin={isOrgAdmin}
                  />
                )}
              </span>
            </span>
          </AccountStatusBannerTextWrapper>
        </AccountStatusBannerFlexWrapper>
      </AccountStatusBanner>
    </Tailwind>
  );
};

export const ResourceSubscriptionLimitExceededWarningBanner = memo(
  ResourceSubscriptionLimitExceededWarningBannerComp
);
