export type SafePoint = {
  x: number;
  y: number;
};

export type Point = {
  x: number;
  y: PossibleNonFinite;
};

export type AreaPoint = {
  x: number;
  y: PossibleNonFinite;
  y0: PossibleNonFinite;
};
export type SafeAreaPoint = {
  x: number;
  y: number;
  y0: number;
};

export function isAreaPoint<T>(
  point: T extends AreaPoint ? AreaPoint : Point
): point is AreaPoint {
  // @ts-expect-error this is the test
  if (point.y0 === undefined || point.y0 === null) {
    return false;
  }
  return true;
}

export function isSafePoint(point: Point): point is SafePoint {
  return isFinite(Number(point.y));
}

export function isSafeAreaPoint(point: AreaPoint): point is SafeAreaPoint {
  return isFinite(Number(point.y)) && isFinite(Number(point.y0));
}

export type PossibleNonFinite = number | 'Infinity' | 'NaN' | '-Infinity';

export type PointType = 'Last' | 'Avg' | 'Min' | 'Max';
export type HistoryAggKey = `${string}${PointType}`;
type HistoryNaNKey = `${string}HasNaN`;
export type HistoryFallThroughKey = `${string}""`;
export type ValidHistoryKey = HistoryAggKey | HistoryFallThroughKey;
type HistoryRecordKey =
  | 'bucketSize'
  | HistoryAggKey
  | HistoryNaNKey
  | HistoryFallThroughKey;

/**
 * This is the data structure that is returned from the API for full fidelity bucketing. This type maps out:
 * - bucketSize: number (the size of the bucket)
 * - [metric]HasNan: boolean (if the bucket includes a NaN value)
 * - [metric]Avg|Min|Max: number | 'Infinity' | '-Infinity' | 'NaN' (the possible values for a metric)
 * - [metric]"" - a fall through since the API can also return certain values w/out the bucketing suffixes and aggregations (namely system metrics)
 */
export type HistoryRecord = {
  [K in HistoryRecordKey]: K extends HistoryNaNKey
    ? boolean
    : K extends 'bucketSize'
    ? number
    : K extends HistoryAggKey
    ? PossibleNonFinite
    : K extends HistoryFallThroughKey
    ? number
    : never;
};

export type SupplementalMetrics = Record<string, number>;
export type SupplementalMetricsByRun = Record<string, SupplementalMetrics>;
