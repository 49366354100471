import {useDeepMemo} from '@wandb/weave/hookUtils';
import {useEffect, useMemo, useState} from 'react';

import {Query} from '../../../util/queryTypes';
import {useSharedPanelState} from '../../Panel/SharedPanelStateContext';
import {POINT_VISUALIZATION_OPTIONS} from '../../WorkspaceDrawer/Settings/types';
import {Range, runsLinePlotTransformQuery} from '../common';
import {usePanelConfigContext} from '../PanelConfigContext';
import {RunsLinePlotConfig} from '../types';
import {BucketedQueryState} from './bucketedQueryManager';

const usePanelQuery = (
  pageQuery: Query,
  config: RunsLinePlotConfig,
  queryStepRange: Range
) => {
  const {limit: numRuns, parsedExpressions} = usePanelConfigContext();
  const transformed = runsLinePlotTransformQuery({
    query: pageQuery,
    runsLinePlotConfig: config,
    xStepRange: queryStepRange,
    parsedExpressions,
    defaultMaxRuns: numRuns,
    isFullFidelity: true,
  });

  return useDeepMemo(transformed);
};

export function useBucketedData(
  config: RunsLinePlotConfig,
  pageQuery: Query,
  queryStepRange: Range,
  nBuckets: number
) {
  const panelQuery = usePanelQuery(pageQuery, config, queryStepRange);
  const {bucketQueryManagerById} = useSharedPanelState();

  const [bucketedDataById, setBucketedDataById] = useState<
    Record<string, BucketedQueryState>
  >({});

  useEffect(() => {
    panelQuery.queries.forEach(q => {
      const handlerDataChange = (state: BucketedQueryState) => {
        setBucketedDataById(cur => ({...cur, [q.id]: state}));
      };

      bucketQueryManagerById.current[q.id].registerRequest({
        handler: handlerDataChange,
        nBuckets,
        runsDataQuery: panelQuery,
        singleQuery: q,
      });

      return () => {
        bucketQueryManagerById.current[q.id].unregisterRequest(
          handlerDataChange
        );
      };
    });
  }, [nBuckets, panelQuery, bucketQueryManagerById]);

  const bucketedDataMemo = useMemo(() => {
    return {
      _dataType: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      entityName: pageQuery.entityName,
      histories: {
        data: Object.values(bucketedDataById).flatMap(v => {
          try {
            const result = Object.values(v.data?.runsById ?? {}).filter(
              r => !!r
            );
            return result;
          } catch (e) {
            console.error(e);
            return [];
          }
        }),
      },
      initialLoading: false,
      loadMore: () => {},
      projectName: pageQuery.projectName,
      totalRuns: Object.values(bucketedDataById).reduce((acc, v) => {
        return acc + v.totalRuns;
      }, 0),
    };
  }, [bucketedDataById, pageQuery.entityName, pageQuery.projectName]);

  const {error, loading} = useMemo(() => {
    return {
      error: Object.values(bucketedDataById).find(v => v.error),
      loading: Object.values(bucketedDataById).some(v => v.loading),
    };
  }, [bucketedDataById]);

  if (error) {
    console.error(error);
  }

  return {
    data: bucketedDataMemo,
    error,
    loading,
  };
}
