import {useMemo} from 'react';

import {isFullFidelityMode, LinePlotSettings} from '../types';
import {getCascadingExcludeOutliers} from './utils/getCascadingExcludeOutliers';
import {useCascadingPointAggregationMethod} from './utils/getCascadingPointAggregation';
import {getCascadingShowLegend} from './utils/getCascadingShowLegend';
import {getCascadingSmoothingSettings} from './utils/getCascadingSmoothingSettings';
import {getCascadingXAxisSettings} from './utils/getCascadingXAxisSettings';
import {getCascadingYAxisSettings} from './utils/getCascadingYAxisSettings';

export const useCascadingLinePlotSettings = (
  workspaceLinePlotSettings?: LinePlotSettings,
  sectionLinePlotSettings?: LinePlotSettings
): LinePlotSettings => {
  // This feature is still under development, so we can't directly use the value in workspaceSettings. We need to check for additional values.
  // Note: once feature is fully released on prod, we can remove this hook
  const {pointVisualizationMethod} = useCascadingPointAggregationMethod(
    workspaceLinePlotSettings
  );

  // this should map `supressLegends` to `showLegend` because that's the field used in panel configs
  const cascadingLinePlotSettings = useMemo(
    () => ({
      ...getCascadingXAxisSettings(
        workspaceLinePlotSettings,
        sectionLinePlotSettings
      ),
      ...getCascadingSmoothingSettings(
        workspaceLinePlotSettings,
        sectionLinePlotSettings
      ),
      ...getCascadingYAxisSettings(
        workspaceLinePlotSettings,
        sectionLinePlotSettings
      ),

      // Below do not have section setting, so inherit workspace value
      colorRunNames: workspaceLinePlotSettings?.colorRunNames,
      displayFullRunName: workspaceLinePlotSettings?.displayFullRunName,
      highlightedCompanionRunOnly:
        workspaceLinePlotSettings?.highlightedCompanionRunOnly,
      excludeOutliers: getCascadingExcludeOutliers(
        isFullFidelityMode(pointVisualizationMethod),
        workspaceLinePlotSettings ?? {},
        sectionLinePlotSettings ?? {}
      ),
      pointVisualizationMethod,
      tooltipNumberOfRuns: workspaceLinePlotSettings?.tooltipNumberOfRuns,
      useRunsTableGroupingInPanels:
        workspaceLinePlotSettings?.useRunsTableGroupingInPanels,

      // These settings are not named the same at workspace and panel level, so map to the field used at panel level for consistency
      showLegend: getCascadingShowLegend({
        suppressLegends: workspaceLinePlotSettings?.suppressLegends,
      }),
      limit: workspaceLinePlotSettings?.maxRuns,
    }),
    [
      pointVisualizationMethod,
      sectionLinePlotSettings,
      workspaceLinePlotSettings,
    ]
  );

  return cascadingLinePlotSettings;
};
