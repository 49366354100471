import * as Tabs from '@wandb/weave/components/Tabs';
import React from 'react';

import {UsageType} from '../../generated/graphql';
import {UsageTabURLPart} from '../../routes/paths';
import {useHistoricUsageChartContext} from './HistoricUsageChartContextProvider';
import {HistoricUsageTabContent} from './HistoricUsageTabContent';

type UsageTabSection = {
  label: string;
  urlPart: string;
};

export const TABS: Partial<Record<UsageType, UsageTabSection>> = {
  [UsageType.Storage]: {
    label: 'Storage',
    urlPart: UsageTabURLPart.STORAGE,
  },
  [UsageType.TrackedHours]: {
    label: 'Tracked hours',
    urlPart: UsageTabURLPart.TRACKED_HOURS,
  },
  [UsageType.Weave]: {
    label: 'Weave',
    urlPart: UsageTabURLPart.WEAVE,
  },
};

export const HistoricUsageContent = () => {
  const {usageType} = useHistoricUsageChartContext();

  const tabs = Object.keys(TABS) as UsageType[];
  return (
    <div className="flex flex-col">
      <div className="mb-16 mt-2 text-2xl/10 font-semibold text-moon-950">
        Usage
      </div>
      <Tabs.Root value={usageType}>
        <Tabs.List>
          {tabs.map(tab => (
            <Tabs.LinkedTrigger
              data-test={`tab-${tab}`}
              key={tab}
              value={tab}
              to={TABS[tab]?.urlPart ?? ''}>
              {TABS[tab]?.label}
            </Tabs.LinkedTrigger>
          ))}
        </Tabs.List>
      </Tabs.Root>
      <div className="mt-24">
        <HistoricUsageTabContent />
      </div>
    </div>
  );
};
