import {useEffect} from 'react';

import {useSelector} from '../state/hooks';
import {useViewer} from '../state/viewer/hooks';
import {getViewerLoading} from '../state/viewer/selectors';
import {init as datadogInit} from '../util/datadog';

export const useConsentAwareAnalyticsInjection = () => {
  const viewerIsLoading = useSelector(getViewerLoading);
  const viewer = useViewer();
  useEffect(() => {
    if (viewerIsLoading) {
      return;
    }
    const injector = (window as any).WBAnalyticsInjector;
    if (injector) {
      const authenticated = !!viewer?.username;
      try {
        injector.initializeTrackingScripts(authenticated);
        datadogInit();
      } catch (e) {
        // console.error('Failed to inject analytics', e);
      }
    }
  }, [viewer, viewerIsLoading]);
};
