let _hasViewEverBeenBackgrounded = document.hidden;

export function hasViewEverBeenBackgrounded() {
  return _hasViewEverBeenBackgrounded;
}

export function resetViewBackgrounded() {
  _hasViewEverBeenBackgrounded = document.hidden;
}

export function setViewBackgrounded() {
  _hasViewEverBeenBackgrounded = true;
}

function handleVisibilityChange() {
  if (document.hidden) {
    setViewBackgrounded();
  }
}

if (typeof document !== 'undefined') {
  document.addEventListener('visibilitychange', handleVisibilityChange);
}
