import {Button} from '@wandb/weave/components/Button';
import {Checkbox} from '@wandb/weave/components/Checkbox';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import classNames from 'classnames';
import React from 'react';
import {useParams} from 'react-router';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Dropdown} from 'semantic-ui-react';

import {
  displayKey,
  getDefaultAbsoluteDateValue,
  getKeyAllowedOps,
} from '../../util/filters';
import {EMPTY_FILTER_VALUE} from '../../util/filtersDefaults';
import {
  FilterKey,
  FilterMeta,
  IndividualFilter,
  IndividualOp,
} from '../../util/filterTypes';
import * as Run from '../../util/runs';
import * as RunTypes from '../../util/runTypes';
import {FilterTagInfiniteDropdown} from '../InfiniteScrollDropdown/FilterTagComponents';
import {getCurrentSelections} from '../InfiniteScrollDropdown/utils';
import {useEditableFilterContext} from './FilterContext';
import {FilterKeySelectorCreatorProps} from './FilterKeySelector';
import {FilterValueSelectorCreatorProps} from './FilterValueSelector';

const OperatorUserLabels = new Map<IndividualOp, string>([
  ['=', '='],
  ['!=', '\u2260'],
  ['>=', '\u2265'],
  ['<=', '\u2264'],
  ['IN', 'IN'],
  ['NIN', 'NOT IN'],
  ['WITHINSECONDS', 'within last'],
]);

interface FilterEditableProps {
  filter: IndividualFilter;
  filterIndex: number;
  justAdded: boolean;

  setFilter(filter: IndividualFilter): void;
  setFilterOp(op: IndividualOp): void;
  setFilterValue(value: RunTypes.Value): void;
  setFilterMultiValue(value: RunTypes.Value[]): void;
  setFilterMeta(meta: FilterMeta): void;
  setFilterDisabled(disabled: boolean): void;
  deleteFilter(): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;
  filterValueSelector(
    props: FilterValueSelectorCreatorProps<FilterKey>
  ): React.ReactNode;
}

const getOperatorDisplayList = (filterKey: FilterKey) =>
  getKeyAllowedOps(filterKey).map(op => {
    const opLabel = OperatorUserLabels.get(op);
    if (!opLabel) {
      throw new Error('OperatorUserLabels missing op: ' + op);
    }
    const isSymbol = opLabel.length === 1;
    return {
      text: isSymbol ? <span className="symbol">{opLabel}</span> : opLabel,
      value: op,
    };
  });

export const FilterEditable = ({
  filter,
  justAdded,
  setFilter,
  setFilterOp,
  setFilterDisabled,
  deleteFilter,
  filterKeySelector,
  filterValueSelector,
  filterIndex,
}: FilterEditableProps) => {
  const operatorDisplayList = getOperatorDisplayList(filter.key);
  const {entityName, projectName} = useParams<{
    entityName: string;
    projectName: string;
  }>();
  const [{filter: contextFilter, isMultiValueFilter}, filterActions] =
    useEditableFilterContext(filterIndex);
  // @ts-ignore because this feature is operating on an extreme subsection of the inherited types and those types be nasty
  const selections = getCurrentSelections(contextFilter);
  // This supports two actions based on the kind of filter:
  const toggleFilter = React.useCallback(
    (newSelection: string) => {
      const selectionSet = new Set(selections);

      // Individual filters will replace that item with the new item
      if (!isMultiValueFilter) {
        if (selectionSet.has(newSelection)) {
          filterActions.setFilterValueIndividual('');
        } else {
          filterActions.setFilterValueIndividual(newSelection);
        }
      }

      // the multi-value filter will add or subtract based on if it's already included
      else {
        if (selectionSet.has(newSelection)) {
          selectionSet.delete(newSelection);
        } else {
          selectionSet.add(newSelection);
        }
        filterActions.setFilterValueMulti([...selectionSet]);
      }
    },
    // eslint-disable-next-line
    [contextFilter, filterActions, selections]
  );
  /**
   * The general idea here is that I want to hijack the rendering under the following conditions:
   * 1. It's a tag filter
   * 2. It's an org in the feature flag (we're rolling this out to OpenAI before it goes wider)
   *
   * So this is basically a copy/paste job of the default rendering code with some slight modifications.
   *
   * The CSS for the regular filter layout is pretty gross, so this is a hack to create an identical sized layout without having to muck about with all the spacing complexity affected by components. Should we refactor the other layout to use something nice and clean like CSS Grid where spacing between the columns is a function of the layout? Yes. Should I avoid expanding the scope of this patch to do that? Also yes.
   *
   * It also gives me unique instances of all the subcomponents so I can monkeypatch them as much as I desire to avoid creating regression risk by modifying functionality higher up
   */
  if (filter.key.section === 'tags') {
    // the existing prod layout flexes the grid based on the value of the
    // op selector, we'll fix this later
    const classes = classNames(
      'group relative grid gap-x-8 items-start',
      'grid-cols-[14px_224px_100px_400px_32px]'
    );

    /**
     * The <style> tag here is also SO GROSS but I needed a way to selectively overwrite the CSS coming in by default so that I could dump the margins for Grid w/out having the refactor the original layout.
     */
    return (
      <Tailwind>
        <div className={classes} id="custom-tag-filter">
          <style>
            {`#custom-tag-filter .filter-list__key {
              margin-left: 0px;
            }
            #custom-tag-filter .filter-list__operation {
              margin-left:0px;
            }
            #custom-tag-filter [data-test="filter-toggle"] {
              margin-top: 14px;
            }
            #custom-tag-filter [data-test="filter-delete"] {
              margin-top: 8px;
            }
            `}
          </style>
          <Checkbox
            size="small"
            data-test="filter-toggle"
            checked={!filter.disabled}
            onCheckedChange={() => filterActions.disableFilter()}
          />
          {
            // we are hardcoding the keyValue and storedKey to keep the selector from losing context
            // when I clear out the values: https://weightsandbiases.slack.com/archives/C041GF3LB1Q/p1692367577594179
            filterKeySelector({
              focusOnMount: justAdded,
              keyValue: 'tags:-',
              storedKey: 'tags:-',
              onValidSelection: keyString => {
                // when the key is changed we disable the filter automatically and default it
                const filterKey = Run.keyFromString(keyString);
                if (filterKey != null) {
                  setFilter({
                    key: filterKey,
                    op: '=',
                    value: true,
                    disabled: true,
                  });
                }
              },
            })
          }
          <Dropdown
            className="filter-dropdown filter-list__operation"
            data-test="filter-operation"
            options={[
              {text: 'is', value: '='},
              {text: 'is not', value: '!='},
              {text: 'in', value: 'IN'},
              {text: 'not in', value: 'NIN'},
            ]}
            selectOnNavigation={false}
            value={filter.op}
            onChange={(e, {value}) => {
              // @ts-ignore
              filterActions.changeFilterOperation(value);
            }}
          />
          <FilterTagInfiniteDropdown
            entityName={entityName}
            projectName={projectName}
            isMultiSelect={isMultiValueFilter}
            // @ts-ignore safe, but revisit to figure out why overlap isn't happening correctly
            selections={getCurrentSelections(filter)}
            toggleSelection={toggleFilter}
          />
          <Button
            variant="quiet"
            icon="close"
            data-test="filter-delete"
            onClick={filterActions.deleteFilter}
            aria-label="Delete filter"
          />
        </div>
      </Tailwind>
    );
  }

  return (
    <Tailwind>
      <div data-test="filter-item" className="flex items-center">
        <Checkbox
          size="small"
          data-test="filter-toggle"
          checked={!filter.disabled}
          onCheckedChange={() => {
            // Only toggle if all fields are set.
            const newActive = !filter.disabled;
            setFilterDisabled(newActive);
          }}
        />
        {filterKeySelector({
          focusOnMount: justAdded,
          keyValue: displayKey(filter.key),
          storedKey: displayKey(filter.key),
          onValidSelection: keyString => {
            const filterKey = Run.keyFromString(keyString);
            if (filterKey != null) {
              if (
                filterKey.section === 'run' &&
                filterKey.name === 'createdAt'
              ) {
                setFilter({
                  key: filterKey,
                  op: '<=',
                  value: getDefaultAbsoluteDateValue(),
                  disabled: false,
                });
              } else if (filterKey.section === 'tags') {
                setFilter({
                  key: filterKey,
                  op: '=',
                  value: true,
                  disabled: true,
                });
              } else {
                setFilter({
                  key: filterKey,
                  op: '=',
                  value: EMPTY_FILTER_VALUE,
                  disabled: true,
                });
              }
            }
          },
        })}
        <Dropdown
          className="filter-dropdown filter-list__operation"
          data-test="filter-operation"
          options={operatorDisplayList}
          placeholder={'operator'}
          selectOnNavigation={false}
          value={filter.op}
          onChange={(e, {value}) => {
            setFilterOp(value as IndividualOp);
          }}
        />
        {filterValueSelector({
          filter,
          setFilter,
        })}
        <Button
          className="ml-8"
          variant="quiet"
          icon="close"
          data-test="filter-delete"
          onClick={deleteFilter}
          aria-label="Delete filter"
        />
      </div>
    </Tailwind>
  );
};

export default React.memo(FilterEditable);
