import {TEAL_600} from '@wandb/weave/common/css/color.styles';
import {Link} from '@wandb/weave/common/util/links';
import {Button, ButtonVariant} from '@wandb/weave/components';
import {IconCheckmarkCircle} from '@wandb/weave/components/Icon';
import {Pill} from '@wandb/weave/components/Tag';
import {maybePluralize} from '@wandb/weave/core/util/string';
import classNames from 'classnames';
import React from 'react';
import {twMerge} from 'tailwind-merge';

import {SkeletonWrapper} from '../../../../../components/Skeleton/Skeleton';
import {
  PrimaryPlanCardV3OrgInfoQuery,
  StoragePlanCardV2OrgInfoQuery,
  TrialEndCouponInfoQuery,
  UsageType,
} from '../../../../../generated/graphql';
import {ACADEMIC_APPLICATION_PATH} from '../../../../../routes/paths';
import {showZendeskChat} from '../../../../../util/analytics';
import {DateFormat, format} from '../../../../../util/date';
import {navigateTo} from '../../../../../util/history';
import {
  accountSettingsMembersTab,
  accountSettingsUsagePage,
  contactSalesPricing,
  historicUsageTab,
  trialEndPage,
} from '../../../../../util/urls';
import {TABS} from '../../../../HistoricUsage/HistoricUsage';

export type PlanCardOrganization = NonNullable<
  PrimaryPlanCardV3OrgInfoQuery['organization']
>;
export type PlanCardOrganizationSubscription =
  PlanCardOrganization['subscriptions'][0];

export const METER_STYLE = 'mr-16 w-1/2 last:mr-0';
export const METER_SECTION_STYLE =
  'mb-16 flex flex-col rounded-lg border border-moon-250 px-24 pb-24 pt-16';
export const PLAN_CARD_STYLE = twMerge(
  METER_SECTION_STYLE,
  'px-16 pt-12 pb-18'
); // use the same styling as the meter component except with smaller paddings
const LINK_STYLE = 'text-teal-550 hover:text-teal-600';

export const PlanCard = ({children}: {children?: React.ReactNode}) => (
  <div className={classNames(METER_STYLE, PLAN_CARD_STYLE)}>{children}</div>
);

export const LoadingPlanCard = () => (
  <div className={classNames(METER_STYLE)}>
    <SkeletonWrapper>
      <div className="h-[200px] w-full rounded-lg bg-moon-250" />
    </SkeletonWrapper>
  </div>
);

export const PlanCardHeader = ({
  children,
  sideText,
}: {
  children?: React.ReactNode;
  sideText?: string;
}) => (
  <div className="flex flex-initial">
    <div className="flex-auto items-center gap-x-4">{children}</div>
    <div className="flex-initial text-sm text-moon-500">{sideText}</div>
  </div>
);

export const PlanCardHeaderText = ({
  children,
}: {
  children?: React.ReactNode;
}) => <div className="text-base font-semibold text-moon-750">{children}</div>;

export const PlanCardBody = ({children}: {children?: React.ReactNode}) => (
  <div className="flex-auto text-sm">{children}</div>
);

export const CheckmarkFeatureItem = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <div className="my-8 flex gap-x-10 text-sm">
    <div className="h-18 w-18">
      <IconCheckmarkCircle color={TEAL_600} />
    </div>
    {children}
  </div>
);

export const SubtitleTextSection = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <div className="mt-4 text-sm font-semibold text-moon-500">{children}</div>
);

export const SubtitleBoldText = ({children}: {children?: React.ReactNode}) => (
  <span className="text-sm text-moon-800">{children}</span>
);

export function getDiscountedMonthlyCostPerSeat(
  monthlyCost: number,
  coupon: NonNullable<PlanCardOrganizationSubscription['discount']>['coupon'],
  numSeats: number,
  isBilledAnnually?: boolean
) {
  let discountedMonthlyCostPerSeat = monthlyCost;
  if (coupon.amountOff > 0) {
    const amountOff = coupon.amountOff / 100; // Stripe API returns cents for USD // $100
    const numMonths = isBilledAnnually ? 12 : 1;
    const totalCost = monthlyCost * numMonths * numSeats;
    const discountedTotalCost = totalCost - amountOff;
    const discountedMonthlyCost = discountedTotalCost / numMonths;
    discountedMonthlyCostPerSeat = discountedMonthlyCost / numSeats;
  } else {
    // Stripe API claims that either amountOff or percentOff will be set, but not both.
    discountedMonthlyCostPerSeat = monthlyCost * (1 - coupon.percentOff / 100);
  }
  return Math.max(0, discountedMonthlyCostPerSeat); // cost should never be negative
}

function getDiscountDescription(
  discount: NonNullable<PlanCardOrganizationSubscription['discount']>,
  isBilledAnnually?: boolean
) {
  const duration =
    new Date(discount.end).getTime() > 0 // Stripe API returns 0 for non-recurring coupons
      ? `until ${format(
          new Date(discount.end),
          DateFormat.MONTH_DAY_YEAR_SLASHES,
          'UTC'
        )}`
      : discount.coupon.duration;
  if (discount.coupon.amountOff > 0) {
    return `${formatAsDollarWithCents(
      discount.coupon.amountOff / 100 // Stripe returns cents for USD
    )} off ${isBilledAnnually ? 'annually' : 'monthly'} ${duration}`;
  }
  // Stripe API claims that either amountOff or percentOff will be set, but not both.
  return `${discount.coupon.percentOff}% off ${duration}`;
}

export function getCouponDescription(
  coupon: NonNullable<TrialEndCouponInfoQuery['coupon']>
) {
  const duration =
    coupon.duration === 'repeating' // See options here: https://docs.stripe.com/api/coupons/object?lang=go#coupon_object-duration
      ? `for ${maybePluralize(coupon.durationInMonths, 'month')}`
      : coupon.duration;
  if (coupon.amountOff > 0) {
    return `${formatAsDollarWithCents(
      coupon.amountOff / 100 // Stripe returns cents for USD
    )} off annually ${duration}`; // we only sell the annual plan on the trial end page
  }
  // Stripe API claims that either amountOff or percentOff will be set, but not both.
  return `${coupon.percentOff}% off ${duration}`;
}

export const MonthlyCost = ({
  monthlyCost,
  isBilledAnnually,
  discount,
  numSeats,
}: {
  monthlyCost: number;
  isBilledAnnually?: boolean;
  discount?: PlanCardOrganizationSubscription['discount'];
  numSeats?: number;
}) => {
  const hasCoupon = discount?.coupon != null;
  const discountedMonthlyCost =
    discount != null && numSeats != null
      ? getDiscountedMonthlyCostPerSeat(
          monthlyCost,
          discount.coupon,
          numSeats,
          isBilledAnnually
        )
      : null;
  return (
    <SubtitleTextSection>
      <div className="flex">
        <SubtitleBoldText>
          <span className={hasCoupon ? 'line-through' : ''}>
            {formatAsDollarWithCents(monthlyCost)}
          </span>
          {discountedMonthlyCost != null && (
            <span> {formatAsDollarWithCents(discountedMonthlyCost)} </span>
          )}
        </SubtitleBoldText>{' '}
        <div className="ml-4"> per user per month</div>
        {isBilledAnnually === true ? (
          <div className="ml-4"> billed annually</div>
        ) : (
          ''
        )}
        {hasCoupon && (
          <Pill
            label={getDiscountDescription(discount, isBilledAnnually)}
            color="gold"
            className="ml-4"
          />
        )}
      </div>
    </SubtitleTextSection>
  );
};

export function getFormattedBillingDate(currentPeriodEnd: Date): string {
  return format(currentPeriodEnd, DateFormat.SHORT_MONTH_DAYTH_YEAR, 'UTC');
}

export const formatAsDollarWithCents = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const formatAsDollar = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
};

export const formatAsOverThousandNumber = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
  });

  return formatter.format(value);
};

export const ChoosePlanButton = ({
  children,
  variant,
}: {
  children?: React.ReactElement | string;
  variant?: ButtonVariant;
}) => {
  return (
    <Button
      variant={variant}
      onClick={() => navigateTo({pathname: trialEndPage()})}>
      {children}
    </Button>
  );
};

export const ContactSalesButton = ({
  variant,
  children,
}: {
  variant?: ButtonVariant;
  children?: React.ReactElement | string;
}) => {
  return (
    <Button
      onClick={() => {
        // This is an external URL, so we need it to be unprefixed
        // eslint-disable-next-line wandb/no-unprefixed-urls
        window.open(contactSalesPricing());
      }}
      variant={variant}>
      {children}
    </Button>
  );
};

export const TalkToSupportButton = ({
  variant,
  children,
}: {
  variant?: ButtonVariant;
  children?: React.ReactElement | string;
}) => {
  return (
    <Button
      variant={variant}
      onClick={() => {
        (window as any).openWidget?.();
        showZendeskChat();
      }}
      icon="headset">
      {children}
    </Button>
  );
};

export type StoragePlanOrganization = NonNullable<
  StoragePlanCardV2OrgInfoQuery['organization']
>;
export type StoragePlanOrganizationSubscription =
  StoragePlanOrganization['subscriptions'][0];

export const ManageStorageButton = ({
  organization,
  children,
  variant,
  showIcon = true,
}: {
  organization: Pick<StoragePlanOrganization, 'name'>;
  children?: React.ReactElement | string;
  variant?: ButtonVariant;
  showIcon?: boolean;
}) => {
  const pathname = accountSettingsUsagePage(organization.name);
  return (
    <Button
      variant={variant}
      onClick={() => navigateTo({pathname})}
      icon={showIcon ? 'database-artifacts' : undefined}>
      {children}
    </Button>
  );
};

export const PlanCardButtonRow = ({children}: {children?: React.ReactNode}) => (
  <div className="mt-12 flex flex-initial gap-x-12">{children}</div>
);

export const getSeatUsageText = (
  organization: PlanCardOrganization,
  subscription: PlanCardOrganizationSubscription
) => {
  const usedSeats = organization.usedSeats;
  const maxSeats = subscription.seats;
  return `${usedSeats} out of ${maxSeats} user seats`;
};

export const ManageUsersLink = ({
  organization,
}: {
  organization: PlanCardOrganization;
}) => {
  const url = accountSettingsMembersTab(organization.name);
  return (
    <Link className={LINK_STYLE} to={url}>
      Manage users
    </Link>
  );
};

export const ViewUsageLink = ({
  organization,
}: {
  organization: PlanCardOrganization;
}) => {
  const url = accountSettingsUsagePage(organization.name);
  return (
    <Link className={LINK_STYLE} to={url}>
      View usage
    </Link>
  );
};

export const ViewHistoricUsageLink = ({
  organization,
  usageType,
}: {
  organization: PlanCardOrganization;
  usageType: UsageType;
}) => {
  const url = historicUsageTab(
    organization.name,
    TABS[usageType]?.urlPart ?? ''
  );
  return (
    <Link className={LINK_STYLE} to={url}>
      View usage
    </Link>
  );
};

export const UpgradePlanLink = () => (
  <Link className={classNames('font-semibold', LINK_STYLE)} to={trialEndPage()}>
    Upgrade your plan
  </Link>
);

export const ApplyForAcademicLink = () => (
  <Link
    className={classNames('font-semibold', LINK_STYLE)}
    to={ACADEMIC_APPLICATION_PATH}>
    apply for an academic scholarship
  </Link>
);

export const OrganizationExceededStorageLimitSection = ({
  storageLimitGB,
}: {
  storageLimitGB: number;
}) => (
  <div>
    <span className="font-semibold">
      Your organization has exceeded its {storageLimitGB} GB free storage.
    </span>{' '}
    <UpgradePlanLink /> or free up storage. Access to your data will be
    restricted.
  </div>
);
