import {useMemo} from 'react';
import {useParams} from 'react-router';

import {useRampFlagNonMonotonicXAxis} from '../../../util/rampFeatureFlags';
import {
  DerivedPointVisualizationOption,
  isBucketingOption,
} from '../../WorkspaceDrawer/Settings/types';
import {RunsLinePlotConfig} from '../types';
import {configSupportsBucketing} from './configSupportsBucketing';

/**
 * Line Aggregations are going to be used to determine the primary line. This will changing the querying under the hood as 'AVERAGE' and 'MEAN' will be bucketing queries.
 */
export type LineAggregations =
  | 'MEAN' // this is the mean point within each bucket
  | 'SAMPLED' // this is the default RSDQ response
  | 'AVERAGE'; // this is the default bucketing response

export function useDetermineAggregationType(
  config: RunsLinePlotConfig,
  {
    hasRenderError,
    isGrouped, // if something is forcing grouping on the panel
    isMonotonic, // if the x-axis is non-monotonic
    pointVisualizationMethod,
  }: {
    hasRenderError: boolean;
    isGrouped: boolean;
    isMonotonic: boolean;
    pointVisualizationMethod: DerivedPointVisualizationOption;
  }
): {
  lineType: LineAggregations;
  warningMessages: string[];
} {
  const {entityName} = useParams<{entityName?: string}>();
  if (!entityName) {
    // This check should narrow the type of `entityName` from `undefined | string`
    // to `string` in subsequent code.
    throw new RangeError(`Current entity name unexpectedly empty!`);
  }
  const shouldAllowNonMonotonicX = useRampFlagNonMonotonicXAxis(entityName);
  const unsupportedBucketingConditions = configSupportsBucketing({
    ...config,
    isGrouped,
    isMonotonic: isMonotonic || shouldAllowNonMonotonicX,
  });

  return useMemo(() => {
    if (unsupportedBucketingConditions.length > 0 || hasRenderError) {
      return {
        lineType: 'SAMPLED',
        warningMessages: unsupportedBucketingConditions,
      };
    }

    if (isBucketingOption(pointVisualizationMethod)) {
      return {
        lineType: 'AVERAGE',
        warningMessages: [],
      };
    }

    // Mean values are not supported yet
    // if() {
    //   return 'MEAN';
    // }

    //
    return {
      lineType: 'SAMPLED',
      warningMessages: [],
    };
  }, [
    unsupportedBucketingConditions,
    hasRenderError,
    pointVisualizationMethod,
  ]);
}
