import {
  Maybe,
  OrganizationMembersTableQuery,
  User,
} from '../../generated/graphql';
import {LicenseSeatsResult} from '../../pages/OrgDashboard/UserActivityDashboard/UserSeatsCard/useLicenseSeatsResult';
import {getPrimarySub, HasSubsWithPlanType} from '../../util/accounts/pricing';
import {RoleOption} from './TeamOrOrganizationRoleOption';

export enum UserRole {
  Member = 'Member',
  Admin = 'Admin',
  Viewer = 'Viewer',
  Service = 'Service',
}

export enum UserStatus {
  Pending = 'Pending',
  Active = 'Active',
  Deactivated = 'Deactivated',
  Unknown = 'Unknown',
}

export type LastActiveSort = {
  activity?: Pick<User, 'activity'>['activity'];
  createdAt: string;
  status: UserStatus;
};

export type TableUser = Pick<
  User,
  'id' | 'email' | 'photoUrl' | 'username' | 'activity'
> & {
  createdAt: string;
  displayName: string;
  name?: string;
  role: UserRole;
  status: UserStatus;
  teams: Array<{id: string; name: string}>;
  modelsSeat?: ModelsSeatType;
  organization?: {
    id?: string;
    name?: string;
  };
  lastActiveAt: LastActiveSort; // values off of the user object needed for sorting
};

export enum ModelsSeatType {
  FULL = 'Full',
  VIEWER = 'Viewer',
  NOACCESS = 'No access',
}

export enum OrganizationMemberRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  VIEW_ONLY = 'viewer',
  BILLING_ONLY = 'billing-only',
}

export function getModelsSeatTypeColumnName(seat: ModelsSeatType) {
  switch (seat) {
    case ModelsSeatType.VIEWER:
      return 'viewer';
    case ModelsSeatType.NOACCESS:
      return 'none';
    case ModelsSeatType.FULL:
      return 'full';
  }
}

export type ModelSeatTypeColumnName = 'viewer' | 'none' | 'full';
export function getModelsSeatTypeDisplayName(seat: ModelSeatTypeColumnName) {
  switch (seat) {
    case 'viewer':
      return ModelsSeatType.VIEWER;
    case 'none':
      return ModelsSeatType.NOACCESS;
    case 'full':
      return ModelsSeatType.FULL;
  }
}

export enum WeaveSeatType {
  INCLUDED = 'Full',
  VIEWER = 'Viewer',
  NOACCESS = 'No access',
}

export function transformToTableUser(
  member: NonNullable<
    OrganizationMembersTableQuery['organization']
  >['members'][number]
): TableUser {
  const teams =
    member.teams?.edges
      .map(edge => ({
        id: edge.node?.id ?? '',
        name: edge.node?.name ?? '',
      }))
      .filter(team => team.id && team.name) || [];
  const tableUser = {
    id: member.id,
    email: member.email,
    photoUrl: member.photoUrl,
    username: member.username,
    activity: null, // SAAS has no activity store
    createdAt: new Date().toISOString(),
    displayName: member.name,
    name: member.name,
    role: member.role as UserRole,
    status: 'Active' as UserStatus,
    teams,
  };

  return {...tableUser, lastActiveAt: {...tableUser}};
}

type HasMembers = {
  members: Array<{}>;
};
type HasOrgRole = {role: string};
type HasUsername = {username: string};
type HasBillingUser = {
  billingUser?: Maybe<{username?: Maybe<string>}>;
};

export function getIsMemberBillingUser<
  T extends HasUsername,
  U extends HasBillingUser
>(member?: T, organization?: U): boolean {
  if (member == null || organization == null) {
    return false;
  }

  return member.username === organization.billingUser?.username;
}

type HasSubsWithPrivilege = {
  subscriptions?: Array<{privileges?: {num_viewonly_seats?: number}}>;
};

function hasAvailableViewOnlySeats<
  T extends HasSubsWithPlanType & HasSubsWithPrivilege
>(organization: T) {
  const primarySub = getPrimarySub(organization);
  const numViewOnlySeats = primarySub?.privileges?.num_viewonly_seats;

  if (numViewOnlySeats == null || typeof numViewOnlySeats !== 'number') {
    return false;
  }

  return numViewOnlySeats > 0;
}

export function getOrgRoleDropdownItems<
  T extends HasMembers & HasBillingUser,
  U extends HasUsername & HasOrgRole
>(
  organization: T,
  member: U,
  roleManagementEnabled: boolean,
  isWeaveAsServiceUser: boolean,
  membersModelsSeatType: ModelsSeatType,
  isEnterpriseAccount: boolean
): RoleOption[] {
  // if there is only one member in org, the only option is admin
  const isAdminOnlyOption = organization.members.length === 1;
  const isMemberBillingUser = getIsMemberBillingUser(member, organization);
  const isMemberBillingOnly =
    member.role === OrganizationMemberRole.BILLING_ONLY;
  const includesViewOnly = hasAvailableViewOnlySeats(organization);

  const withWeaveRoleOptions: RoleOption[] = [
    {
      text: 'Admin',
      value: OrganizationMemberRole.ADMIN,
      isPremiumOption: false,
      isDisabled:
        membersModelsSeatType === ModelsSeatType.VIEWER || isMemberBillingOnly,
      disabledToolTipText: isMemberBillingOnly
        ? `To revert to a billing admin, the user needs a Full Models seat.`
        : `To assign the Admin role, the user needs a Full or No access Models seat. The current Viewer seat prevents this.`,
    },
    {
      text: isMemberBillingUser ? 'Billing only' : 'Member',
      value: isMemberBillingUser
        ? OrganizationMemberRole.BILLING_ONLY
        : OrganizationMemberRole.MEMBER,
      isPremiumOption: true,
      isPremiumFeatureDisabled: !roleManagementEnabled,
      isDisabled:
        isAdminOnlyOption ||
        !roleManagementEnabled ||
        (membersModelsSeatType === ModelsSeatType.VIEWER &&
          !isMemberBillingUser),
      ...(membersModelsSeatType === ModelsSeatType.VIEWER &&
        !isMemberBillingUser && {
          disabledToolTipText:
            'To assign the Member role, the user needs a Full or No access Models seat. The current Viewer seat prevents this.',
        }),
    },
  ];

  const viewerRoleWeave: RoleOption = {
    text: 'Viewer',
    value: OrganizationMemberRole.VIEW_ONLY,
    isPremiumOption: false,
    isDisabled:
      isAdminOnlyOption || membersModelsSeatType === ModelsSeatType.FULL,
    ...(membersModelsSeatType === ModelsSeatType.FULL && {
      disabledToolTipText: `To assign the Viewer role, the user needs a Viewer or No access Models seat. The current seat prevents this.
`,
    }),
  };

  const roleOptions: RoleOption[] = [
    {
      text: 'Admin',
      value: OrganizationMemberRole.ADMIN,
      isPremiumOption: false,
    },
    {
      text: isMemberBillingUser ? 'Billing only' : 'Member',
      value: isMemberBillingUser
        ? OrganizationMemberRole.BILLING_ONLY
        : OrganizationMemberRole.MEMBER,
      isPremiumOption: true,
      isPremiumFeatureDisabled: !roleManagementEnabled,
      isDisabled: isAdminOnlyOption || !roleManagementEnabled,
    },
    {
      text: 'Viewer',
      value: OrganizationMemberRole.VIEW_ONLY,
      isPremiumOption: true,
      isPremiumFeatureDisabled: !roleManagementEnabled || !includesViewOnly,
      isDisabled:
        isAdminOnlyOption || !roleManagementEnabled || !includesViewOnly,
    },
  ];

  const finalRoleOptions = isWeaveAsServiceUser
    ? withWeaveRoleOptions
    : roleOptions;

  if (isWeaveAsServiceUser && !isMemberBillingOnly && isEnterpriseAccount) {
    finalRoleOptions.push(viewerRoleWeave);
  }

  return finalRoleOptions;
}

export type UserRoleOption = RoleOption & {value: UserRole};

export function getOrgRoleDropdownItemsServer(
  membersModelsSeatType: ModelsSeatType,
  hasWeaveAccess: boolean
): UserRoleOption[] {
  const roleOptions: UserRoleOption[] = [
    {
      text: UserRole.Admin,
      value: UserRole.Admin,
      isPremiumOption: false,
      isDisabled: membersModelsSeatType === ModelsSeatType.VIEWER,
      disabledToolTipText: `To assign the Admin role, the user needs a Full or No access Models seat. The current Viewer seat prevents this.`,
    },
    {
      text: UserRole.Member,
      value: UserRole.Member,
      isPremiumOption: false,
      isDisabled: membersModelsSeatType === ModelsSeatType.VIEWER,
      disabledToolTipText:
        'To assign the Member role, the user needs a Full or No access Models seat. The current Viewer seat prevents this.',
    },
  ];

  if (!hasWeaveAccess && membersModelsSeatType === ModelsSeatType.NOACCESS) {
    return roleOptions;
  }

  roleOptions.push({
    text: UserRole.Viewer,
    value: UserRole.Viewer,
    isPremiumOption: false,
    isDisabled: membersModelsSeatType === ModelsSeatType.FULL,
    disabledToolTipText: `To assign the Viewer role, the user needs a Viewer or No access Models seat. The current seat prevents this.`,
  });

  return roleOptions;
}

export function getModelSeatTypeDropdownItemsServer(
  seatLicense: LicenseSeatsResult
): RoleOption[] {
  const {licenseSeatsData} = seatLicense;

  const includesViewOnly =
    (licenseSeatsData?.numViewOnlySeatsLicensed &&
      licenseSeatsData?.numViewOnlySeatsLicensed > 0) === true;

  const roleOptions: RoleOption[] = [
    {
      text: 'Full',
      value: ModelsSeatType.FULL,
      isPremiumOption: false,
    },
    {
      text: 'Viewer',
      value: ModelsSeatType.VIEWER,
      isPremiumOption: true,
      isPremiumFeatureDisabled: !includesViewOnly,
      isDisabled: !includesViewOnly,
    },
    {
      text: 'No access',
      value: ModelsSeatType.NOACCESS,
      isPremiumOption: false,
    },
  ];

  return roleOptions;
}

export function getModelSeatTypeDropdownItems<
  T extends HasSubsWithPlanType & HasSubsWithPrivilege,
  U extends HasUsername & HasOrgRole
>(
  organization: T,
  member: U | null,
  roleManagementEnabled: boolean
): RoleOption[] {
  const includesViewOnly = hasAvailableViewOnlySeats(organization);
  const isMemberBillingOnly =
    member?.role === OrganizationMemberRole.BILLING_ONLY;

  const roleOptions: RoleOption[] = [
    {
      text: 'Full',
      value: ModelsSeatType.FULL,
      isPremiumOption: false,
    },
    {
      text: 'Viewer',
      value: ModelsSeatType.VIEWER,
      isPremiumOption: true,
      isPremiumFeatureDisabled: !roleManagementEnabled || !includesViewOnly,
      isDisabled:
        !roleManagementEnabled || !includesViewOnly || isMemberBillingOnly,
      ...(isMemberBillingOnly && {
        disabledToolTipText: 'Billing only users cannot be models viewer',
      }),
    },
    {
      text: 'No access',
      value: ModelsSeatType.NOACCESS,
      isPremiumOption: false,
    },
  ];

  return roleOptions;
}

export const getWeaveSeatTypeFromOrgRole = (
  organizationRole: OrganizationMemberRole
): WeaveSeatType | undefined => {
  switch (organizationRole) {
    case OrganizationMemberRole.BILLING_ONLY:
      return WeaveSeatType.NOACCESS;
    case OrganizationMemberRole.ADMIN:
    case OrganizationMemberRole.MEMBER:
      return WeaveSeatType.INCLUDED;
    case OrganizationMemberRole.VIEW_ONLY:
      return WeaveSeatType.VIEWER;
    default:
      console.error('No valid Organization Role provided');
      return undefined;
  }
};

export const getWeaveSeatTypeFromUserRole = (
  userRole: UserRole
): WeaveSeatType | undefined => {
  switch (userRole) {
    case UserRole.Service:
    case UserRole.Member:
    case UserRole.Admin:
      return WeaveSeatType.INCLUDED;
    case UserRole.Viewer:
      return WeaveSeatType.VIEWER;
    default:
      console.error('No valid user Role provided');
      return undefined;
  }
};

export function getInviteRoleOptions(
  isViewOnlyAvailable: boolean,
  roleManagementEnabled: boolean
): RoleOption[] {
  const roleOptions: RoleOption[] = [
    {
      text: 'Admin',
      value: OrganizationMemberRole.ADMIN,
      isPremiumOption: false,
    },
    {
      text: 'Member',
      value: OrganizationMemberRole.MEMBER,
      isPremiumOption: true,
      isPremiumFeatureDisabled: !roleManagementEnabled,
      isDisabled: !roleManagementEnabled,
    },
    {
      text: 'Viewer',
      value: OrganizationMemberRole.VIEW_ONLY,
      isPremiumOption: true,
      isPremiumFeatureDisabled: !roleManagementEnabled || !isViewOnlyAvailable,
      isDisabled: !roleManagementEnabled || !isViewOnlyAvailable,
    },
  ];

  return roleOptions;
}

export function getInviteRoleOptionsServer(
  basicUserRole: 'Member' | 'User',
  shouldEnforceSeatLimit: boolean,
  userLimitReached: boolean,
  viewerLimitReached: boolean
): RoleOption[] {
  const roleOptions: RoleOption[] = [
    {
      text: 'Admin',
      value: 'Admin',
      isPremiumOption: false,
      isDisabled: shouldEnforceSeatLimit && userLimitReached,
    },
    {
      text: basicUserRole,
      value: basicUserRole,
      isPremiumOption: false,
      isDisabled: shouldEnforceSeatLimit && userLimitReached,
    },
    {
      text: 'Viewer',
      value: 'Viewer',
      isPremiumOption: false,
      isDisabled: shouldEnforceSeatLimit && viewerLimitReached,
    },
  ];

  return roleOptions;
}
