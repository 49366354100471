import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Stripe} from '@stripe/stripe-js';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {Select} from '@wandb/weave/components/Form/Select';
import _ from 'lodash';
import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Loader, Message, StrictInputProps} from 'semantic-ui-react';

import {
  OrganizationSubscriptionInfoQuery,
  StripePaymentMethod,
  useOrganizationSubscriptionInfoQuery,
} from '../../../generated/graphql';
import {useViewer} from '../../../state/viewer/hooks';
import {Viewer} from '../../../state/viewer/types';
import {
  getPrimaryPaymentMethod,
  StripeElementsComp,
} from '../../../util/accounts/pricing';
import {PaymentCardInfo} from '../AccountSettings/BillingTab/PaymentCardInfo';
import {AddPaymentMethodModal} from './AddPaymentMethodModal';
import {BillingAddressContext} from './BillingAddressContext';
import {BillingAddressInput} from './BillingAddressInput';
import {
  NewCheckoutModalContext,
  NewCheckoutModalUpdaterContext,
} from './NewCheckoutModalContext';
import * as S from './NewStripeForm.styles';

export type SubmitParams = {
  stripe: Stripe;
  orgName: string;
  custoEmail: string;
  paymentMethodID?: string;
  setPaymentMethodAsDefault: boolean;
  setErrMsg: (errMsg: string) => void;
};

export type RenderButtonsParams = {
  submitWrapper: SubmitWrapper;
  submitButtonDisabled: boolean;
  submitting: boolean;
};

export type SubmitWrapper = (parentSubmit: ParentSubmitFn) => Promise<void>;
type ParentSubmitFn = (p: SubmitParams) => Promise<boolean>;

type NewStripeFormProps = {
  orgName?: string;
  orgID?: string;
  hideOrgName?: boolean;
  hasStripePaymentMethods?: boolean;
  defaultPaymentMethodID?: string;
  renderButtons: (p: RenderButtonsParams) => ReactNode;
};

export const NewStripeFormComp: FC<NewStripeFormProps> = props => {
  const viewer = useViewer();
  const {loading, data, refetch} = useOrganizationSubscriptionInfoQuery({
    variables: {organizationId: props.orgID ?? ''},
    skip: !props.orgID,
  });

  if (loading) {
    return <Loader />;
  }

  if (viewer == null || (props.orgID && data == null)) {
    return null;
  }

  return (
    <NewStripeFormInner
      {...props}
      viewer={viewer}
      organization={data?.organization}
      refetchOrganization={refetch}
    />
  );
};

type NewStripeFormInnerProps = NewStripeFormProps & {
  viewer: Viewer;
  organization?: OrganizationSubscriptionInfoQuery['organization'];
  refetchOrganization: () => void;
};

type ExistingCardOptionType = {
  value: StripePaymentMethod | undefined;
  label?: string;
};

const NewStripeFormInner: React.FC<NewStripeFormInnerProps> = React.memo(
  props => {
    const {
      hideOrgName,
      renderButtons,
      viewer,
      organization,
      refetchOrganization,
    } = props;
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
      const defaultPaymentMethod =
        getPrimaryPaymentMethod(organization?.stripePaymentMethods) ??
        organization?.stripePaymentMethods[0];

      setSelectedPaymentMethod(defaultPaymentMethod);
    }, [organization]);

    const hasStripePaymentMethods =
      organization?.stripePaymentMethods != null &&
      organization?.stripePaymentMethods.length > 0;

    const {city, country, line1, line2, postalCode, state, filledAllRequired} =
      useContext(BillingAddressContext);
    const checkoutModalContext = useContext(NewCheckoutModalContext);
    const checkoutModalUpdaterContext = useContext(
      NewCheckoutModalUpdaterContext
    );
    if (checkoutModalContext == null || checkoutModalUpdaterContext == null) {
      throw new Error(
        'this component must be put under a NewCheckoutModalContext.Provider'
      );
    }
    const {submitting} = checkoutModalContext;
    const {setSubmitting} = checkoutModalUpdaterContext;

    const orgName = props.orgName ?? '';
    const orgID = props.orgID ?? '';
    const billingUserEmail = organization?.billingUser?.email ?? viewer.email;
    const [custoName, setCustoName] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
      StripePaymentMethod | undefined
    >(undefined);

    const NewStripeFormDisabled =
      submitting || stripe == null || elements == null;
    // TODO: we might want to check cardElement.complete for submitButtonDisabled
    const filledInputs =
      !hasStripePaymentMethods && (NewStripeFormDisabled || !filledAllRequired);
    const submitButtonDisabled = filledInputs || submitting;

    const onCustoNameChange = useCallback<
      NonNullable<StrictInputProps['onChange']>
    >((e, data) => {
      trackCustoNameChange(data.value);
      setCustoName(data.value);
    }, []);

    const submitWrapper = useCallback(
      async (parentSubmit: ParentSubmitFn) => {
        // Clear status messages and disable further input
        setError(null);
        setSubmitting(true);
        if (!hideOrgName && orgName.length === 0) {
          setError('Must provide organization name.');
          setSubmitting(false);
          return;
        }

        if (stripe == null || elements == null) {
          console.error('Stripe not loaded.');
          setSubmitting(false);
          return;
        }

        if (selectedPaymentMethod != null) {
          // user already has default payment method so we don't need to go through
          // the process of registering the card information with Stripe

          await parentSubmit({
            stripe,
            orgName,
            custoEmail: viewer.email,
            paymentMethodID: selectedPaymentMethod.stripePaymentMethodID,
            setPaymentMethodAsDefault: false,
            setErrMsg: setError,
          });

          setSubmitting(false);
          return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!hasStripePaymentMethods && cardElement == null) {
          console.error('Stripe card element not loaded.');
          setSubmitting(false);
          return;
        }

        const paymentMethod =
          cardElement != null
            ? await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                  name: custoName,
                  email: viewer.email,
                  address: {
                    city,
                    country,
                    line1,
                    line2,
                    postal_code: postalCode,
                    state,
                  },
                },
              })
            : null;

        if (
          paymentMethod != null &&
          (paymentMethod.error != null || paymentMethod.paymentMethod == null)
        ) {
          setError(
            paymentMethod.error?.message ?? 'Error validating payment method.'
          );
          setSubmitting(false);
          return;
        }

        await parentSubmit({
          stripe,
          orgName,
          custoEmail: viewer.email,
          paymentMethodID: paymentMethod?.paymentMethod?.id,
          setPaymentMethodAsDefault: true,
          setErrMsg: setError,
        });

        setSubmitting(false);
      },
      [
        setSubmitting,
        hideOrgName,
        orgName,
        stripe,
        elements,
        selectedPaymentMethod,
        hasStripePaymentMethods,
        custoName,
        viewer.email,
        city,
        country,
        line1,
        line2,
        postalCode,
        state,
      ]
    );

    const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false);

    const renderButtonsParams = useMemo(
      () => ({submitWrapper, submitButtonDisabled, submitting}),
      [submitWrapper, submitButtonDisabled, submitting]
    );

    const optionValues: ExistingCardOptionType[] = [
      ...(organization?.stripePaymentMethods
        ?.filter(stripePaymentMethod => stripePaymentMethod.card != null)
        .map(stripePaymentMethod => ({
          value: stripePaymentMethod,
          label: stripePaymentMethod.card?.last4,
        })) || []),
      {value: undefined, label: 'Add New Payment Method'},
    ];

    return (
      <S.CheckoutForm className="checkout-form stripe-form">
        {error != null && (
          <Message negative content={error} className="alert" />
        )}
        <S.CheckoutFields className="checkout-fields">
          {hasStripePaymentMethods && (
            <>
              <S.Label htmlFor="stripe-form-payment-method">
                Payment method
              </S.Label>
              <Select
                size="large"
                options={optionValues}
                formatOptionLabel={(option: ExistingCardOptionType) =>
                  option.value !== undefined ? (
                    <PaymentCardInfo card={option.value.card} />
                  ) : (
                    option.label ?? `Unable to load card info`
                  )
                }
                value={{value: selectedPaymentMethod}}
                onChange={selected => {
                  if (selected != null) {
                    if (selected?.value?.card == null) {
                      setIsAddPaymentModalOpen(true);
                    } else {
                      setSelectedPaymentMethod(selected.value);
                    }
                  }
                }}
                isSearchable={false}
              />
            </>
          )}
          {!hasStripePaymentMethods && (
            <>
              <S.Label htmlFor="stripe-form-name">Cardholder Name</S.Label>
              <S.Input
                id="stripe-form-name"
                data-test="cardholder-name-input"
                className="custo-input"
                onChange={onCustoNameChange}
                disabled={NewStripeFormDisabled}
              />
              <S.Label>Credit Card</S.Label>
              <div className="card-element">
                <CardElement
                  options={{
                    disabled: NewStripeFormDisabled,
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: '15px',
                        color: '#424770',
                        fontFamily: 'sans-serif',
                        fontWeight: '300',
                        letterSpacing: '0.025em',
                        ':disabled': {
                          backgroundColor: globals.gray200,
                          color: globals.gray600,
                        },
                      },
                    },
                  }}
                />
              </div>
              <div>
                <S.Label htmlFor="stripe-form-billing-address">
                  Billing address
                </S.Label>
                <BillingAddressInput />
              </div>
            </>
          )}
        </S.CheckoutFields>
        {renderButtons(renderButtonsParams)}
        {isAddPaymentModalOpen && (
          <StripeElementsComp>
            <AddPaymentMethodModal
              billingUserEmail={billingUserEmail}
              organizationName={orgName}
              organizationID={orgID}
              shouldDefault={true}
              shouldRetryOpenInvoices={false}
              onTransactionCompleted={() => {
                refetchOrganization();
                setIsAddPaymentModalOpen(false);
              }}
              onClose={() => setIsAddPaymentModalOpen(false)}
            />
          </StripeElementsComp>
        )}
      </S.CheckoutForm>
    );
  }
);

export const NewStripeForm = memo(NewStripeFormComp);

const trackCustoNameChange = _.debounce((value: string) => {
  window.analytics?.track('stripe form customer name changed', {value});
}, 500);
