import {useMemo} from 'react';

import {useDeferredMemo} from '../../hooks';
import * as FilterTypes from '../filter/types';
import * as GroupSelectionsTypes from '../groupSelections/types';
import {usePart, useParts} from '../hooks';
import * as SortTypes from '../sort/types';
import {WholeFromTypeWithRef} from '../types';
import * as Types from './types';

function assembleRunSet(
  part: Types.RunSetPart,
  groupSelections: GroupSelectionsTypes.GroupSelections,
  sort: SortTypes.Sort,
  filters: FilterTypes.Filter
): Types.RunSetConfig {
  return {
    ...part,
    grouping: groupSelections.grouping,
    selections: groupSelections.selections,
    expandedRowAddresses: groupSelections.expandedRowAddresses,
    sort,
    filters,
  };
}

export function useRunSet(ref: Types.Ref): WholeFromTypeWithRef<'runSet'> {
  const part = usePart(ref);
  const groupSelections = usePart(part.groupSelectionsRef);
  const sort = usePart(part.sortRef);
  const filters = usePart(part.filtersRef);

  return useMemo(
    () => ({...assembleRunSet(part, groupSelections, sort, filters), ref}),
    [ref, part, groupSelections, sort, filters]
  );
}

export function useRunSets(
  refs: Types.Ref[]
): Array<WholeFromTypeWithRef<'runSet'>> {
  const parts = useParts(refs);

  const groupSelectionsRefs = useMemo(
    () => parts.map(part => part.groupSelectionsRef),
    [parts]
  );
  const groupSelectionsList = useParts(groupSelectionsRefs);

  const sortRefs = useMemo(() => parts.map(part => part.sortRef), [parts]);
  const sorts = useParts(sortRefs);

  const filtersRefs = useMemo(
    () => parts.map(part => part.filtersRef),
    [parts]
  );
  const filtersList = useParts(filtersRefs);

  // useRunSets is always used to construct a query to send to the backend.
  // Our various query layers (apollo useQuery, useBucketedQuery, useRunsData)
  // all have cache mechanisms that can immediately return a result if the
  // query is already in flight.
  //
  // We want all user interactions to cause a paint within 100ms of the action,
  // to avoid "sluggishness".
  //
  // Consider this scenario:
  // - User clicks an eyeball to start a query
  // - action is synchronously dispatched to redux
  // - reducer synchronously applies the action
  // - selectors synchronously run, including useRunSets (useParts above)
  // - useBucketedQuery depends on useRunSets, which runs synchronously
  // - if that hits cache, we synchronously render all line plots (which is very expensive)
  //
  // All of the above happens after the user's click, but before a paint occurs!
  //
  // So we use useDefferedMemo here to "break the path" by a tick. This means
  // that the line plots won't render synchronously with the user's click, removing 1s of
  // delay or more before the user sees something.

  return useDeferredMemo(
    () =>
      parts.map((part, i) => ({
        ...assembleRunSet(
          part,
          groupSelectionsList[i],
          sorts[i],
          filtersList[i]
        ),
        ref: refs[i],
      })),
    [parts, groupSelectionsList, sorts, filtersList, refs]
  );
}
