import {useLocation} from 'react-router';

import {TeamOrgQuery, useTeamOrgQuery} from '../generated/graphql';
import {getRouteMatch, isBillingPath, isUsagePath} from '../routes/utils';

type TeamOrgQueryVariables = {
  entityName?: string;
  orgName?: string;
};

export function useCurrentTeamOrg(): TeamOrgQuery | null {
  const location = useLocation();
  const routeMatch = getRouteMatch(location.pathname);
  const params = routeMatch?.params as TeamOrgQueryVariables | undefined;
  const entityName = params?.entityName;
  const orgName = params?.orgName;

  const skipQuery = orgName != null || entityName == null;
  const teamOrgQuery = useTeamOrgQuery({
    variables: {
      entityName: entityName ?? '',
    },
    skip: skipQuery,
  });

  // We use Organization name as "/:{entity name}/" when setting the Route/URL for account setting pages & subpages
  // (frontends/app/src/util/urls/index.ts). Legacy orgs can have the same name as entity/team but new orgs follow the norm of "team/entity-org"
  // In those cases query above will fail, so we just return TeamOrgQueryResult item w/ Organization name, which we know is in the path.
  if (orgName != null) {
    return {
      __typename: 'Query',
      entity: {
        __typename: 'Entity',
        id: '',
        name: '',
        organization: {
          id: '',
          name: orgName,
          __typename: 'Organization',
        },
      },
    };
  }

  if (entityName == null || !teamOrgQuery.data?.entity) {
    return null;
  }

  if (teamOrgQuery.loading) {
    return null;
  }

  return teamOrgQuery.data;
}

export function useShowNudgeBar(): boolean {
  const location = useLocation();
  const onUsagePage = isUsagePath(location.pathname);
  const onAccountSettingPage = isBillingPath(location.pathname);

  if (onUsagePage || onAccountSettingPage) {
    return false;
  }

  return true;
}
