import {TargetBlank} from '@wandb/weave/common/util/links';
import {Button, ButtonVariants} from '@wandb/weave/components/Button';
import {Icon, IconName} from '@wandb/weave/components/Icon';
import React, {useEffect} from 'react';

import {useNavUpdaterContext} from '../../components/NavContextProvider';
import {useViewer} from '../../state/viewer/hooks';
import DocUrls from '../../util/doc_urls';
import * as urls from '../../util/urls';
import * as S from './NoAccessPage.styles';

type NoAccessPageProps = {
  contentKey?: ContentKey;
};

// Hides breadcrumbs while on page
function useHideNavBreadcrumbs(): void {
  const {setShouldShowBreadcrumbs} = useNavUpdaterContext();
  useEffect(() => {
    setShouldShowBreadcrumbs(false);
    // clean up so that after a user navigates away from the page the breadcrumbs reappear
    return () => setShouldShowBreadcrumbs(true);
  }, [setShouldShowBreadcrumbs]);
}

type Content = {
  headerText: string;
  caption: string;
  url: string;
  cta: string;
  icon: IconName;
  subCaption?: string;
  buttonProps?: {
    variant: string;
    icon?: IconName;
    endIcon?: IconName;
  };
};
type ContentKey = 'loggedIn' | 'loggedOut' | 'noModelsAccess';

const content: Record<ContentKey, Content> = {
  loggedIn: {
    headerText: 'It looks like you’ve landed on a locked or empty page.',
    caption:
      'If this is the right URL ask the page owner to change the privacy permissions.',
    url: urls.homePage(),
    cta: 'Go back to home',
    icon: 'chevron-next',
    buttonProps: {
      variant: ButtonVariants.Secondary,
      endIcon: 'chevron-next',
    },
  },
  loggedOut: {
    headerText: 'It looks like you’ve landed on a locked or empty page.',
    caption:
      'Try logging in or ask the page owner to change the privacy permissions.',
    url: '/wandb/intro/reports/A-Few-of-Our-Favorite-W-B-Reports--VmlldzozMTAzNjQ3',
    cta: 'See our favorite W&B Reports',
    icon: 'chevron-next',
    buttonProps: {
      variant: ButtonVariants.Secondary,
      endIcon: 'chevron-next',
    },
  },
  noModelsAccess: {
    headerText: "You don't have access to Models.",
    caption:
      'Weights & Biases Models offers seamless experiment tracking, model versioning, and performance visualization, empowering efficient debugging, optimization, and collaboration across the ML workflow.',
    subCaption: 'Reach out to your admin to request access.',
    url: DocUrls.modelManagement,
    cta: 'Learn more about Models',
    icon: 'documentation',
    buttonProps: {
      variant: ButtonVariants.Primary,
      icon: 'documentation',
    },
  },
};

export const NoAccessPage: React.FC<NoAccessPageProps> = ({contentKey}) => {
  useHideNavBreadcrumbs();
  const viewer = useViewer();
  const isLoggedIn = !!viewer;

  const stateKey = contentKey ?? (isLoggedIn ? 'loggedIn' : 'loggedOut');
  const noModelsAccess = stateKey === 'noModelsAccess';

  const buttonProps = noModelsAccess
    ? {
        variant: ButtonVariants.Primary,
        icon: 'documentation' as IconName,
      }
    : {variant: ButtonVariants.Secondary, endIcon: 'chevron-next' as IconName};

  return (
    <S.Container data-test="no-access">
      <S.LockedIcon>
        <Icon name="lock-closed" width={24} height={24} />
      </S.LockedIcon>
      <S.Header>{content[stateKey].headerText}</S.Header>
      <S.Caption>{content[stateKey].caption}</S.Caption>
      {content[stateKey].subCaption && (
        <S.Caption>{content[stateKey].subCaption}</S.Caption>
      )}
      <TargetBlank href={content[stateKey].url}>
        <Button size="large" {...buttonProps}>
          {content[stateKey].cta}
        </Button>
      </TargetBlank>
    </S.Container>
  );
};
