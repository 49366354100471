import fnv from 'fnv-plus';

export function getEntityNameFromPath(): string {
  return window.location.pathname.split('/')[1];
}

let useCacheLink = false;

export const enableCacheLink = () => {
  useCacheLink = true;
};

export const disableCacheLink = () => {
  useCacheLink = false;
};

export const isCacheEnabled = () => {
  return useCacheLink;
};

export function getCacheStorageKey(namespace: string, key: any): string | null {
  if (key == null) {
    return null;
  }
  const stringified = JSON.stringify(key);

  // fnv.fast1a64 returns a hex string, which is always a valid file name.
  // See https://github.com/tjwebb/fnv-plus#fnvfast1a64string
  const hash = fnv.fast1a64(`${namespace}--${stringified}`);

  return `${hash}.json`;
}
