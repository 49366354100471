import React, {createContext, useContext} from 'react';

import {Key} from '../../util/runTypes';
import {parseExpressions} from '../PanelExpressionOptions';
import {DEFAULT_LINE_PLOT_SETTINGS} from '../WorkspaceDrawer/Settings/runLinePlots/linePlotDefaults';
import {ExcludeOutliersValues} from '../WorkspaceDrawer/Settings/types';
import {
  configKeysInExpression,
  summaryKeysInExpression,
} from './../../util/expr';
import {AggregateCalculation} from './../../util/plotHelpers/types';
import {useRunsLinePlotSettings} from './RunsLinePlotContext/useRunsLinePlotSettings';
import {RunsLinePlotConfig} from './types';

type PanelConfigContextType = {
  aggregateCalculations: AggregateCalculation[];
  defaultLegendTemplate: string;
  entityName: string;
  excludeOutliers: ExcludeOutliersValues | undefined;
  expressionKeys: Key[];
  isFullFidelity: boolean;
  isSingleRun: boolean;
  parsedExpressions: ReturnType<typeof parseExpressions>;
  projectName: string;
} & ReturnType<typeof useRunsLinePlotSettings>;

export const PanelConfigContext = createContext<PanelConfigContextType>({
  aggregateCalculations: [],
  defaultLegendTemplate: '',
  entityName: '',
  excludeOutliers: undefined,
  expressionKeys: [],
  isFullFidelity: false,
  isSingleRun: false,
  parsedExpressions: {expressions: undefined, xExpression: undefined},
  projectName: '',
  ...DEFAULT_LINE_PLOT_SETTINGS,
});

export const PanelConfigContextProvider = React.memo(
  ({
    children,
    config,
    data,
    defaultLegendTemplate,
    isFullFidelity,
    isSingleRun,
  }: {
    children: React.ReactNode;
    config: RunsLinePlotConfig;
    data: {
      entityName: string;
      projectName: string;
    };
    defaultLegendTemplate: string;
    isFullFidelity: boolean;
    isSingleRun: boolean;
  }) => {
    const {
      colorRunNames,
      displayFullRunName,
      excludeOutliers,
      highlightedCompanionRunOnly,
      limit,
      pointVisualizationMethod,
      showLegend,
      smoothingType,
      smoothingWeight,
      tooltipNumberOfRuns,
      useRunsTableGroupingInPanels,
      xAxis,
      xAxisMax,
      xAxisMin,
      yAxisMax,
      yAxisMin,
    } = useRunsLinePlotSettings(config);

    // LB: TODO Make this come from legend spec
    const aggregateCalculations = React.useMemo(() => {
      return [
        {
          isShown: true,
          value: 'minmax',
        },
        {
          isShown: true,
          value: 'stddev',
        },
        {
          isShown: config.groupArea === 'stderr',
          value: 'stderr',
        },
        {
          isShown: config.groupArea === 'samples',
          value: 'samples',
        },
      ]
        .filter(ac => ac.isShown)
        .map(ac => ac.value as AggregateCalculation);
    }, [config.groupArea]);

    const parsedExpressions = React.useMemo(
      () => parseExpressions(config.expressions, config.xExpression),
      [config.expressions, config.xExpression]
    );

    // extra variables to collect for expressions
    const allExpressions = React.useMemo(() => {
      return [
        ...(parsedExpressions.expressions ?? []),
        ...(parsedExpressions.xExpression
          ? [parsedExpressions.xExpression]
          : []),
      ];
    }, [parsedExpressions.expressions, parsedExpressions.xExpression]);

    const expressionKeys = React.useMemo(() => {
      return allExpressions.flatMap(expr => [
        ...configKeysInExpression(expr),
        ...summaryKeysInExpression(expr),
      ]);
    }, [allExpressions]);

    const value = React.useMemo(
      () => ({
        aggregateCalculations,
        colorRunNames,
        defaultLegendTemplate,
        displayFullRunName,
        entityName: data.entityName,
        excludeOutliers,
        expressionKeys,
        highlightedCompanionRunOnly,
        isFullFidelity,
        isSingleRun,
        limit,
        parsedExpressions,
        pointVisualizationMethod,
        projectName: data.projectName,
        showLegend,
        smoothingType,
        smoothingWeight,
        tooltipNumberOfRuns,
        useRunsTableGroupingInPanels,
        xAxis,
        xAxisMax,
        xAxisMin,
        yAxisMax,
        yAxisMin,
      }),
      [
        aggregateCalculations,
        colorRunNames,
        data.entityName,
        data.projectName,
        defaultLegendTemplate,
        displayFullRunName,
        excludeOutliers,
        expressionKeys,
        highlightedCompanionRunOnly,
        isFullFidelity,
        isSingleRun,
        limit,
        parsedExpressions,
        pointVisualizationMethod,
        showLegend,
        smoothingType,
        smoothingWeight,
        tooltipNumberOfRuns,
        useRunsTableGroupingInPanels,
        xAxis,
        xAxisMax,
        xAxisMin,
        yAxisMax,
        yAxisMin,
      ]
    );
    return (
      <PanelConfigContext.Provider value={value}>
        {children}
      </PanelConfigContext.Provider>
    );
  }
);
PanelConfigContextProvider.displayName = 'PanelConfigContextProvider';

export const usePanelConfigContext = () => {
  return useContext(PanelConfigContext);
};
