import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {Tooltip} from '@wandb/weave/components/Tooltip';
import {capitalizeFirst} from '@wandb/weave/core';
import classNames from 'classnames';
import React, {FC} from 'react';
import {
  components,
  ControlProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';

import {PremiumFeatureTooltip} from '../../util/accounts/pricing';

export interface TeamOrOrganizationRoleOptionProps {
  roleOption: RoleOption;
  className?: string | null;
}

export type RoleOption = {
  text: string;
  value: string;
  description?: string | null;
  viewerCanInviteOtherAsRole?: boolean | null;
  isDisabled?: boolean | null;
  disabledToolTipText?: string | null;
} & (
  | {isPremiumOption: true; isPremiumFeatureDisabled: boolean}
  | {isPremiumOption: false}
);

export const TeamOrOrganizationRoleOption: FC<
  TeamOrOrganizationRoleOptionProps
> = ({roleOption, className}) => {
  const isDisabledWithToolTip =
    roleOption.isDisabled && roleOption.disabledToolTipText != null;

  return (
    <Tailwind style={{width: '100%'}}>
      <div className="flex w-full items-center">
        <div className="flex flex-col">
          {(() => {
            const roleText = (
              <div
                className={classNames(
                  'mr-auto',
                  className,
                  roleOption.isDisabled ? 'text-moon-800/40' : 'text-moon-800'
                )}>
                {roleOption.text}
              </div>
            );

            return isDisabledWithToolTip ? (
              <Tooltip position="bottom center" trigger={roleText}>
                {roleOption.disabledToolTipText}
              </Tooltip>
            ) : (
              roleText
            );
          })()}

          {roleOption.description && (
            <div className="mt-5 text-moon-800/40">
              {roleOption.description}
            </div>
          )}
        </div>
        {roleOption.isPremiumOption && !isDisabledWithToolTip && (
          <div className="ml-2">
            <PremiumFeatureTooltip
              isPremiumFeatureDisabled={roleOption.isPremiumFeatureDisabled}
            />
          </div>
        )}
      </div>
    </Tailwind>
  );
};

type HasValue = {
  value: string;
};

export function customSelectComponentForRoleOptions<T extends HasValue>(
  isAddUserDrawer: boolean = false
) {
  return {
    Control: (props: ControlProps<T, false>) => (
      <components.Control
        {...props}
        className={`bg-transparent hover:cursor-pointer ${
          isAddUserDrawer
            ? 'border-gray-300 h-40 w-full border-2'
            : 'w-fit shadow-none hover:shadow-none'
        }`}
      />
    ),
    ValueContainer: (props: ValueContainerProps<T, false>) => (
      <components.ValueContainer {...props} className="ml-4 p-0" />
    ),
    SingleValue: ({children, ...props}: SingleValueProps<T, false>) => (
      <components.SingleValue
        {...props}
        className={`flex items-center gap-2 ${isAddUserDrawer ? 'ml-8' : ''}`}>
        {capitalizeFirst(props.getValue()[0].value)}
      </components.SingleValue>
    ),
    Menu: (props: MenuProps<T, false>) => (
      <Tailwind>
        <components.Menu
          {...props}
          className={`${isAddUserDrawer ? 'w-full' : 'w-[180px]'}`}
        />
      </Tailwind>
    ),
    Option: (props: OptionProps<T, false>) => (
      <components.Option
        {...props}
        className="flex items-center justify-between">
        {props.children}
        {props.isSelected && <Icon name="checkmark" width={18} height={18} />}
      </components.Option>
    ),
  };
}
