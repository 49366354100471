import React, {type ReactElement, type ReactNode, useEffect} from 'react';

import {useSelector} from '../../state/hooks';
import {useViewer} from '../../state/viewer/hooks';
import {getViewerLoading} from '../../state/viewer/selectors';
import {useAdminModeActive} from '../admin';
import {disableDatadog, getDatadogRum} from './wrapper';

type DatadogProps = {
  children: ReactNode;
};
const ANONYMOUS = {id: 'anonymous'};

/**
 * Sets up the Datadog context with user info when it becomes available.
 * Disables Datadog if the user is detected to be an admin user.
 *
 * @param children
 */
export function DatadogProvider({children}: DatadogProps): ReactElement {
  const isAdmin = useAdminModeActive();
  const viewerIsLoading = useSelector(getViewerLoading);
  const viewer = useViewer();

  useEffect(() => {
    if (isAdmin) {
      disableDatadog();
    }
  }, [isAdmin]);

  useEffect(() => {
    if (viewerIsLoading) {
      return;
    }
    const datadogRum = getDatadogRum();
    if (viewer) {
      datadogRum.setUser({
        id: viewer.username,
        name: viewer.name,
      });
      datadogRum.startSessionReplayRecording();
    } else {
      datadogRum.setUser(ANONYMOUS);
    }
  }, [viewerIsLoading, viewer]);

  // N.B. Wrapping children in DatadogContext.Provider is unnecessary here,
  //      because it is set to reasonable values by default in context.ts. This
  //      component exists to disable datadog if the user is an admin and as a
  //      wrapper in case the Provider becomes necessary in the future.
  return <>{children}</>;
}
