import {envIsIntegration, envIsLocal} from '../config';
import {RampIdType} from '../generated/graphql';
import {Viewer} from '../state/viewer/types';
import {useViewer} from './../state/viewer/hooks';
import {
  checkEntityRampFlag,
  useEntityRampFlag,
  useOrgNameRampFlag,
  useOrgRampFlag,
  useOrgRampFlagWithLoading,
  useUserOrOrgRampFlag,
  useUserRampFlag,
} from './useRampFlag';

/*
Docs for ramp feature flags:
https://www.notion.so/wandbai/Ramp-Feature-Flag-Documentation-da56991072df4c8d980cd36804f52ded
*/

/**
 * This is a little hacky but we're wanting to safely prevent special orgs from getting
 * force enrolled in full fidelity until we're ready.
 * 1. The entity name check won't always work because not all workspaces are under namespaces like NVIDIA
 * 2. The `userInfo.company` field is not always populated
 * 3. viewer orgs is pretty reliable, so between all three of these this should be a comprehensive block
 */
// https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1727116018580919
const BLOCK_LIST = ['OPENAI', 'NVIDIA'];
export const getShouldBlockEntity = (
  entityName: string,
  viewer: Viewer | undefined
) => {
  // block special entities
  const isEntityNameBlocked = BLOCK_LIST.includes(entityName.toUpperCase());

  // block based on viewer org
  const isViewerOrgBlocked = viewer?.organizations?.some(org => {
    return BLOCK_LIST.includes(org.name.toUpperCase());
  });

  // block based on userInfo.company
  const company = viewer?.userInfo?.company ?? '';
  const isCompanyBlocked = BLOCK_LIST.includes(company.toUpperCase());

  return isEntityNameBlocked || isViewerOrgBlocked || isCompanyBlocked;
};

export const useRampFlagShadowRSDQ = () => {
  return useUserRampFlag('shadow-rsdq-queries-user', RampIdType.UserName);
};

export const useRampFlagDisableFuzzySearch = (entityName: string) => {
  const rampFlag = useEntityRampFlag(
    entityName,
    'disable-fuzzy-search',
    RampIdType.EntityName
  );
  if (envIsLocal) {
    return true;
  }
  return rampFlag;
};

export const useRampFlagDisableQueryMerging = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'disable-query-merging',
    RampIdType.EntityName
  );
};

export const useRampFlagDisableUpdateHistoryKeysInfo = () => {
  return useUserOrOrgRampFlag(
    'disable-update-history-key-info-user',
    'disable-update-history-key-info-org'
  );
};

export const checkRampFlagEnableOPFSCache = async (
  entityName: string
): Promise<boolean> => {
  return await checkEntityRampFlag(entityName, 'enable-opfs-cache');
};

export const useRampFlagEnableOPFSCache = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'enable-opfs-cache',
    RampIdType.EntityName
  );
};

export const useRampFlagFullFidelityInReports = (entityName: string) => {
  const viewer = useViewer();

  const userOrOrgFlag = useUserOrOrgRampFlag(
    'full-fidelity-in-reports-user',
    'full-fidelity-in-reports-org'
  );

  const entityFlag = useEntityRampFlag(
    entityName,
    'full-fidelity-in-reports-entity',
    RampIdType.EntityName
  );

  const shouldBlockEntity = getShouldBlockEntity(entityName, viewer);
  if (shouldBlockEntity) {
    return false;
  }

  return userOrOrgFlag || entityFlag;
};

export const useRampFlagMultiRunsetHistoryKeys = (entityName: string) => {
  const viewer = useViewer();
  const userOrOrgFlag = useUserOrOrgRampFlag(
    'multi-runset-history-keys-user',
    'multi-runset-history-keys-org'
  );

  const entityFlag = useEntityRampFlag(
    entityName,
    'multi-runset-history-keys-entity',
    RampIdType.EntityName
  );

  const shouldBlockEntity = getShouldBlockEntity(entityName, viewer);
  if (shouldBlockEntity) {
    return false;
  }

  return userOrOrgFlag || entityFlag;
};

export const useRampFlagDefaultGorillaOutliers = (entityName: string) => {
  const userOrOrgFlag = useUserOrOrgRampFlag(
    'gorilla-force-run-outliers-user',
    'gorilla-force-run-outliers'
  );
  const entityFlag = useEntityRampFlag(
    entityName,
    'gorilla-force-run-outliers-entity',
    RampIdType.EntityName
  );

  return userOrOrgFlag || entityFlag;
};

export const useRampFlagNonMonotonicXAxis = (entityName: string) => {
  const viewer = useViewer();
  const userOrOrgFlag = useUserOrOrgRampFlag(
    'non-monotonic-x-axis-user',
    'non-monotonic-x-axis-org'
  );
  const entityFlag = useEntityRampFlag(
    entityName,
    'non-monotonic-x-axis-entity',
    RampIdType.EntityName
  );

  return userOrOrgFlag || entityFlag;
};

// in W&B server yet
export const useRampFlagAccountSelector = () => {
  if (envIsLocal) {
    return false;
  }
  return true;
};

export const useRampFlagGlobalRegistry = (orgName: string) => {
  // NB: do not copy this pattern, it is exactly the same as `return useOrgNameRampFlag()`
  const {data: orgGlobalRegistryFlag, loading} = useOrgNameRampFlag(
    orgName,
    'global-registry',
    RampIdType.OrgName
  );
  return {data: orgGlobalRegistryFlag ?? false, loading};
};

export const useRampFlagNoKeysets = () => {
  return useUserOrOrgRampFlag('no-keysets-user', 'no-keysets-org');
};

export const useRampFlagWeaveAsAService = () => {
  const userFlag = useUserRampFlag(
    'weave-as-a-service-user',
    RampIdType.UserName
  );

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag ?? false;
};

export const useRampProPricingPlan = () => {
  const userFlag = useUserRampFlag('pro-pricing-plan', RampIdType.UserName);

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag ?? false;
};

export const useRampFlagWeaveMarketing = () => {
  const userFlag = useOrgRampFlag('weave-marketing', RampIdType.OrgName);

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag;
};

export const useRampProjectSpecificRoles = (orgName: string) => {
  // NB: do not copy this pattern, it is exactly the same as `return useOrgNameRampFlag()`
  const {data: orgProjectSpecificRolesFlag, loading} = useOrgNameRampFlag(
    orgName,
    'project-specific-roles',
    RampIdType.OrgName
  );
  return {data: orgProjectSpecificRolesFlag ?? false, loading};
};

export const useRampFlagMetricChangeNotifications = () => {
  return useOrgRampFlagWithLoading(
    'run-metric-notifications',
    RampIdType.OrgName
  );
};

export const useRampFlagStarredProjects = () => {
  return useUserRampFlag('starred-projects', RampIdType.UserName);
};

export const useRampFlagHideSummaryHistograms = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'hide-summary-histograms',
    RampIdType.EntityName
  );
};

export const useRampFlagGQLLongTermCacheByEntity = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'gql-long-term-cache-entity',
    RampIdType.EntityName
  );
};

export const useRampFlagGQLLongTermCacheByUser = () => {
  return useUserRampFlag('gql-long-term-cache-user', RampIdType.UserName);
};

export const useRampFlagOrgLevelServiceAccounts = (orgName: string) => {
  return useOrgNameRampFlag(
    orgName,
    'org-level-service-accounts',
    RampIdType.OrgName
  );
};

export const useRampFlagEmbeddedPanels = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'embedded-panels-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'embedded-panels-entity',
    RampIdType.EntityName
  );
  if (envIsLocal || envIsIntegration) {
    return false;
  }
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagFullScreenWorkspacePanels = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'full-screen-workspace-panels-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'full-screen-workspace-panels-entity',
    RampIdType.EntityName
  );
  if (envIsLocal || envIsIntegration) {
    return false;
  }
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagWorkspaceModes = () => {
  return useUserOrOrgRampFlag('workspace-modes-user', 'workspace-modes-org');
};
