// Note: for accurate profiling of query time we want to collect even fast responses
// HOWEVER: certain queries (such as RSDQs) make up the bulk of our queries so
// we'll apply a higher reporting threshold for those
import config from '../../config';

export const MIN_NETWORK_ELAPSED_TIME_MILLIS = 50;
export const THROTTLED_MIN_NETWORK_ELAPSED_TIME_MILLIS = 1000;

// Requests to these domains will count towards the time-to-interactive metric.
export const TTI_DOMAIN_INCLUSIONS = [
  'wandb.com',
  'wandb.ai',
  'wandb.us',
  'wandb.test',
  'googleapis.com',
];

export const TTI_WANDB_ANALYTICS_DOMAIN = 'analytics.wandb.ai';

// Requests to these domains will be ignored in the time-to-interactive metric.
export const TTI_DOMAIN_EXCLUSIONS = [
  '6sc.co',
  'ads-twitter.com',
  'bing.com',
  'browser-intake-datadoghq.com',
  'chameleon.io',
  'clarity.ms',
  'cookielaw.org',
  'datadoghq.com',
  'doubleclick.net',
  'fullstory.com',
  'getbeamer.com',
  'github.com',
  'google-analytics.com',
  'googleoptimize.com',
  'googletagmanager.com',
  'gravatar.com',
  'intercom.io',
  'intercomcdn.com',
  'jquery.com',
  'jscloud.net',
  'licdn.com',
  'linkedin.com',
  'marketo.net',
  'mktoresp.com',
  'onetrust.com',
  'oribi.io',
  'pendo.io',
  'reddit.com',
  'redditstatic.com',
  'segment.io',
  'sentry.io',
  't.co',
  'twitter.com',
  'wordlift.io',
  'wp.com',
  'www.google',
  'zdassets.com',
  'zendesk.com',
  'zoominfo.com',
  'zopim.com',
];

export const FRIENDLY_ENTRY_TYPE_NAMES = {
  // A long task is any uninterrupted period where the main UI thread is busy for 50ms or longer
  // note that longtask and event/first-input will overlap and report the same issue multiple times
  longtask: 'UI thread busy',
  // resource performance event occurs when a network request takes a long time to fulfill
  resource: 'Network',
  // event and first-input both measure how long it takes for the app to respond to input from the user
  // the value of 'duration' is the time from startTime to the next rendering paint.
  event: 'Slow UI response time',
  'first-input': 'First input delay',
} as const;

/*
How did we pick these limits?
* longtasks: we're finding that when the user is hovering over charts, it is getting
  marked as long tasks, but just above the 50ms threshold for longtasks, so we are just
  bumping up that threshold a bit.
* network: this is somewhat arbitrary, so it may need adjusting. The idea is that
  500ms is still "fast" from user's viewpoint for loading data. Hopefully we can
  make this value lower over time. This was picked by looking at network request in
  workspaces, and see what duration looked like for those call, and aiming for a
  ~p90 value.
*/
export const MINIMUM_NETWORK_DURATION_MS = 500;

export const MINIMUM_LONGTASK_DURATION_MS = 200;

export const PERF_LOG_PREFIX = 'WB_P'; // W&B Perf

// What performance events should we print to the console?
export const CONSOLE_REPORT_SLOW_EVENT_MILLIS = config.DATADOG_DEBUG ? 1 : 500;

// We don't report all events to server since we only want to capture data about
// particularly bad loading times
export const MIN_REPORT_TO_SERVER_TIME_MILLIS = config.DATADOG_DEBUG ? 1 : 500;

// This is data sent to DataDog as part of network performance events. We add this so it's
// easier to find network perf events, regardless of how they're reported to DD (graphql vs resource)
export const NETWORK_EVENT_CONTEXT = {isNetwork: true};
