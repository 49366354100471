import {useCallback} from 'react';

import {useDatadogRum} from '../../datadog';

type StartOrStopVital = () => void;

/**
 * start and stop are millisecond timestamps.
 */
type RecordVital = (start: number, duration: number) => void;

type UseDurationVitalHook = {
  /**
   * For now, do not use this call. It can confuse our metrics-gathering if the
   * browser tab is backgrounded.
   * @deprecated
   */
  start: StartOrStopVital;
  /**
   * For now, do not use this call. It can confuse our metrics-gathering if the
   * browser tab is backgrounded.
   * @deprecated
   */
  stop: StartOrStopVital;
  record: RecordVital;
};

/**
 * A thin wrapper over Datadog's vital durations. It has start and stop for
 * tracking durations, as well as a retrospective record fn, in case we just
 * get back a number and not start/stop moments.
 *
 * @param metric - the Datadog RUM metric name, should be unique
 * @param description - description, used to distinguish similar RUM metrics (eg different pages)
 */
export function useDurationVital(
  metric: string,
  description?: string
): UseDurationVitalHook {
  const datadog = useDatadogRum();

  const start = useCallback(() => {
    datadog.startDurationVital(metric, {description});
  }, [datadog, description, metric]);

  const stop = useCallback(() => {
    datadog.stopDurationVital(metric, {description});
  }, [datadog, description, metric]);

  const record = useCallback(
    (start, duration) => {
      datadog.addDurationVital(metric, {
        startTime: start,
        duration,
        description,
      });
    },
    [datadog, description, metric]
  );

  return {start, stop, record};
}
