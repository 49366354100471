import {toast} from '@wandb/weave/common/components/elements/Toast';
import WandbLoader from '@wandb/weave/common/components/WandbLoader';
import {saveTableAsCSV, Table, TableRow} from '@wandb/weave/common/util/csv';
import {Button} from '@wandb/weave/components';
import React, {useMemo, useState} from 'react';

import {UsageAggregation, UsageType} from '../../generated/graphql';
import {compareAsc, secondsToHoursExact} from '../../util/date';
import {bytesInGB, bytesInMB} from '../../util/storage';
import {
  getIntervalMarkers,
  TimeRange,
  useHistoricUsageChartContext,
  valueAggregator,
} from './HistoricUsageChartContextProvider';

const CSV_MAX_TIME_RANGE = TimeRange.LAST_24_MONTHS;
const DATE_FORMAT = 'MMM d, yyyy';

function getUnitColumnLabel(usageType: UsageType): string {
  switch (usageType) {
    case UsageType.Storage:
      return 'Storage (average GB)';
    case UsageType.TrackedHours:
      return 'Tracked hours';
    case UsageType.Weave:
      return 'Weave (MB ingested)';
    case UsageType.UserSeats:
      return 'User seats';
  }
}

export function getValueFormatterWithoutUnits(
  usageType: UsageType
): (value: number) => string {
  switch (usageType) {
    case UsageType.Storage:
      return value => `${value / bytesInGB}`;
    case UsageType.Weave:
      return value => `${value / bytesInMB}`;
    case UsageType.TrackedHours:
      return value => `${secondsToHoursExact(value)}`;
    case UsageType.UserSeats:
      return value => `${value}`;
  }
}

enum DefaultColumn {
  START = 'Start',
  END = 'End',
  TEAM = 'Team',
  PROJECT = 'Project',
}

function processData(
  usage: UsageAggregation[],
  usageType: UsageType,
  aggregationCutoffDate: Date | undefined
): Table {
  const filteredData = usage
    ?.sort(
      (
        {start: start1, entityName: entityName1, projectName: projectName1},
        {start: start2, entityName: entityName2, projectName: projectName2}
      ) => {
        const dateSort = compareAsc(new Date(start1), new Date(start2));
        if (dateSort !== 0) {
          return dateSort;
        }
        if (entityName1 !== entityName2) {
          return entityName1.localeCompare(entityName2);
        }
        return projectName1.localeCompare(projectName2);
      }
    )
    ?.map(usageData => ({
      ...usageData,
      // need to divide storage by number of days in the month, so use the valueAggregator
      value: valueAggregator(usageData, usageType, 0, aggregationCutoffDate),
    }));
  const valueFormatter = getValueFormatterWithoutUnits(usageType);

  // Convert it to CSV format
  return (
    filteredData?.reduce(
      (table, {start, end, value, entityName, projectName}) => {
        const row = {} as TableRow;
        row[DefaultColumn.START] = new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          timeZone: 'UTC',
        }).format(new Date(start));
        row[DefaultColumn.END] = new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          timeZone: 'UTC',
        }).format(new Date(end));
        row[DefaultColumn.TEAM] = entityName;
        row[DefaultColumn.PROJECT] = projectName;
        row[getUnitColumnLabel(usageType)] = valueFormatter(value);
        table.data.push(row);
        return table;
      },
      {
        data: [] as TableRow[],
        cols: [...Object.values(DefaultColumn), getUnitColumnLabel(usageType)],
      }
    ) ?? {
      data: [] as TableRow[],
      cols: [...Object.values(DefaultColumn), getUnitColumnLabel(usageType)],
    }
  );
}

export const ExportAsCSV = () => {
  const {usageType, processedData, fetchCSVUsage, aggregationCutoffDate} =
    useHistoricUsageChartContext();
  const intervalMarkers = getIntervalMarkers(CSV_MAX_TIME_RANGE);
  const [isLoading, setIsLoading] = useState(false);
  const [usageData, setUsageData] = useState<Partial<
    Record<UsageType, UsageAggregation[]>
  > | null>(null);
  const data = usageData?.[usageType];

  // Can't use Apollo useLazyQuery because
  // 1. Apollo's cache is super slow when dealing with large amounts of data. But we don't need the cache anyway because the user is unlikely to download the same data twice
  // 2. There's a bug with useLazyQuery where it acts like useQuery after the first render. This would mean that if a user clicks Export CSV on the Storage page, the data is queried
  //    as expected. But then, if they go to the Tracked hours tab, the data is automatically queried on render instead of only when they click.
  //    This solution using the apollo client directly is found here https://github.com/apollographql/apollo-client/issues/7484#issuecomment-1101724093.
  // 3. On top of that, React doesn't seem to realize when switching between the tabs that this component should unmount and remount. So, any
  //    local state is still kept. That's why we need `usageData` to be a Record, instead of just straightforward UsageTabInfoQuery.
  const getUsage = async () =>
    await fetchCSVUsage(usageType, intervalMarkers)
      .then(fetchedUsage => {
        setUsageData(prevData => {
          const newData = {...prevData};
          newData[usageType] = fetchedUsage;
          return newData;
        });
        setIsLoading(false);
        saveTableAsCSV(
          processData(fetchedUsage, usageType, aggregationCutoffDate)
        );
      })
      .catch(error => {
        toast(`Error exporting data as CSV: ${error}`, {
          type: 'error',
        });
        setIsLoading(false);
      });

  const processedCSVData: Table = useMemo(
    () =>
      data != null
        ? processData(data, usageType, aggregationCutoffDate)
        : {
            cols: [
              ...Object.values(DefaultColumn),
              getUnitColumnLabel(usageType),
            ],
            data: [],
          },
    [aggregationCutoffDate, data, usageType]
  );

  // If there's no data visible in the chart, that means there's nothing to export
  const hasNoDataToDownload = processedData?.every(({hideValue}) => hideValue);
  return (
    <Button
      data-test="export-as-csv"
      icon="download"
      disabled={hasNoDataToDownload}
      variant="ghost"
      size="medium"
      onClick={async () => {
        if (data == null) {
          setIsLoading(true);
          await getUsage();
        } else {
          setIsLoading(false);
          saveTableAsCSV(processedCSVData);
        }
      }}>
      <>
        Export as CSV
        {isLoading && (
          <div className="ml-8 flex items-center">
            <WandbLoader active size="tiny" inline />
          </div>
        )}
      </>
    </Button>
  );
};
