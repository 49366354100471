import {ApolloError, ApolloQueryResult} from 'apollo-client';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useParams} from 'react-router';

import {organizationComputeSecondUsageQuery} from '../../../components/GlobalBanners';
import {OrganizationMemberRole} from '../../../components/MembersTable/util';
import {AccountSelectorContext} from '../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {Account, AccountType} from '../../../components/Search/SearchNav/types';
import {useAccountList} from '../../../components/Search/SearchNav/useAccountList';
import {
  OrganizationSubscriptionInfoQuery,
  PlanType,
  useOrganizationSubscriptionInfoQuery,
  useOrganizationSubscriptionsBasicDetailsQuery,
} from '../../../generated/graphql';
import {useViewer} from '../../../state/viewer/hooks';
import {
  getPrimarySub,
  Privileges,
  useAccountPrivileges,
} from '../../../util/accounts/pricing';
import {useAdminModeActive} from '../../../util/admin';
import {useRampFlagAccountSelector} from '../../../util/rampFeatureFlags';
import {PlanName} from '../util';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useDefaultAccountFromAccountList} from './AccountPickerSection';
import {getIsViewerBillingUser, getViewerOrganizationMemberRole} from './util';

export enum TabType {
  USERS = 'Users',
  MEMBERS = 'Members',
  TEAMS = 'Teams',
  USAGE = 'Usage',
  ROLES = 'Roles',
  BILLING = 'Billing',
  SETTINGS = 'Settings',
  SERVICE_ACCOUNTS = 'Service Accounts',
}

export type AccountSettingsContextState = {
  loading: boolean;
  isViewerInternalAdmin: boolean;
  isViewerBillingUser: boolean;
  viewerMemberRole?: OrganizationMemberRole;
  selectedAccount: Account | null;
  selectedTab: TabType;
  organization?: OrganizationSubscriptionInfoQuery['organization'];
  teamsPlanTrackedHoursInSecs: number | null;
  organizationLoadingError?: ApolloError;
  canSeeOrgInURL: boolean;
  privileges: Privileges;
};

export const AccountSettingsContext = createContext<
  AccountSettingsContextState | undefined
>(undefined);

type AccountSettingsContextUpdaters = {
  refetch?: (() => Promise<
    ApolloQueryResult<OrganizationSubscriptionInfoQuery>
  >) &
    (() => void);
};

export const AccountSettingsUpdaterContext =
  createContext<AccountSettingsContextUpdaters>({
    refetch: undefined,
  });

export const AccountSettingsContextProvider: FC = ({children}) => {
  const viewer = useViewer();
  const {
    selectedAccount: accountSelectorSelectedAccount,
    setSelectedAccount,
    canSeeOrgInURL,
  } = useContext(AccountSelectorContext);
  const enableAccountSelector = useRampFlagAccountSelector();
  const isViewerInternalAdmin = useAdminModeActive();
  const {selectedAccount: selectedBillingAccount, selectedTab} =
    useDerivedStateFromURL();

  const selectedAccount = enableAccountSelector
    ? accountSelectorSelectedAccount
    : selectedBillingAccount;

  // TODO(elaina): remove this once we've fully enabled account selector. This is a temporary hack
  // so that child tabs on the account settings page can make use of the AccountSelectorContext
  useEffect(() => {
    if (!enableAccountSelector && selectedBillingAccount) {
      setSelectedAccount(selectedBillingAccount);
    }
  }, [selectedBillingAccount, enableAccountSelector, setSelectedAccount]);

  const {loading: privilegesLoading, privileges} =
    useAccountPrivileges(selectedAccount);
  const {accountList, isLoading} = useAccountList();
  let orgID = enableAccountSelector ? null : selectedBillingAccount?.id; // we do NOT want to make an organization query for the account selector's personal entity
  if (
    enableAccountSelector &&
    selectedAccount?.accountType === AccountType.Personal
  ) {
    orgID = accountList.find(
      account =>
        account?.accountType === AccountType.Personal &&
        account.personalOrgId != null
    )?.personalOrgId;
  }
  if (
    enableAccountSelector &&
    selectedAccount?.accountType !== AccountType.Personal
  ) {
    orgID = selectedAccount?.id;
  }
  const organizationQuery = useOrganizationSubscriptionInfoQuery({
    variables: {organizationId: orgID as string},
    skip: orgID == null,
  });

  const organization = organizationQuery.data?.organization || null;

  const viewerMemberRole = getViewerOrganizationMemberRole(
    viewer,
    organization?.members
  );
  const isViewerBillingUser = getIsViewerBillingUser(viewer, organization);
  const planName =
    organization != null ? getPrimarySub(organization)?.plan.name : null;
  const isPrimarySubTeamsPlans =
    planName === PlanName.MonthlyTeams2023 ||
    planName === PlanName.YearlyTeams2023;

  const organizationSubscription =
    useOrganizationSubscriptionsBasicDetailsQuery({
      variables: {organizationId: organization?.id ?? ''},
      skip: organization == null || !isPrimarySubTeamsPlans,
    });
  const primaryBillingDate =
    organizationSubscription?.data?.organization?.subscriptions?.find(
      s => s.plan.planType === PlanType.Primary
    )?.billingPeriodStart;

  const [teamsPlanTrackedHoursInSecs, setTeamsPlanTrackedHoursInSecs] =
    useState<number | null>(null);

  useEffect(() => {
    const fetchOrgUsageIfTeamsPlan = async () => {
      if (
        primaryBillingDate == null ||
        !organization?.id ||
        !organization?.name ||
        !isPrimarySubTeamsPlans
      ) {
        // Conditions not met. Either don't have necessary info or primary sub not Teams
        return;
      }

      const trackedHours = await organizationComputeSecondUsageQuery(
        organization?.id,
        organization?.name,
        new Date(primaryBillingDate)
      );
      setTeamsPlanTrackedHoursInSecs(trackedHours);
    };
    fetchOrgUsageIfTeamsPlan();
  }, [organization, primaryBillingDate, isPrimarySubTeamsPlans]);

  const state = useMemo(() => {
    if (organizationQuery.loading || isLoading || privilegesLoading) {
      return {
        loading: true,
        viewerMemberRole,
        isViewerInternalAdmin,
        isViewerBillingUser,
        selectedAccount,
        selectedTab,
        organization: undefined,
        teamsPlanTrackedHoursInSecs,
        canSeeOrgInURL,
        privileges,
      };
    }

    return {
      loading: false,
      viewerMemberRole,
      isViewerInternalAdmin,
      isViewerBillingUser,
      selectedAccount,
      selectedTab,
      organization,
      teamsPlanTrackedHoursInSecs,
      organizationLoadingError: organizationQuery.error,
      canSeeOrgInURL,
      privileges,
    };
  }, [
    selectedAccount,
    selectedTab,
    organization,
    organizationQuery,
    viewerMemberRole,
    isViewerBillingUser,
    isViewerInternalAdmin,
    teamsPlanTrackedHoursInSecs,
    isLoading,
    privilegesLoading,
    privileges,
    canSeeOrgInURL,
  ]);

  const updaters = {
    refetch: organizationQuery.refetch,
  };

  return (
    <AccountSettingsContext.Provider value={state}>
      <AccountSettingsUpdaterContext.Provider value={updaters}>
        {children}
      </AccountSettingsUpdaterContext.Provider>
    </AccountSettingsContext.Provider>
  );
};

export function useAccountSettingsContext(): AccountSettingsContextState {
  const accountSettingsContext = useContext(AccountSettingsContext);

  if (accountSettingsContext === undefined) {
    throw new Error(
      'useAccountSettingsContext must be used within a AccountSettingsContextProvider'
    );
  }

  return accountSettingsContext;
}

export function useAccountSettingsContextUpdater(): AccountSettingsContextUpdaters {
  const updaters = useContext(AccountSettingsUpdaterContext);

  if (updaters === undefined) {
    throw new Error(
      'useAccountSettingsContextUpdater must be used within a AccountSettingsContextProvider'
    );
  }

  return updaters;
}

type RouteParams = {
  orgName?: string;
  tab?: string;
};

function useDerivedStateFromURL(): Pick<
  AccountSettingsContextState,
  `selectedAccount` | `selectedTab`
> {
  const {orgName, tab} = useParams<RouteParams>();
  const {accountList} = useAccountList();
  const defaultAccount = useDefaultAccountFromAccountList();

  const selectedAccount =
    accountList.find(a => a.name === orgName) ?? defaultAccount;
  const selectedTab = getSelectedTab(tab);

  return {
    selectedAccount,
    selectedTab,
  };
}

export function getSelectedTab(tab?: string): TabType {
  if (tab == null) {
    return TabType.USERS;
  }

  const tabLowerCase = tab.toLowerCase();
  switch (tabLowerCase) {
    case `teams`:
      return TabType.TEAMS;
    case `billing`:
      return TabType.BILLING;
    case `service-accounts`:
      return TabType.SERVICE_ACCOUNTS;
    case `settings`:
      return TabType.SETTINGS;
    default:
      // includes case `users`
      return TabType.USERS;
  }
}
