import * as Switch from '@wandb/weave/components/Switch';
import * as React from 'react';
import {twMerge} from 'tailwind-merge';

import {SettingLabel} from '../components/SettingLabel';
import {ExcludeOutliersValues} from '../types';
import {areOutliersExcluded} from '../utils';

type LinePlotConfigExcludeOutliersProps = {
  className?: string;
  excludeOutliers: ExcludeOutliersValues;
  onExcludeOutliersChange: (value: boolean) => void;
};

export const LinePlotConfigExcludeOutliers = ({
  className,
  excludeOutliers,
  onExcludeOutliersChange,
}: LinePlotConfigExcludeOutliersProps) => {
  return (
    <fieldset className={twMerge('text-moon-800', className)}>
      <div className="mt-4 flex items-center justify-between">
        <div>
          <div className="flex items-center gap-4">
            <SettingLabel className="w-fit text-sm" text="Outliers" />
          </div>
          <p className="text-sm text-moon-500">
            Exclude extreme outliers when scaling charts
          </p>
        </div>
        <Switch.Root
          aria-label={'Outliers'}
          checked={areOutliersExcluded(excludeOutliers)}
          onCheckedChange={onExcludeOutliersChange}>
          <Switch.Thumb checked={areOutliersExcluded(excludeOutliers)} />
        </Switch.Root>
      </div>
    </fieldset>
  );
};
