import {WorkspaceLayoutSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {isAutoPanel} from '../../../util/isAutoPanel';
import {EMPTY_PANEL_BANK_CONFIG} from '../../../util/panelbankConfigs';
import {PANEL_BANK_HIDDEN_SECTION_NAME} from '../../../util/panelbankTypes';
import {getKey} from '../../../util/panels';
import {setInShallowClone} from '../../../util/utility';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import {getExpectedSpec} from '../../expectedPanels/utils';
import * as Normalize from '../normalize';
import * as PanelTypes from '../panel/types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as PanelGenUtils from '../workspaceSettings/panelGenUtils';
import * as WorkspaceSettingsTypes from '../workspaceSettings/types';
import * as Actions from './actions';
import * as Types from './types';

export const clearAllPanels = (
  state: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref,
  shouldDisablePanelGen = true
): [ViewReducerState, ActionType] => {
  // track these values for undo
  const prevSectionRefs =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;
  const prevShouldAutoGeneratePanels =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      .shouldAutoGeneratePanels;

  const {newParts, newSectionRefs} = EMPTY_PANEL_BANK_CONFIG.sections.reduce(
    (acc, section) => {
      const {parts, ref} = Normalize.addObjImmutable(
        acc.newParts,
        'panel-bank-section-config',
        panelBankConfigRef.viewID,
        section
      );
      acc.newParts = parts;
      acc.newSectionRefs.push(ref);
      return acc;
    },
    {
      newParts: state.parts,
      newSectionRefs: [] as PanelBankSectionConfigTypes.Ref[],
    }
  );

  let newState = {
    ...state,
    parts: {
      ...newParts,
      [panelBankConfigRef.type]: {
        ...newParts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...newParts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs: newSectionRefs, // update pb config to use new sections
        },
      },
    },
  };

  if (shouldDisablePanelGen) {
    newState = PanelGenUtils.disableAutoGeneratePanels(
      newState,
      workspaceSettingsRef,
      panelBankConfigRef,
      null
    )[0];
  }

  const inverseAction = Actions.clearAllPanelsUndo(
    panelBankConfigRef,
    workspaceSettingsRef,
    prevSectionRefs,
    prevShouldAutoGeneratePanels
  );

  return [newState, inverseAction];
};

export const clearAllPanelsUndo = (
  state: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref,
  sectionRefs: PanelBankSectionConfigTypes.Ref[],
  shouldAutoGeneratePanels: WorkspaceLayoutSettings['shouldAutoGeneratePanels']
): [ViewReducerState, ActionType] => {
  let newState = {
    ...state,
    parts: {
      ...state.parts,
      [panelBankConfigRef.type]: {
        ...state.parts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...state.parts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs, // reverts sectionRefs
        },
      },
    },
  };

  // revert auto-gen setting
  newState = PanelGenUtils.setShouldAutoGeneratePanels(
    newState,
    workspaceSettingsRef,
    shouldAutoGeneratePanels
  );

  const inverseAction = Actions.clearAllPanels(
    panelBankConfigRef,
    workspaceSettingsRef
  );

  return [newState, inverseAction];
};

export const clearAutoPanels = (
  prevState: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref,
  expectedPanels: ExpectedPanelsState
): [ViewReducerState, ActionType] => {
  // track values for undo
  const panelBankConfig =
    prevState.parts[panelBankConfigRef.type][panelBankConfigRef.id];
  const prevSectionsAndPanels = panelBankConfig.sectionRefs.map(sectionRef => {
    const {panelRefs} = prevState.parts[sectionRef.type][sectionRef.id];
    return {sectionRef, panelRefs};
  });
  const {
    autoOrganizePrefix,
    shouldAutoGeneratePanels: prevShouldAutoGeneratePanels,
  } = prevState.parts[workspaceSettingsRef.type][workspaceSettingsRef.id];

  let newState = prevState;
  const sectionRefsToKeep = panelBankConfig.sectionRefs.filter(
    (sectionRef, idx) => {
      const section = newState.parts[sectionRef.type][sectionRef.id];

      // must always keep hidden section!
      const isLastSection = idx === panelBankConfig.sectionRefs.length - 1;
      if (isLastSection && section.name === PANEL_BANK_HIDDEN_SECTION_NAME) {
        return true;
      }

      const manualPanelRefs = section.panelRefs.filter(panelRef => {
        const panel = newState.parts[panelRef.type][panelRef.id];
        const key = getKey(panel);
        const spec = getExpectedSpec(expectedPanels, key);
        return !isAutoPanel(panel, section.name, key, spec, autoOrganizePrefix);
      });

      if (section.panelRefs.length > manualPanelRefs.length) {
        newState = setInShallowClone(
          newState,
          ['parts', sectionRef.type, sectionRef.id, 'panelRefs'],
          manualPanelRefs
        );
      }

      // keep section only if it still has panels left
      return manualPanelRefs.length > 0;
    }
  );

  // update panel bank with only the sections to keep
  if (panelBankConfig.sectionRefs.length > sectionRefsToKeep.length) {
    newState = setInShallowClone(
      newState,
      ['parts', panelBankConfigRef.type, panelBankConfigRef.id, 'sectionRefs'],
      sectionRefsToKeep
    );
  }

  // disable panel gen
  newState = PanelGenUtils.disableAutoGeneratePanels(
    newState,
    workspaceSettingsRef,
    panelBankConfigRef,
    expectedPanels
  )[0];

  return [
    newState,
    Actions.clearAutoPanelsUndo(
      panelBankConfigRef,
      workspaceSettingsRef,
      expectedPanels,
      prevSectionsAndPanels,
      prevShouldAutoGeneratePanels
    ),
  ];
};

export const clearAutoPanelsUndo = (
  prevState: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref,
  expectedPanels: ExpectedPanelsState,
  sectionsAndPanels: Array<{
    sectionRef: PanelBankSectionConfigTypes.Ref;
    panelRefs: PanelTypes.Ref[];
  }>,
  shouldAutoGeneratePanels: WorkspaceLayoutSettings['shouldAutoGeneratePanels']
): [ViewReducerState, ActionType] => {
  let newState = prevState;

  // revert panels
  const sectionRefs = sectionsAndPanels.map(({sectionRef, panelRefs}) => {
    const section = newState.parts[sectionRef.type][sectionRef.id];
    if (section.panelRefs.length !== panelRefs.length) {
      newState = setInShallowClone(
        newState,
        ['parts', sectionRef.type, sectionRef.id, 'panelRefs'],
        panelRefs
      );
    }
    return sectionRef;
  });

  // revert sections
  const panelBankConfig =
    newState.parts[panelBankConfigRef.type][panelBankConfigRef.id];
  if (panelBankConfig.sectionRefs.length !== sectionRefs.length) {
    newState = setInShallowClone(
      newState,
      ['parts', panelBankConfigRef.type, panelBankConfigRef.id, 'sectionRefs'],
      sectionRefs
    );
  }

  // revert panel gen setting
  newState = PanelGenUtils.setShouldAutoGeneratePanels(
    newState,
    workspaceSettingsRef,
    shouldAutoGeneratePanels
  );

  return [
    newState,
    Actions.clearAutoPanels(
      panelBankConfigRef,
      workspaceSettingsRef,
      expectedPanels
    ),
  ];
};
