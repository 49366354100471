import {toast} from '@wandb/weave/common/components/elements/Toast';
import {Select} from '@wandb/weave/components/Form/Select';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {Tooltip} from '@wandb/weave/components/Tooltip';
import {Analytics} from '@wandb/weave/integrations/analytics/Analytics';
import React, {useContext} from 'react';

import {
  RoleOption,
  TeamOrOrganizationRoleOption,
} from '../../../../components/MembersTable/TeamOrOrganizationRoleOption';
import {
  getModelSeatTypeDropdownItems,
  getModelsSeatTypeColumnName,
  getModelsSeatTypeDisplayName,
  ModelsSeatType,
} from '../../../../components/MembersTable/util';
import {AccountSelectorContext} from '../../../../components/Search/SearchNav/AccountSelectorContextProvider';
import {SkeletonWrapper} from '../../../../components/Skeleton';
import {
  OrganizationFlagsDocument,
  PlanType,
  useOrganizationDefaultModelsSeatQuery,
  useUpdateOrganizationMutation,
} from '../../../../generated/graphql';
import {DEFAULT_PRIVILEGES} from '../../../../util/accounts/pricing';
import {propagateErrorsContext} from '../../../../util/errors';
import {jsonParseSafe} from '../../../../util/json';
import {SettingsContent} from './SettingsContent';

type OrganizationDefaultModelsSeatSettingProps = {
  isAdmin?: boolean;
};

export const convertToModelsSeatType = (
  seat: string | undefined
): ModelsSeatType => {
  switch (seat) {
    case ModelsSeatType.FULL:
    case 'full':
      return ModelsSeatType.FULL;
    case ModelsSeatType.VIEWER:
    case 'viewer':
      return ModelsSeatType.VIEWER;
    case ModelsSeatType.NOACCESS:
    case 'none':
      return ModelsSeatType.NOACCESS;
    default:
      return ModelsSeatType.FULL;
  }
};

export const OrganizationDefaultModelsSeatSetting = ({
  isAdmin,
}: OrganizationDefaultModelsSeatSettingProps) => {
  const {selectedAccount} = useContext(AccountSelectorContext);

  const {data, loading, error, refetch} = useOrganizationDefaultModelsSeatQuery(
    {
      variables: {
        orgName: selectedAccount?.name || '',
      },
    }
  );

  const [updateOrganizationMutation] = useUpdateOrganizationMutation({
    context: propagateErrorsContext(),
    refetchQueries: [
      {
        query: OrganizationFlagsDocument,
        variables: {
          orgName: selectedAccount?.name || '',
        },
      },
    ],
  });

  if (loading || error != null || !data?.organization) {
    return (
      <SkeletonWrapper>
        <div className="rounded-lg bg-moon-250" />
      </SkeletonWrapper>
    );
  }

  const organization = data.organization;
  const parsedOrgFlags = organization.flags
    ? jsonParseSafe(organization.flags)
    : {};

  const defaultModelsSeat =
    getModelsSeatTypeDisplayName(parsedOrgFlags.default_models_seat) ??
    ModelsSeatType.FULL;

  const roleManagementEnabled =
    organization.subscriptions?.some(
      sub =>
        sub.plan.planType === PlanType.Primary &&
        sub.privileges.roleManagementEnabled
    ) || DEFAULT_PRIVILEGES.roleManagementEnabled;

  const roleOptions =
    selectedAccount && organization
      ? getModelSeatTypeDropdownItems(organization, null, roleManagementEnabled)
      : [];

  const onUpdateDefaultModelsSeat = async (newSeat: string | undefined) => {
    const newSeatType = convertToModelsSeatType(newSeat);
    const newFlags = JSON.stringify({
      default_models_seat: getModelsSeatTypeColumnName(newSeatType),
    });

    Analytics.track('Set default models seat', {
      organizationName: selectedAccount?.name,
      location: 'Organization settings',
      newFlags,
    });

    try {
      await updateOrganizationMutation({
        variables: {
          organizationID: selectedAccount?.id || '',
          flags: newFlags,
        },
      });
      toast('Successfully updated default models seat.');
      await refetch();
    } catch (err) {
      toast(`Error updating default models seat. Please try again later.`, {
        type: 'error',
      });
    }
  };

  const dropdown = (
    <Select<RoleOption, false>
      options={roleOptions}
      value={roleOptions.find(option => option.value === defaultModelsSeat)}
      formatOptionLabel={option =>
        !isAdmin ? (
          <div>{option.text}</div>
        ) : (
          <TeamOrOrganizationRoleOption
            roleOption={option}
            className={'group-hover:text-teal-600'} // the hover event is triggered on the parent and must be applied to the child, ie change the text color
          />
        )
      }
      onChange={selected => {
        if (selected?.value) {
          onUpdateDefaultModelsSeat(selected.value);
        }
      }}
      classNames={{
        control: () =>
          'shadow-none bg-transparent font-semibold w-[130px] hover:shadow-none hover:bg-teal-300/[0.48] hover:cursor-pointer group',
        menu: () => 'w-[130px] text-right absolute right-0',
      }}
      isDisabled={loading || !isAdmin}
      isSearchable={false}
    />
  );

  return (
    <SettingsContent
      title="Default Models seat"
      subtitle="Automatically assign models seats to people when they join your organization."
      sideContent={
        isAdmin ? (
          dropdown
        ) : (
          <Tooltip
            trigger={
              // span is needed so tooltip works on disabled buttons
              <span>{dropdown}</span>
            }>
            <Tailwind>
              <div className="flex items-center">
                <Icon name="info" width={16} height={16} />
                <div className="ml-8 w-[150px]">
                  Please ask the org admin to change this setting.
                </div>
              </div>
            </Tailwind>
          </Tooltip>
        )
      }
    />
  );
};
