import {datadogLogs, type LogsGlobal} from '@datadog/browser-logs';
import {datadogRum, type RumGlobal} from '@datadog/browser-rum';
import {once} from 'lodash';

import config, {envIsDev} from '../../config';
import {getRouteMatch} from '../../routes/utils';
import {isCacheEnabled} from '../cache/util';
import {
  TTI_DOMAIN_INCLUSIONS,
  TTI_WANDB_ANALYTICS_DOMAIN,
} from '../profiler/constants';
import {
  hasViewEverBeenBackgrounded,
  resetViewBackgrounded,
} from '../viewVisibility';
import {
  ALLOWED_TRACING_URLS,
  DATADOG_CLIENT_TOKEN,
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_SITE,
  DATADOG_UI_SERVICE,
} from './constants';

let isDatadogEnabledAtRuntime = true;

// This flag is set elsewhere to indicate that datadog should be enabled
declare global {
  interface Window {
    DatadogEnabled?: boolean;
  }
}

// prettier-ignore -- the curly braces make this fn harder to read, not easier
export function datadogEnabled() {
  /* eslint-disable curly */
  // Can set the DATADOG_DEBUG config flag to force-enable Datadog logging. This
  // is for debugging Datadog's behavior in development environments and should
  // not be otherwise used.
  if (config.DATADOG_DEBUG) return true;

  // If we are in a development environment, don't log to Datadog.
  if (envIsDev) return false;

  // If analytics are disabled in this environment, don't log to Datadog.
  if (config.ANALYTICS_DISABLED) return false;

  if (typeof window === 'undefined') return false;

  // If in a private environment (which are private?) don't log to Datadog.
  if (window.CONFIG?.ENVIRONMENT_IS_PRIVATE) return false;

  // Finally, if DataDog just isn't enabled, don't log to it.
  if (!window.DatadogEnabled) return false;

  // If we got this far, the only thing that can indicate whether or not DD is
  // running is if it's been specifically turned off via `disableDatadog` later
  // in this file. This can happen because the user is detected to be an admin.
  return isDatadogEnabledAtRuntime;
  /* eslint-enable curly */
}

const initDatadogLogs = once(function initDatadogLogs() {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    env: window.CONFIG?.ENVIRONMENT_NAME,
    site: DATADOG_SITE,
    forwardErrorsToLogs: false,
    sessionSampleRate: config.DATADOG_DEBUG ? 100 : 25, // only send logs for X% of _sessions_
    service: DATADOG_UI_SERVICE,
  });
});

const initDatadogRum = once(function initDatadogRum() {
  datadogRum.init({
    allowedTracingUrls: ALLOWED_TRACING_URLS as any,

    applicationId: DATADOG_RUM_APPLICATION_ID,
    beforeSend(event) {
      // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
      const discardedErrorMessages = [
        "'measure' on 'Performance': The mark 'GTM", // Google Tag manager
        'Performance.measure: Given mark name', // Google Tag manager
        'https://jscloud.net/x/25796/inlinks.js',
      ];

      if (event.type === 'error') {
        if (
          discardedErrorMessages.some(msg => event.error.message.includes(msg))
        ) {
          return false;
        }
      }

      const context = event.context ?? {};
      event.context = context;

      const url = new URL(event.view.url);
      const matchParams = getRouteMatch(url.pathname)?.params as any;
      context.gitTag = window.CONFIG?.GIT_TAG;
      context.identity = {
        entity: matchParams?.entityName,
        project: matchParams?.projectName,
        org: window.viewer?.org,
      };
      context.cacheEnabled = isCacheEnabled();

      return true;
    },
    clientToken: DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env: window.CONFIG?.ENVIRONMENT_NAME,
    service: DATADOG_UI_SERVICE,
    sessionReplaySampleRate: 100,
    site: DATADOG_SITE,
    traceSampleRate: 100,
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    // Only include activity from domains that are in TTI_DOMAIN_INCLUSIONS
    excludedActivityUrls: [
      (url: string) => {
        const urlobj = new URL(url);
        return !TTI_DOMAIN_INCLUSIONS.some(
          domain =>
            urlobj.hostname.includes(domain) &&
            // exclude analytics.wandb.ai - this is a special case because it includes wandb.ai, which is allowed
            urlobj.hostname !== TTI_WANDB_ANALYTICS_DOMAIN
        );
      },
    ],
    trackViewsManually: true,
  });
});

export function init() {
  if (!datadogEnabled()) {
    return;
  }
  initDatadogLogs();
  initDatadogRum();
}

export function getDatadogLogs(): LogsGlobal {
  return datadogLogs;
}

/**
 * Wraps a datadog analytics function and returns a new function with the same
 * signature. If the current browser window isn't in view, the datadog fn will
 * not be called. In either case the return value should be the same-ish.
 *
 * @param fn
 * @param generateReturnOnHidden
 */
function skipFnIfHidden<Fn extends (...args: any) => any>(
  fn: Fn,
  generateReturnOnHidden?: () => ReturnType<Fn>
) {
  return function (this: any, ...args: Parameters<Fn>): ReturnType<Fn> {
    if (hasViewEverBeenBackgrounded()) {
      if (generateReturnOnHidden) {
        return generateReturnOnHidden();
      }
      return undefined as ReturnType<Fn>;
    }
    return fn.call(this, ...args);
  };
}

// Generates a durationVital reference. New ref ident each time. Datadog uses
// these to start/end a vital duration, and we don't want to accidentally hand
// out the same reference ident to multiple callers.
function ddRef(): ReturnType<RumGlobal['startDurationVital']> {
  return {
    __dd_vital_reference: true,
  };
}

const rumFacade = Object.create(datadogRum);

rumFacade.addDurationVital = skipFnIfHidden(datadogRum.addDurationVital);
rumFacade.startDurationVital = skipFnIfHidden(
  datadogRum.startDurationVital,
  ddRef
);
rumFacade.stopDurationVital = skipFnIfHidden(datadogRum.stopDurationVital);
rumFacade.addTiming = skipFnIfHidden(datadogRum.addTiming);
rumFacade.addAction = skipFnIfHidden(datadogRum.addAction);

export function getDatadogRum(): RumGlobal {
  return rumFacade;
}

export function disableDatadog() {
  isDatadogEnabledAtRuntime = false;

  // Halts Datadog RUM by revoking tracking consent. Can be done at any time.
  // This is a bit of a hack, but should work pretty reliably. The main way this
  // function will be called is if the React tree detects that the user is an
  // admin.
  datadogLogs.setTrackingConsent('not-granted');
  datadogRum.setTrackingConsent('not-granted');
}

const viewTimings = new Set<string>();

export function hasTriggeredTiming(name: string) {
  return viewTimings.has(name);
}

/**
 * The same as `datadogRum.addTiming`, but subsequent calls to the same timing
 * within the same datadog view will not be recorded.
 *
 * By default, `datadogRum.addTiming` can be called multiple times, and the
 * *last* instance of it is used by Datadog in their metrics. This can be useful
 * sometimes, but usually what we want is to measure the *first* instance of
 * a timing concluding. This lets us record things like "when did the first
 * graph finish loading?" or "how long does it take to load a whole workspace?"
 *
 * @param datadogRum
 * @param name
 * @param time
 */
export function addFirstTiming(
  datadogRum: RumGlobal,
  name: string,
  time?: number
): void {
  if (hasTriggeredTiming(name)) {
    return;
  }

  viewTimings.add(name);
  datadogRum.addTiming(name, time);
}

export function startDatadogRumView(name?: string) {
  // See `addFirstTiming` -- clear out the list of already-recorded timings so
  // that they can be re-recorded for the new view.
  viewTimings.clear();

  const datadogRum = getDatadogRum();
  resetViewBackgrounded();
  datadogRum.startView({name});

  // QUESTION - is this necessary/used? setting session-level properties to
  //            values that change frequently seems incorrect.
  datadogRum.setGlobalContextProperty('routeName', name);
}
