import {useQuery} from '@apollo/react-hooks';
import _ from 'lodash';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import * as Generated from '../../generated/graphql';
import {useDeploymentIdQuery} from '../../generated/graphql';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {identifyWithViewer, trackWBReferrer} from '../../util/analytics';
// n.b.: can't us the bucket export here because it creates an import cycle
import {useDatadogRum} from '../../util/datadog/hooks';
import {useDispatch} from '../hooks';
import * as Actions from './actions';
import * as Selectors from './selectors';
import * as Types from './types';

// This should be used everywhere except in app. It always returns valid viewer
// App.tsx blocks the rest of the app from loading until useInitViewer is complete.
const VIEWER_PHOTO_CACHE_TIMEOUT = 1000 * 60 * 30; // 30 minutes.
let viewerPhotoLastCached = 0;
export function useViewer() {
  return useSelector(Selectors.getViewer, (left, right) => {
    // TODO(adrnswanberg): This is a crazy workaround to deal with the fact that profile photos
    // are signed URLs and thus unstable. Without this check, this selector re-fires on
    // every mutation or query that pulls the viewer down.
    //  Remove this when photo URLs are public or signed URLs are stable.
    if (Date.now() - viewerPhotoLastCached > VIEWER_PHOTO_CACHE_TIMEOUT) {
      viewerPhotoLastCached = Date.now();
      return _.isEqual(left, right);
    }
    const l = _.omit(left, 'photoUrl');
    const r = _.omit(right, 'photoUrl');

    return _.isEqual(l, r);
  });
}

// This is only called from App.tsx, it populates the redux store with the viewer
// object and the app hangs until we've set loading to false.  This is how we
// know if we're logged in.
export function useInitViewer() {
  const {loading, data, refetch} = useQuery<
    Generated.ViewerQuery,
    Generated.ViewerQueryVariables
  >(Generated.ViewerDocument);

  const viewer =
    data != null && data.viewer != null
      ? (data.viewer as unknown as Types.Viewer)
      : undefined;

  const deploymentIdQuery = useDeploymentIdQuery({});
  const deploymentId = deploymentIdQuery.data?.serverInfo?.deploymentId;

  const reload = useSelector(Selectors.reloadViewer);
  const dispatch = useDispatch();
  const datadogRum = useDatadogRum();

  // Reload the viewer when set to true
  useEffect(() => {
    if (reload) {
      refetch();
    }
  }, [reload, refetch]);

  useEffect(() => {
    dispatch(Actions.setCurrentViewer(loading, viewer));
    identifyWithViewer(viewer, deploymentId);

    if (viewer) {
      // This code ties the viewer's organization to the global viewer object for RUM.
      // It assumes the viewer's organization matches the content's organization. This
      // might not always be true. For instance, a viewer from Org A could access content
      // from Org B. Ideally, the organization should be determined from the page's URL
      // entity, which would be more accurate. However, this would require pausing the
      // RUM's beforeSend process for a GraphQL query or starting an async task to fetch
      // the organization linked to an entity. This info would need global storage for
      // RUM beforeSend() access, risking RUM events dispatch without an organization tag.
      // Thus, we default to using the viewer's organization, assuming viewers primarily
      // interact with their own organization's content.
      const org = viewer.organizations.find(o =>
        o.subscriptions.some(s => s.subscriptionType === 'ENTERPRISE')
      );
      window.viewer = {
        ...viewer,
        org: org?.name,
      };
      datadogRum.setGlobalContextProperty('orgName', org?.name);
    } else {
      window.viewer = viewer;
    }

    if (!loading) {
      // We send the track event after identifying the currently logged in user with `identifyWithViewer`
      trackWBReferrer();
    }
  }, [datadogRum, dispatch, viewer, deploymentId, loading]);
}
