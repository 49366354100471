import {debounce} from 'lodash';
import {useCallback, useState} from 'react';
import type {ObservedSize} from 'use-resize-observer';
import useResizeObserver from 'use-resize-observer';

export function useResizeObserverDebounced<T extends HTMLElement>(
  waitTime: number
): {
  ref: React.RefCallback<T>;
  isResizing: boolean;
  width: number;
  height: number;
} {
  const [resizeState, setResizeState] = useState<{
    isResizing: boolean;
    width: number;
    height: number;
  }>({
    isResizing: false,
    width: 0,
    height: 0,
  });

  // useCallback doesn't like us passing a debounced function in, so
  // disable the lint warning. The only dep is waitTime (setResizeState)
  // is a callback from useState and therefore doesn't need to be
  // included in the dependency list.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resized = useCallback(
    debounce(
      (size: ObservedSize) => {
        setResizeState({
          isResizing: false,
          width: size.width ?? 0,
          height: size.height ?? 0,
        });
      },
      waitTime,
      {leading: false}
    ),
    [waitTime]
  );

  const onResize = useCallback(
    (size: ObservedSize) => {
      setResizeState({
        isResizing: true,
        width: size.width ?? 0,
        height: size.height ?? 0,
      });
      resized(size);
    },
    [resized]
  );

  const {ref} = useResizeObserver<T>({onResize});

  return {ref, ...resizeState};
}
