import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import NumberInput from '@wandb/weave/common/components/elements/NumberInput';
import React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Popup} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {ExcludeOutliersValues} from '../WorkspaceDrawer/Settings/types';
import {areOutliersExcluded} from '../WorkspaceDrawer/Settings/utils';

type RangeInputProps = {
  className?: string;
  disabled?: boolean;
  log?: boolean;
  logValue?: boolean;
  maxValue?: number;
  minValue?: number;
  onLogChange?: (newVal?: boolean) => void;
  onMaxChange?: (newVal?: number) => void;
  onMinChange?: (newVal?: number) => void;
  stepper?: boolean;
} & (
  | {
      excludeOutliersIsDisplayed: true;
      excludeOutliersValue: ExcludeOutliersValues | undefined;
      excludeOutliersValueChangeFn: (newVal: ExcludeOutliersValues) => void;
    }
  | {
      excludeOutliersIsDisplayed: false;
    }
);

const StyledRangeAndOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  
  .number-input__container {
    margin-right: 0px; !important;
  }

  .number-input__input {
    margin-right: 0px !important;
  }

  .ui.button {
    margin-right: 0px;
  }

  > * + * {
    margin-left: 10px !important;
  }
`;

const RangeInput: React.FC<RangeInputProps> = props => {
  return (
    <StyledRangeAndOptions data-test="styled-range-and-options">
      <NumberInput
        disabled={props.disabled}
        stepper={false}
        placeholder="min"
        value={props.minValue}
        onChange={newVal => {
          props.onMinChange?.(newVal);
        }}
      />
      <NumberInput
        className="max"
        disabled={props.disabled}
        stepper={false}
        placeholder="max"
        value={props.maxValue}
        onChange={newVal => {
          props.onMaxChange?.(newVal);
        }}
      />
      {props.log && (
        <Popup
          on="hover"
          position="top center"
          content="Log scale"
          inverted
          size="mini"
          trigger={
            <Button
              disabled={props.disabled}
              toggle
              icon
              active={props.logValue}
              onClick={(e, {value}) => {
                if (props.onLogChange != null) {
                  props.onLogChange(!props.logValue);
                }
              }}>
              <LegacyWBIcon name="log" title="" />
            </Button>
          }
        />
      )}
      {props.excludeOutliersIsDisplayed && (
        <Popup
          inverted
          size="mini"
          on="hover"
          position="top center"
          content="Ignore outliers in chart scaling"
          trigger={
            <Button
              disabled={props.disabled}
              active={areOutliersExcluded(props.excludeOutliersValue)}
              icon
              toggle
              onClick={() => {
                props.excludeOutliersValueChangeFn(
                  areOutliersExcluded(props.excludeOutliersValue)
                    ? 'include-outliers'
                    : 'exclude-outliers'
                );
              }}>
              <LegacyWBIcon
                name="outlier"
                title="Ignore outliers in chart scaling"
              />
            </Button>
          }
        />
      )}
    </StyledRangeAndOptions>
  );
};

export default RangeInput;
