import numeral from 'numeral';
import React from 'react';

import {SkeletonWrapper} from '../../components/Skeleton/Skeleton';
import BarChart from '../../components/vis/BarChart/BarChart';
import {UsageType} from '../../generated/graphql';
import {secondsToHours} from '../../util/date';
import {StorageTableLoadingFailure} from '../Billing/AccountSettings/ManageUsagePage/StorageTableLoadingFailure';
import {useHistoricUsageChartContext} from './HistoricUsageChartContextProvider';

export function getValueFormatter(
  usageType: UsageType
): (value: number) => string {
  switch (usageType) {
    case UsageType.Storage:
      return value => numeral(value).format('0.0b'); // Convert Bytes into MB, KB, GB, TB, etc
    case UsageType.TrackedHours:
      return value => `${secondsToHours(value)} hrs`;
    case UsageType.Weave:
      return value => numeral(value).format('0.0b'); // Convert Bytes into MB, KB, GB, TB, etc
    case UsageType.UserSeats:
      return value => `${value}`;
  }
}

export const HistoricUsageChart = () => {
  const {processedData, loading, usageType, error} =
    useHistoricUsageChartContext();

  if (error != null) {
    return (
      <div className="flex h-[400px] items-center">
        <StorageTableLoadingFailure />
      </div>
    );
  }

  if (loading || processedData == null) {
    return (
      <SkeletonWrapper>
        <div className="mt-8 h-[400px] w-full rounded bg-moon-250" />
      </SkeletonWrapper>
    );
  }
  return (
    <div className="relative" data-test="historic-usage-chart">
      <BarChart
        vertical
        bars={processedData}
        height={400}
        enableGridRows
        fontSize="small"
        tickLabelSize={12}
        rotateTickLabel={false}
        tooltipOnTop
        showTooltipWhenHoveringAboveBars
        tooltipClassName="rv-hint__content-dark"
        withTooltipBounds={false}
        valueFormatter={getValueFormatter(usageType)}
      />
    </div>
  );
};
